import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarCardComponent } from './avatar-card.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
    declarations: [
        AvatarCardComponent,
    ],
    imports: [ 
        CommonModule,
        IonicModule
    ],
    exports: [
        AvatarCardComponent
    ],
    providers: [],
})
export class AppAvatarCardModule {}



import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { BfitStaticRoute } from './entities/bfit-static-route';
import { BfitContextService } from './bfit-context.service';

export * from './entities/bfit-static-route';

@Injectable({ providedIn: 'root' })
export class BfitStaticRoutesService extends BfitContextService<BfitStaticRoute> {

    constructor(db: FirestoreService) {
        super('bfit_static_routes', db);
    }

}

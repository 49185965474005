import {
    NavigationOptions,
    AnimationOptions
} from '@ionic/angular/providers/nav-controller';

import { UrlTree } from '@angular/router';

export function removeMenus(menus: NavigationMenu[], ids: string[]): NavigationMenu[] {
    return menus.reduce((newMenu, menu) => {
        if (!menu.children) {
            if (!ids.includes(menu.id)) {
                newMenu.push(menu);
            }
        } else {
            const children = removeMenus(menu.children, ids);
            if (children.length > 0) {
                if (children.length === 1) {
                    newMenu.push(children[0]);
                } else {
                    newMenu.push({ ...menu, children });
                }
            }
        }
        return newMenu;
    }, []);
}

export function selectMenus(menus: NavigationMenu[], ids: string[]): NavigationMenu[] {
    return menus.reduce((newMenu, menu) => {
        if (!menu.children) {
            if (ids.includes(menu.id)) {
                newMenu.push(menu);
            }
        } else {
            const children = selectMenus(menu.children, ids);
            if (children.length > 0) {
                if (children.length === 1) {
                    newMenu.push(children[0]);
                } else {
                    newMenu.push({ ...menu, children });
                }
            }
        }
        return newMenu;
    }, []);
}
export interface NavigationMenu {
    id: string;
    name: string;
    description?: string;
    icon?: string;
    badge?: string;
    url?: string;
    dispatch?: (menu: NavigationMenu, id?: string) => any;
    disabled?: boolean;
    counter?: number;
    queryParams?: any;
    children?: NavigationMenu[];
}

export class NavigateRoot {
    static readonly type = '[msi-ionic-router] NavigateRoot';
    constructor(
        public readonly path: string | UrlTree | any[],
        public readonly options?: NavigationOptions
    ) { }
}

export class NavigateForward {
    static readonly type = '[msi-ionic-router] NavigateForward';
    constructor(
        public readonly path: string | UrlTree | any[],
        public readonly options?: NavigationOptions
    ) { }
}

export class NavigateBackward {
    static readonly type = '[msi-ionic-router] NavigateBackward';
    constructor(
        public readonly path: string | UrlTree | any[],
        public readonly options?: NavigationOptions
    ) { }
}

export class NavigateBack {
    static readonly type = '[msi-ionic-router] NavigateBack';
    constructor(public readonly options?: AnimationOptions) { }
}

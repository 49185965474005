import { ObjectReference } from '@bfit/core/models/entities/ref';

import { Observable, defer, of, combineLatest } from 'rxjs';

import { first, defaults, assign, chunk, get, flatten, isEmpty } from 'lodash';
import cleanDeep from 'clean-deep';
import * as moment from 'moment';

import { FirestoreService, GeoPoint, Timestamp, QueryLogger } from '@msi/core/dao/firebase/firestore';

import { switchMap, take, finalize, tap } from 'rxjs/operators';
import { QueryFn } from '@angular/fire/firestore';
import { FieldDefinitions } from './models/field-definitions';

const cleanDeepOptions = { emptyArrays: false, emptyObjects: false, emptyStrings: false, nullValues: false, undefinedValues: true };

export interface IdentityObject {
  id: string;
  _version?: number;
  createdAt?: Timestamp | Date;
  createdBy?: ObjectReference<any>;
  updatedAt?: Timestamp | Date;
  updatedBy?: ObjectReference<any>;
  _idempotency?: any;
}

export interface DataTablePageInfo {
  pageLimit: number;
  limit: number;
  count: number;
  cursor: string;
  readAhead: number;
  orderBy: string,
  orderDir: 'asc' | 'desc',
  isLoading: boolean,
}

export function timestampAsDate(timestamp: Timestamp | Date | string): Date | null {

  let date: Date;

  if (!timestamp) {
    return null;
  } else if (typeof (timestamp) === 'string') {
    date = moment(timestamp).toDate();
  } else if (timestamp && get(timestamp, 'nanoseconds', -1) !== -1) {
    date = (<Timestamp>timestamp).toDate();
  } else {
    date = timestamp as Date;
  }
  return date;
}

export function timestampAsString(timestamp: Timestamp | Date): string {
  const result = timestampAsDate(timestamp);
  return result.toISOString();
}

export abstract class DBService<T extends IdentityObject>  {

  private DEFAULTS: Partial<T>;

  constructor(protected dbname: string, protected db: FirestoreService) {
    this.DEFAULTS = {};
  }

  protected get collectionPath(): string { return this.dbname; }

  protected getAll(queryFn?: QueryFn): Observable<T[]> {

    const logger = new QueryLogger(`getAll: ${this.collectionPath}`, queryFn);
    return this.db.colWithIds$<T>(this.collectionPath, queryFn).pipe(
      tap((result) => logger.log(result)),
      finalize(() => logger.finalize())
    );
  }

  get(id: string): Observable<T> {

    const logger = new QueryLogger(`get: ${this.collectionPath}`);
    return this.db.docWithId$<T>(`${this.collectionPath}/${id}`).pipe(
      take(1),
      tap((result) => logger.log(result)),
      finalize(() => logger.finalize())
    );
  }

  observe(id: string): Observable<T> {

    const logger = new QueryLogger(`observe: ${this.collectionPath}`);
    return this.db.docWithId$<T>(`${this.collectionPath}/${id}`).pipe(
      tap((result) => logger.log(result)),
      finalize(() => logger.finalize())
    );
  }

  exists(id: string): Observable<boolean> {
    return this.db.exists$(id);
  }

  findAll(): Observable<T[]> {
    return this.getAll();
  }

  protected findOne(queryFn: QueryFn): Observable<T> {

    const logger = new QueryLogger(`findOne: ${this.collectionPath}`);
    return this.getAll(queryFn).pipe(
      take(1),
      switchMap(it => of(first(it))),
      tap(result => logger.log(result)),
      finalize(() => logger.finalize())
    );
  }

  findAllIds(ids: string[]): Observable<T[]> {

    if (isEmpty(ids)) {
      return of([]);
    }

    const logger = new QueryLogger(`findAllIds: ${this.collectionPath}`);

    const key = this.db.getDocIdFieldName();
    const batches = chunk(ids, 10);

    if (batches.length > 1) {
      return combineLatest(batches.map(batch => this.getAll(ref => ref.where(key, 'in', batch)))).pipe(
        // concatAll(),
        tap(result => logger.log(result)),
        switchMap(all => of(flatten(all))),
        tap(result => logger.log(result)),
        finalize(() => logger.finalize())
      );
    } else {
      return this.getAll(ref => ref.where(key, 'in', batches[0])).pipe (
        tap(result => logger.log(result)),
        finalize(() => logger.finalize())
      );
    }
  }

  count(queryFn?: QueryFn): Observable<number> {
    const logger = new QueryLogger(`count: ${this.collectionPath}`);
    return this.getAll(queryFn).pipe(
      switchMap(list => of(list.length)),
      tap(result => logger.log(result)),
      finalize(() => logger.finalize())
    );
  }

  private async _createIfNotExist(id: string, data: Partial<T>): Promise<void> {
    const docRef = this.db.doc<T>(`${this.collectionPath}/${id}`);
    return docRef.ref.get().then(async doc => {
      if (!doc.exists) {
        const newData = this.newInstance({ ...data, id: id });
        await docRef.set(newData);
      }
    });
  }

  getOrCreate(id: string, data: Partial<T>): Observable<T> {
    const logger = new QueryLogger(`getOrCreate: ${this.collectionPath}`);
    return defer(() => this._createIfNotExist(id, data)).pipe(
      switchMap(() => this.get(id)),
      tap(result => logger.log(result)),
      finalize(() => logger.finalize())
    );
  }

  newInstance(data: Partial<T>): T {
    const clean = cleanDeep(data, cleanDeepOptions) as T;
    return defaults(clean, this.DEFAULTS);
  }

  async create(it: Partial<T>): Promise<T> {
    const logger = new QueryLogger(`create: ${this.collectionPath}`);
    const data: any = this.newInstance(it);
    if (data.id) {
      return this.db.set(`${this.collectionPath}/${data.id}`, data).then(() => {
        return this.get(data.id).pipe(
          take(1),
          tap(result => logger.log(result)),
          finalize(() => logger.finalize())
        ).toPromise();
      });
    } else {
      return this.db.add(this.collectionPath, data).then(doc => {
        return this.get(doc.id).pipe(
          take(1),
          tap(result => logger.log(result)),
          finalize(() => logger.finalize())
        ).toPromise();
      });
    }
  }

  async createAll(data: T[]): Promise<void[]> {
    const logger = new QueryLogger(`createAll: ${this.collectionPath}`);
    const all = data.map(it => this.newInstance(it));
    const result = await this.db.addAll(this.collectionPath, all);
    logger.log(result);
    logger.finalize();
    return result;
  }

  async upsert(id: string, data: Partial<T>): Promise<void> {
    const logger = new QueryLogger(`upsert: ${this.collectionPath}`);
    const result = await this.db.upsert(`${this.collectionPath}/${id}`, cleanDeep(data, cleanDeepOptions));
    logger.log(result);
    logger.finalize();
    return result;
  }

  async upsertAll(data: T[]): Promise<void[]> {
    const logger = new QueryLogger(`upsertAll: ${this.collectionPath}`);
    const all = data.map(it => this.newInstance(it));
    const result = await this.db.upsertAll(this.collectionPath, all);
    logger.log(result);
    logger.finalize();
    return result;
  }

  async update(id: string, data: Partial<T>): Promise<void> {
    const logger = new QueryLogger(`update: ${this.collectionPath}`);
    const result = await this.db.update(`${this.collectionPath}/${id}`, cleanDeep(data, cleanDeepOptions));
    logger.log(result);
    logger.finalize();
    return result;
  }

  async updateAll(data: Partial<T>[]): Promise<void[]> {
    const logger = new QueryLogger(`updateAll: ${this.collectionPath}`);
    const result = await this.db.updateAll(this.collectionPath, data.map(it => cleanDeep(it, cleanDeepOptions)));
    logger.log(result);
    logger.finalize();
    return result;
  }

  async delete(id: string): Promise<void> {
    const logger = new QueryLogger(`delete: ${this.collectionPath}`);
    const result = await this.db.delete(`${this.collectionPath}/${id}`);
    logger.log(result);
    logger.finalize();
    return result;
  }

  async deleteAll(ids: string[]): Promise<void[]> {
    const logger = new QueryLogger(`DeleteAll: ${this.collectionPath}`);
    const result = await this.db.deleteAll(this.collectionPath, ids);
    logger.log(result);
    logger.finalize();
    return result;
  }

  // query(): any {
  //     return this.db.linq(this.collectionPath);
  // }

  geopoint(lat: number, lng: number): GeoPoint {
    return this.db.geopoint(lat, lng);
  }

  createUUID(): string {
    return this.db.createUUID();
  }

  getFieldDefinitions(): FieldDefinitions {
    return null;
  }

  patchDefaults(values: Partial<T>): void {
    assign(this.DEFAULTS, values);
  }

  getDefaults(): Partial<T> {
    return this.DEFAULTS;
  }

  runTransaction(fn: (transaction: firebase.firestore.Transaction) => Promise<T>): Promise<T> {
    return this.db.runTransaction(fn);
  }
}


import { NgModule } from '@angular/core';
import { ProfilePage } from './profile';

import { ProfileShowMoreMenuModule } from './profile-show-more.module';

import { AvatarComponent } from './avatar/avatar.component';

import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MsiFormlyModule } from '@msi/ionic/ui/formly/formly.module';
@NgModule({

  declarations: [
    ProfilePage,
    AvatarComponent,
  ],
  imports: [

    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    FlexLayoutModule,

    ProfileShowMoreMenuModule,
    MsiFormlyModule,
  ],
  exports: [
    ProfilePage,
  ],
})
export class ProfilePageModule {}

import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { Injectable } from '@angular/core';

import { OVirtJobRequest } from './entities/ovirt-job-request';
import { DBService } from '@msi/core/dao/db';

export * from './entities/ovirt-job-request';

@Injectable({ providedIn: 'root' })
export class OVirtJobRequestsService extends DBService<OVirtJobRequest> {

    constructor(db: FirestoreService) {
        super('ovirt-job-requests', db);
    }

    newInstance(data: Partial<OVirtJobRequest>): OVirtJobRequest {
        return super.newInstance({ ...data, start_time: new Date().getTime() });
    }

}

import { Organization } from '@msi/core/dao/models/organization';
import { User, UserSettings } from '@msi/core/dao/models/user';

const topic = '[msi-auth]';

export class MsiAuthLogin {
    static readonly type: string = `${topic} show login page`;

}

export class MsiAuthLoginCompleted {
    static readonly type: string = `${topic} login completed`;

}

export class MsiAuthVerifyMobile {
    static readonly type: string = `${topic} verify mobile`;
}

export class MsiAuthMobilePhoneVerified {
    static readonly type: string = `${topic} mobile is verified`;

}

export class MsiAuthNeedToCompleteProfile {
    static readonly type: string = `${topic} need to complete my profile`;

}

export class MsiAuthRequestEditProfile {
    static readonly type: string = `${topic} request edit my profile`;
}


export class MsiAuthEditProfile {
    static readonly type: string = `${topic} edit my profile`;
}

export class MsiAuthStartWalkThrough {
    static readonly type: string = `${topic} start walkthrough`;

}

export class MsiAuthWalkthroughCompleted {
    static readonly type: string = `${topic} walkthrough completed`;

}

export class MsiAuthOrganizationHasChanged {
    static readonly type: string = `${topic} org has changed`;
    constructor(public org: Organization) {}
}

export class MsiAuthUserHasChanged {
    static readonly type: string = `${topic} user has changed`;
    constructor(public user: User) {}
}

export class MsiAuthChangeFirstPage {
    static readonly type: string = `${topic} change first page`;
    constructor(public url: string) {}
}

export class FirebaseAuthContextHasChanged {
    static readonly type: string = '[afs-auth] context has changed';
    constructor(public user: User, public authenticated: boolean) { }
}

export class MsiAuthContextHasChanged {
    static readonly type: string = `${topic} context has changed`;
}

export class MsiAuthLogout {
    static readonly type: string = `${topic} logout`;

}

export class MsiAuthExitApp {
    static readonly type: string = `${topic} exit app`;

}

export class MsiAuthStartApp {
    static readonly type: string = `${topic} start app`;

}

export class MsiAuthRequestHomePage {
    static readonly type: string = `${topic} request home page`;

}

export class MsiAuthUnlockDevice {
    static readonly type: string = `${topic} unlock device`;

}

export class MsiAuthDeviceUnlocked {
    static readonly type: string = `${topic} device unlocked`;

}

export class MsiAuthCheckFirstTimeLogin {
    static readonly type: string = `${topic} first time login `;
}

export class MsiAuthRequestFirstTimeLoginPage {
    static readonly type: string = `${topic} request first time login page`;
}

export class MsiAuthFirstTimeLoginCompleted {
    static readonly type: string = `${topic} first time login completed`;

}

export class MsiAuthSendEmailVerificationLink {
    static readonly type: string = `${topic} send email verification link`;

}

export class MsiAuthLegalDisagree {
    static readonly type: string = `${topic} legal disagreement`;

}

export class MsiAuthLegalAgree {
    static readonly type: string = `${topic} legal agreement`;

}

export class MsiAuthCurrentUserChangePassword {
    static readonly type: string = `${topic} change password`;
    constructor(public currentPassword, public newPassword: string) {}
}

export class MsiAuthCurrentUserResetTemporaryPassword {
    static readonly type: string = `${topic} reset temporary password`;
}

export class MsiAuthCurrentUserForgotPassword {
    static readonly type: string = `${topic} forgot password`;
}

export class LoadAppContext {
    static readonly type: string  = `${topic} load app context for auth user`;
    constructor(public userId: string) {}
}
export class MsiAuthUpdateUserProfile {
    static readonly type: string  = `${topic} change user profile`;
    constructor(public user: User, public profile: Partial<User>) {}
}

export class RequestCurrentUserChangeProfilePhoto {
    static readonly type: string  = `${topic} change current user profile photo`;
    constructor(public profile: Partial<User>) {}
}

export class MsiAuthUpdateUserSettings {
    static readonly type: string  = `${topic} change user settings`;
    constructor(public user: User, public settings: UserSettings) {}
}


import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { Injectable } from '@angular/core';
import { BfitTemplateType, OVirtTemplate } from './entities/ovirt-template';
import { of, Observable } from 'rxjs';
import { OVirtDataCenter } from './ovirt-data-centers';
import { CloudContextService } from './ovirt-context.service';

export * from './entities/ovirt-data-center';

@Injectable({ providedIn: 'root' })
export class OVirtTemplatesService extends CloudContextService<OVirtTemplate> {
   

    constructor(db: FirestoreService) {
        super('ovirt_templates', db);
    }

    findAllForDataCenter(dc: OVirtDataCenter): Observable<OVirtTemplate[]> {
        return dc ? this.getAll(ref => ref.where('dataCenter_id', '==', dc.id)) : of([]);
    }

    findAllMasterImages(): Observable<OVirtTemplate[]> {
        return this.getAll(ref => ref.where('bfit_type', '==', BfitTemplateType.masterSeed));
    }

}

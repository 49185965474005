import { MsiModalBannerModule } from '@msi/ionic/ui/modal-banner/modal-banner.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { MsiTwoFactorAuthFormModule } from '@msi/ionic/ui/auth/forms/two-factor-auth/two-factor-auth.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TwoFactorAuthModal } from './two-factor-auth.modal';

@NgModule({
    
    declarations: [
        TwoFactorAuthModal
    ],
    imports: [
        CommonModule,
        IonicModule,
        FlexLayoutModule,
        MsiTwoFactorAuthFormModule,
        MsiModalBannerModule,
    ],
    exports: [
        TwoFactorAuthModal
    ],
})
export class TwoFactorAuthModalModule { }

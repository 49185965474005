

import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { BfitPartner } from './entities/bfit-partner';
import { BfitOrganizationsService } from './bfit-organizations';

import { Observable, BehaviorSubject, of } from 'rxjs';
import { BfitOrganization, BfitOrganizationType } from './entities/bfit-organization';


export * from './entities/bfit-partner';

@Injectable({ providedIn: 'root' })
export class BfitPartnersService extends BfitOrganizationsService {
    

    provider$: BehaviorSubject<BfitOrganization>;

    constructor(db: FirestoreService) {
        super(db);
        this.patchDefaults({ self_managed: true, type: BfitOrganizationType.Partner });
    }

    findAllForOrganization(org: BfitOrganization): Observable<BfitPartner[]> {
        return org ? super.getAll(ref => ref.where('bfit_provider_ref.id', '==', org.id)) : of([]);
    }

    newInstance(data: Partial<BfitPartner>): BfitPartner {
        return super.newInstance({ ...data }) as BfitPartner;
    }

}

import { Component, OnInit, Input } from '@angular/core';
import { GenericCardInfo } from '../generic-card-grid.module';


@Component({
  selector: 'msi-generic-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class MsiCardComponent implements OnInit {

  @Input() card: GenericCardInfo;

  submenuOpened = false;

  constructor() { }

  ngOnInit() {
  }

  subeMenuToggle() {
    this.submenuOpened = !this.submenuOpened;
  }
}

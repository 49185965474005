
import { combineLatest, Observable, of } from 'rxjs';
import { DBService } from '@msi/core/dao/db';
import { Injectable } from '@angular/core';
import { BfitJobRequest } from './entities/bfit-job-request';
import { FirestoreService } from '@msi/core/dao/firebase/firestore';
import { BfitOrganization } from './bfit-organizations';
import { switchMap } from 'rxjs/operators';
import { chain } from 'lodash';
import { BfitUser } from './bfit-users';
import { BfitUserType } from './entities/bfit-user';

export * from './entities/bfit-job-request';


@Injectable({ providedIn: 'root' })
export class BfitJobRequestsService extends DBService<BfitJobRequest> {

    constructor(db: FirestoreService) {
        super('bfit-job-requests', db);

    }

/*     findAllForPartner(partner: BfitOrganization): Observable<BfitJobRequest[]> {
        return partner ? this.getAll(ref => ref.where('partner_id', '==', partner.id)) : of([]);
    }

    findRecentForPartner(partner: BfitPartner): Observable<BfitJobRequest[]> {

        const last3days = moment().subtract(3, 'days').format('YYYY-MM-DD');
        return partner ? this.getAll(ref => ref.where('partner_id', '==', partner.id).where('updatedAt', '>=', last3days)) : of([]);
    }

    findAllForOrganization(org: BfitOrganization): Observable<BfitJobRequest[]> {

        return org ? this.getAll(ref => ref.where('bfit_org_ref.id', '==', org.id)) : of([]);
    }

    findRecentForOrganization(org: BfitOrganization): Observable<BfitJobRequest[]> {

        const last3days = moment().subtract(3, 'days').format('YYYY-MM-DD');
        return org ? this.getAll(ref => ref.where('bfit_org_ref.id', '==', org.id).where('updatedAt', '>=', last3days)) : of([]);
    }

    findAllForPartnerOrOrganization(org: BfitOrganization): Observable<BfitJobRequest[]> {
        return org ?
            combineLatest([this.findAllForPartner(org), this.findAllForOrganization(org)]).pipe(
                switchMap(([partners, orgs]) => {
                    return of(unionBy(partners, orgs, 'id'));
                })
            ) : of([]);
    } */

    findAllForAudience(org: BfitOrganization, user: BfitUser): Observable<BfitJobRequest[]> {

        const orgs$ = org && [ BfitUserType.manager, BfitUserType.partner ].includes(user.type) ?
            this.getAll(ref => ref.where('audience', 'array-contains', `organizations/${org.id}`)) :
            of([]);

        const partners$ = org && org.bfit_provider_ref && user.type === BfitUserType.partner ?
            this.getAll(ref => ref.where('audience', 'array-contains', `organizations/${org.bfit_provider_ref.id}`)) :
            of([]);

        const user$ = user ?
            this.getAll(ref => ref.where('audience', 'array-contains', `users/${user.id}`)) :
                of([]);

        return  combineLatest([ partners$, orgs$, user$ ]).pipe(
            switchMap(([ partners, orgs, users]) => {
                const result = chain([ partners, users, orgs ])
                    .flatten()
                    .uniqBy('id')
                    .value();

                return of(result);
            })
        );
    }

/*     findRecentForPartnerOrOrganization(org: BfitOrganization | BfitPartner): Observable<BfitJobRequest[]> {
        return org ?
            combineLatest([this.findRecentForPartner(org as BfitPartner), this.findRecentForOrganization(org)]).pipe(
                switchMap(([partners, orgs]) => {
                    return of(unionBy(partners, orgs, 'id'));
                })
            ) : of([]);
    } */
}

import { Injectable } from '@angular/core';
import { OVirtVirtualMachine } from '@ovirt/core/models/ovirt/ovirt-vms';

import { AngularFireFunctions } from '@angular/fire/functions';
import { BfitComputerType } from '@bfit/core/models/bfit/bfit-computers';
import { LoadingController, AlertController } from '@ionic/angular';
import { BfitOrganization } from '@bfit/core/models/bfit/bfit-organizations';
import { ObjectReference } from '@bfit/core/models/entities/ref';

@Injectable({ providedIn: 'root' })
export class OVirtVmsProcessing {

    readonly backend = 'ovirtFn-vms';

    constructor(
        private cloudService: AngularFireFunctions,
        private loadingCtrl: LoadingController,
        private alertCtrl: AlertController,
    ) { }


    async onVirtualMachine(vm: OVirtVirtualMachine, action: string): Promise<any> {

        const loading = await this.loadingCtrl.create({
            spinner: 'crescent',
            message: `${action} of vm in progress ...`
        });
        await loading.present();

        const result = await this.cloudService.httpsCallable(this.backend)({
            vm,
            action
        }).toPromise();

        loading.dismiss();

        if (result && typeof result === 'string' && result.startsWith('http')) {
            return result;
        } else {
            const alert = await this.alertCtrl.create({
                header: 'Alert',
                subHeader: `${action}: error`,
                message: result,
                buttons: ['OK']
            });
            await alert.present();
            return null;
        }
    }

    onAssignOrg(vm: OVirtVirtualMachine, orgRef: ObjectReference<BfitOrganization>): Promise<any> {

        return this.execute({
            vm,
            action: 'assignOrg',
            orgRef
        });
    }

    onChangeType(vm: OVirtVirtualMachine, type: BfitComputerType): Promise<any> {

        return this.execute({
            vm,
            action: 'changeType',
            type
        });
    }

    private async execute(args: any): Promise<any> {

        const loading = await this.loadingCtrl.create({ message: 'Please wait...' });
        await loading.present();

        try {
            const result = await this.cloudService.httpsCallable(this.backend)(args).toPromise();

            loading.dismiss();

            // console.log('result: ', args, result);
            return result;
        } catch (err) {
            await loading.dismiss();
            const alert = await this.alertCtrl.create({ message: `${err}` });
            return alert.present();
        }

    }

}

import { MsiAuthUserRegistration } from '../interfaces/auth';

const topic = '[msi-auth-register]';

export interface MsiAuthRegisterOptions {
    canUseFacebook: boolean;
    canUseTwitter: boolean;
    canUseGoogle: boolean;
    addParams: any;
}

export class MsiAuthRegisterWithGoogle {
    static readonly type: string = `${topic} with google`;
}

export class MsiAuthRegisterWithFacebook {
    static readonly type: string = `${topic} with facebook`;
}

export class MsiAuthRegisterWithTwitter {
    static readonly type: string = `${topic} with twitter`;
}


export class MsiAuthRequestRegisterDevice {
    static readonly type = `${topic}  request register device`;

}

export class MsiAuthRegisterDevice {
    static readonly type = `${topic} register device`;
    constructor(public code: string) {}
}

export class MsiAuthRegisterUser {
    static readonly type: string = `${topic} register new user`;
    constructor(public registration: MsiAuthUserRegistration) {}
}

export class MsiAuthSignUpUser {
    constructor(public payload: { name: string, email: string, phone: string }) {}
}


import { FirestoreService } from '@msi/core/dao/firebase/firestore';


import { Injectable } from '@angular/core';
import { OVirtNetwork } from './entities/ovirt-network';
import { of, Observable } from 'rxjs';
import { OVirtDataCenter } from './ovirt-data-centers';
import { CloudContextService } from './ovirt-context.service';

export * from './entities/ovirt-network';

@Injectable({ providedIn: 'root' })
export class OVirtNetworksService extends CloudContextService<OVirtNetwork> {

    constructor(db: FirestoreService) {
        super('ovirt_networks', db);
    }

    findAllForDataCenter(dc: OVirtDataCenter): Observable<OVirtNetwork[]> {
        return dc ? this.getAll(ref => ref.where('dataCenter_id', '==', dc.id)) : of([]);
    }

}

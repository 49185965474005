import { MsiNotifySuccess, MsiNotifyClose } from './../../../ui/notify/store/notify.actions';
import {
    MsiAuthLoginWithTwitter,
    MsiAuthLoginWithEmail,
    MsiAuthOperationFailure,
    MsiAuthOperationSuccessful,
    MsiAuthLoginWithFacebook,
    MsiAuthLoginWithGoogle,
    MsiAuthLoginWithPhone,
    MsiAuthLoginWithAnonymously,
    MsiAuthRegisterWithEmail,
    MsiAuthLoginRequestSignUp,
    MsiAuthLoginRequestForgotPassword
} from '../actions/auth-login.actions';

import * as uuid from 'uuid';

import { MsiAuthService } from '../services/auth.service';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SocialLink } from '../models/social-link';
import { MsiAuthNavigateTo, MsiAuthPage, MsiAuthRequestRegister, MsiAuthRequestLogin } from '../actions/nav.actions';
import { Observable } from 'rxjs';
import { MsiAuthLoginService } from '../services/login.service';
import { Injectable } from '@angular/core';
import { MsiNotifyAlert } from '@msi/ionic/ui/notify/store/notify.actions';

export interface MsiAuthViewModel {
    currentPage: string;
    title: string;

    submitted: boolean;

    socialLinks: SocialLink[];

    status: string;
    message: string;
    errorMsg: string | null;

    msgId: string;
}

@State<MsiAuthViewModel>({
    name: 'msiAuthLoginState',
    defaults: {
        currentPage: null,
        title: null,

        submitted: false,
        errorMsg: null,


        socialLinks: [],

        status: null,
        message: null,

        msgId: null,
    }
})
@Injectable({ providedIn: 'root' })
export class MsiAuthViewState {

    @Selector()
    static getState(state: MsiAuthViewModel): MsiAuthViewModel { return state; }

    @Selector()
    static currentPage(state: MsiAuthViewModel): string { return state.currentPage; }

    constructor(
        protected authLoginService: MsiAuthLoginService,
        private authAdminService: MsiAuthService,
    ) { }

    @Action(MsiAuthLoginWithEmail)
    async signInWithEmail(ctx: StateContext<MsiAuthViewModel>, action: MsiAuthLoginWithEmail): Promise<any> {

        ctx.patchState({ message: 'Signing with your email', status: 'info', errorMsg: null, submitted: true });
        return this.authLoginService.signInWithEmailAndPassword(action.email, action.password).then(() => {
            ctx.dispatch(new MsiAuthOperationSuccessful('login', 'Login successful'));
        }).catch(err => {
            ctx.dispatch(new MsiAuthOperationFailure('login', `Error signing in: ${err.message}`));

        });
    }

    @Action(MsiAuthLoginWithFacebook)
    async signInWithFacebook(ctx: StateContext<MsiAuthViewModel>): Promise<any> {
        ctx.patchState({ message: 'Signing with Facebook', status: 'info', errorMsg: null, submitted: true });
        return this.authLoginService.facebookLogin().then(() => {
            ctx.dispatch(new MsiAuthOperationSuccessful('login', 'Login successful'));
        }).catch(err => {
            ctx.dispatch(new MsiAuthOperationFailure('login', `Error signing in: ${err.message}`));

        });
    }

    @Action(MsiAuthLoginWithGoogle)
    async signInWithGoogle(ctx: StateContext<MsiAuthViewModel>): Promise<any> {
        ctx.patchState({ message: 'signing with Groogle', status: 'info', errorMsg: null, submitted: true });
        return this.authLoginService.googleLogin().then(() => {
            ctx.dispatch(new MsiAuthOperationSuccessful('login', 'Login successful'));
        }).catch(err => {
            ctx.dispatch(new MsiAuthOperationFailure('login', `Error signing in: ${err.message}`));
        });
    }

    @Action(MsiAuthLoginWithTwitter)
    async signInWithTwitter(ctx: StateContext<MsiAuthViewModel>): Promise<any> {
        ctx.patchState({ message: 'Signing with twitter', status: 'info', errorMsg: null, submitted: true });
        return this.authLoginService.twitterLogin().then(() => {
            ctx.dispatch(new MsiAuthOperationSuccessful('login', 'Login successful'));
        }).catch(err => {
            ctx.dispatch(new MsiAuthOperationFailure('login', `Error signing in: ${err.message}`));
        });
    }

    @Action(MsiAuthLoginWithPhone)
    async signInWithPhone(ctx: StateContext<MsiAuthViewModel>): Promise<any> {
        ctx.patchState({ message: 'Signing with your phone', status: 'info', errorMsg: null, submitted: true });
        return this.authLoginService.phoneLogin().then(() => {
            ctx.dispatch(new MsiAuthOperationSuccessful('login', 'Login successful'));
        }).catch(err => {
            ctx.dispatch(new MsiAuthOperationFailure('login', `Error signing in: ${err.message}`));
        });
    }

    @Action(MsiAuthLoginWithAnonymously)
    async signInAnonymously(ctx: StateContext<MsiAuthViewModel>): Promise<any> {
        ctx.patchState({ message: 'Login you as a guest', status: 'info', errorMsg: null, submitted: true });
        return this.authLoginService.anonymousLogin().then(() => {
            ctx.dispatch(new MsiAuthOperationSuccessful('login', 'Login successful'));
        }).catch(err => {
            ctx.dispatch(new MsiAuthOperationFailure('login', `Error signing in: ${err.message}`));
        });
    }

    @Action(MsiAuthOperationSuccessful)
    success(ctx: StateContext<MsiAuthViewModel>, action: MsiAuthOperationSuccessful): void {
        const oldMsgId = ctx.getState().msgId;
        if (oldMsgId) {
            ctx.dispatch(new MsiNotifyClose(oldMsgId));
        }
        const msgId = uuid.v4();
        ctx.patchState({ msgId, message: 'You have been succesfully authenticated. Please wait!', status: 'success', errorMsg: null });
        ctx.dispatch(new MsiNotifySuccess(msgId, action.msg, action.operation));
    }

    @Action(MsiAuthOperationFailure)
    failure(ctx: StateContext<MsiAuthViewModel>, action: MsiAuthOperationFailure): void {
        const oldMsgId = ctx.getState().msgId;
        if (oldMsgId) {
            ctx.dispatch(new MsiNotifyClose(oldMsgId));
        }
        const msgId = uuid.v4();
        ctx.patchState({ msgId, status: 'error', errorMsg: action.msg });
        ctx.dispatch(new MsiNotifyAlert(msgId, action.msg, action.operation));
    }

    @Action(MsiAuthRegisterWithEmail)
    async registerWithEmail(ctx: StateContext<MsiAuthViewModel>, action: MsiAuthRegisterWithEmail): Promise<any> {

        ctx.patchState({ message: 'registering new account', status: 'info', errorMsg: null, submitted: true });

        return this.authAdminService.emailSignUp(action.email, action.password).then(() => {
            ctx.dispatch(new MsiAuthOperationSuccessful('register', 'Registration successful'));

        }).catch(err => {
            ctx.dispatch(new MsiAuthOperationFailure('register', `Error during registration: ${err.message}`));
        });

    }
        
    @Action(MsiAuthNavigateTo)
    authPage(ctx: StateContext<MsiAuthViewModel>, action: MsiAuthNavigateTo): void {

        const page = action.pageName === MsiAuthPage.homePage ? null : action.pageName;

        switch (page) {
            case MsiAuthPage.loginPage:
                ctx.patchState({ title: 'Please sign in', submitted: false });
                break;
            case MsiAuthPage.registerPage:
                ctx.patchState({ title: 'Register now', submitted: false });
                break;
            case MsiAuthPage.requestPasswordPage:
                ctx.patchState({ title: 'Request password', submitted: false });
                break;
            case MsiAuthPage.resetPasswordPage:
                ctx.patchState({ title: 'Reset password', submitted: false });
                break;
            case MsiAuthPage.changePasswordPage:
                ctx.patchState({ title: 'Change password', submitted: false });
                break;
            case MsiAuthPage.firstTimeLoginPage:
                ctx.patchState({ title: 'First Time Login', submitted: false });
                break;
        }
        ctx.patchState({ currentPage: page });
    } 
   

    @Action(MsiAuthRequestRegister)
    requestRegister(ctx: StateContext<MsiAuthViewModel>): Observable<void> {
        ctx.patchState({ message: 'We will send you a link to confirm your email', status: 'info', errorMsg: null, submitted: false });
        return ctx.dispatch(new MsiAuthNavigateTo(MsiAuthPage.registerPage));
    }

    @Action(MsiAuthRequestLogin)
    requestLogin(ctx: StateContext<MsiAuthViewModel>): void {
        ctx.patchState({ message: 'Use your email and latest password', status: 'info', errorMsg: null, submitted: false });
        ctx.dispatch(new MsiAuthNavigateTo(MsiAuthPage.loginPage));
    }
    @Action(MsiAuthLoginRequestSignUp)
    requestSignUp(ctx: StateContext<MsiAuthViewModel>): void {
        ctx.patchState({ message: 'We will send you a link to confirm your email', status: 'info', errorMsg: null, submitted: false });
        ctx.dispatch(new MsiAuthNavigateTo(MsiAuthPage.registerPage));
    }

    @Action(MsiAuthLoginRequestForgotPassword)
    requestForgotPassword(ctx: StateContext<MsiAuthViewModel>): void {
        // tslint:disable-next-line:max-line-length
        ctx.patchState({ message: 'Enter your email adress and we’ll send a link to reset your password', status: 'info', errorMsg: null, submitted: false });
        ctx.dispatch(new MsiAuthNavigateTo(MsiAuthPage.resetPasswordPage));
    }
}

import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-inner-html',
  template: `
    <div [innerHtml]="to.template"></div>
  `,
})
export class FormlyFieldInnerHtmlTemplate extends FieldType {}

import { BfitOrganization } from '@bfit/core/models/bfit/bfit-organizations';
import { MsiAppContext } from '@msi/core/dao/states/app-context.state';
import { 
    State, 
    StateContext, 
    Selector, 
    Store, 
    NgxsOnInit, 
    NgxsAfterBootstrap, 
    SelectorOptions, 
    Actions, 
    ofActionDispatched 
} from '@ngxs/store';

import { BfitUser } from '../models/bfit/entities/bfit-user';
import { Organization } from '@msi/core/dao/models/organization';
import { MsiDeviceContextState } from '@msi/ionic/core/auth/states/device.state';
import { MsiAuthState } from '@msi/ionic/core/auth/states/auth.state';
import { User } from '@msi/core/dao/models/user';

import { LoginSteps } from '../interfaces/login-steps';
import { AppConfig } from '../interfaces/app-config';
import { MsiAuthRequestHomePage, MsiAuthRequestEditProfile, MsiAuthLogout } from '@msi/ionic/core/auth/actions/auth.actions';
import { AppMenu, AppMenuItem, AppMenuDivider } from '@msi/ionic/ui/side-menu/typedefs/sidemenu-options';
import { IntroPageSlide, AppRefreshList } from '../actions/bfit-app.actions';

import { AppMenuState } from '@msi/ionic/ui/side-menu/store/app-menu.state';

import { NavigationMenu, NavigateRoot } from '@msi/core/ngxs/ionic-router/ionic-router.actions';
import { BfitUserRole } from '../models/bfit/entities/bfit-org-user';
import { FaqRequestHelp } from '@msi/faq/store/faq.actions';
import { cloneDeep, Dictionary, keyBy } from 'lodash';
import { Injectable } from '@angular/core';
import { MsiAppCheckForNewUpdate } from '@msi/core/dao/states/app-context.actions';


// tslint:disable-next-line: no-empty-interface
interface StateModel {
    

}

const DEFAULTS: StateModel = {
    

};

const DefaultMenuEntries: (AppMenuItem | AppMenuDivider)[] = [
    { title: 'Home', leftIcon: 'home', dispatch: new MsiAuthRequestHomePage() },

    { isDivider: true },
    { title: 'Privacy Policies', leftIcon: 'briefcase', url: 'https://store.itsupportmsp.com/policies/terms-of-service', /*  page: '/privacy' */ },
    { title: 'Terms & Conditions', leftIcon: 'newspaper', url: 'https://store.itsupportmsp.com/policies/privacy-policy', /* page: '/terms' */ },
    { title: 'Support', leftIcon: 'mail', url: 'mailto:helpdesk@itsupportmsp.com' /* page: '/contact-us' */ },
    { title: 'About us', leftIcon: 'people', page: '/about-us' },
    { title: 'FAQs', leftIcon: 'help-circle', page: '/faq' },
    // { title: 'News', leftIcon: 'chatbubbles', page: '/news-admin' },

    { isDivider: true },

    { title: 'Find a partner', leftIcon: 'location', page: '/partners/find-a-partner', disabled: true },
    { title: 'Become a partner', leftIcon: 'ribbon', page: '/partners/become-a-partner', disabled: true },

    // { title: 'Settings', leftIcon: 'cog', page: '/auth/settings' },
    // { title: 'Register Device', leftIcon: 'phone-portrait', page: '/auth/register-device' },
    // { title: 'Lock', leftIcon: 'lock', page: '/auth/lock-device' },
    // { title: 'Unock', leftIcon: 'unlock', page: '/auth/unlock-device' },
    // { 
    //     title: 'Store Admin', 
    //     leftIcon: 'settings',
    //     page: '/store-admin', 
    //     roles: [ BfitUserRole.master, BfitUserRole.partner ] 
    // },
    { title: 'Shopping', leftIcon: 'basket', page: '/shopping', disabled: true },
    { 
        title: 'My Account', 
        leftIcon: 'person-circle',
        page: '/my-account', 
        roles: [  BfitUserRole.master, BfitUserRole.partner, BfitUserRole.manager ], 
        disabled: true
    },
    { title: 'Download viewer', leftIcon: 'download', page: '/shared/download' },
    { 
        title: 'Friend referral', 
        leftIcon: 'person-add',
        page: '/my-account/send-referral',
        roles:  [  BfitUserRole.master, BfitUserRole.partner, BfitUserRole.manager ],
        disabled: true
    },
    {
        title: 'Check for new Update', leftIcon: 'cloud-download', dispatch: new MsiAppCheckForNewUpdate()
    },

    // { 
    //     title: 'Help Desk', 
    //     leftIcon: 'help-circle', 
    //     page: '/help-desk',
    //     roles:  [  BfitUserRole.master, BfitUserRole.partner, BfitUserRole.manager, BfitUserRole.user ] 
    // },

    { isDivider: true },
    // { title: 'Login', leftIcon: 'log-in', page: '/auth/login' },
    { title: 'Logout', leftIcon: 'log-out', dispatch: new MsiAuthLogout() },
];

@State<StateModel>({
    name: 'bfitAppState',
    defaults: DEFAULTS,
})
@Injectable({ providedIn: 'root' })
export class BfitAppState implements NgxsOnInit, NgxsAfterBootstrap {

    @Selector([MsiDeviceContextState.ready])
    @SelectorOptions({ injectContainerState: false })
    static ready(deviceReady: boolean): boolean {
        return deviceReady;
    }

    @Selector([MsiDeviceContextState.ready, MsiAppContext.readyAndAuthenticated])
    @SelectorOptions({ injectContainerState: false })
    static readyAndAuthenticated(deviceReady: boolean, authenticated: boolean): boolean {
        return deviceReady && authenticated;
    }

    @Selector([MsiAppContext.currentUser])
    @SelectorOptions({ injectContainerState: false })
    static currentUser(user: User): BfitUser | null {
        return user as BfitUser;
    }

    @Selector([MsiAppContext.currentOrg])
    @SelectorOptions({ injectContainerState: false })
    static currentOrg(org: Organization): BfitOrganization | null {
        return org as BfitOrganization;
    }

    @Selector([ BfitAppState.currentUser, BfitAppState.currentOrg ])
    @SelectorOptions({ injectContainerState: false })
    static getUserAndOrg(user: BfitUser, org: BfitOrganization): [ BfitUser, BfitOrganization ] {
        return [ user, org ];
    }

    @Selector()
    @SelectorOptions({ injectContainerState: false })
    static getFirstLoginSteps(): LoginSteps[] {
        return [
            { id: 'profile', title: 'Complete profile', completed: false, page: 'ProfilePage' },
            { id: 'register_device', title: 'Register device', completed: true, page: 'RegisterDevicePage' },
            { id: 'payment_info', title: 'Enter payment info', completed: true, page: 'EnterPaymentInfoPage' },
            { id: 'email_verified', title: 'Verify email', completed: false, page: 'VerifyEmailPage' },
            { id: 'phone_verified', title: 'Verify mobile phone', completed: false, page: 'VerifyMobilePage' },
            { id: 'accept_terms', title: 'Review and Accept terms and conditions', completed: true, page: 'TermsAndConditionsPage' },
            { id: 'accept_privacy', title: 'Review and Accept privacy notices', completed: false, page: 'PrivacyPolicyPage' },
        ];
    }

    @Selector([MsiAuthState.currentUser, AppMenuState.currentMenu])
    @SelectorOptions({ injectContainerState: false })
    static getMenu(user: BfitUser, currentMenu: AppMenu): AppMenu {

        return {
            header: {
                background: '#ccc url(/assets/img/sidemenu/header.jpg) no-repeat top left / cover',
                email: user ? user.email : 'Please login first',
                picture: user ? user.photoURL : '',
                username: user ? user.displayName : 'Guest',
                title: '',
                dispatch: new MsiAuthRequestEditProfile()
            },
            entries: currentMenu && currentMenu.entries || DefaultMenuEntries,
        };

    }

    @Selector()
    @SelectorOptions({ injectContainerState: false })
    static collections(): any {
        return {
            shopping: {
                categories: 'product_categories',
                products: 'products',
                carts: 'carts',
                cart_items: 'cart',
                pending_sale_orders: 'pending-sales'
            }
        };
    }

    @Selector()
    @SelectorOptions({ injectContainerState: false })
    static getConfig(): AppConfig {
        return {
            id: 'bfit',
            title: 'Butterfly IT',
        };
    }

    @Selector()
    static getSupport(): any {
        return {
            organization: {
                name: 'Butterfly IT',
                phone: '+1 949-396-0396',
            },
            software: {
                name: 'Butterfly IT',
                phone: '+1 949-396-0396',
            },

        };
    }

    @Selector()
    @SelectorOptions({ injectContainerState: false })
    static getIntroSlides(): IntroPageSlide[] {

        return [
            {
                title: 'BUTTERFLY IT',
                description: 'IT Made Simple',
                image: '/assets/img/intro/slide1.png',
                color: '#182f19'
            },
            {
                title: 'Components and Features',
                description: 'Butterfly IT connects you to your virtual desktop wherever you are.',
                image: '/assets/img/intro/slide2.png',
                color: '#071f18'
            },
            {
                title: 'About us',
                description: '',
                image: '/assets/img/intro/slide4.png',
                color: '#0a0a02'
            }
        ];

    }

    @Selector()
    @SelectorOptions({ injectContainerState: false })
    static getMenus(): Dictionary<NavigationMenu> {

        const menus = [
            {
                id: 'partners',
                name: 'Partners',
                description: 'Manage your partners info',
                icon: '/assets/images/admin-menu-images/partnership-image.png'
            },
            {
                id: 'clients',
                name: 'Clients',
                description: 'Manage your clients info and assets',
                icon: '/assets/images/admin-menu-images/consumers-image.png'
            },
            {
                id: 'my-organization',
                name: 'My Organization',
                description: 'Manage Organization assets',
                icon: '/assets/images/admin-menu-images/consumers-image.png',
                children: [
                    {
                        id: 'users',
                        name: 'Internal Users',
                        description: 'Manage your internal users. They will have access to your network',
                        icon: '/assets/images/admin-menu-images/users-image.png'
                    },
                    {
                        id: 'external-users',
                        name: 'External Users',
                        description: 'Manage your external users. They won\'t have access to your network',
                        icon: '/assets/images/admin-menu-images/users-image.png',
                        queryParams: { external: true }
                    },

                    /* {
                        id: 'demos',
                        name: 'Manage Demos',
                        description: 'Manage your demo invitations and computers',
                        icon: '/assets/images/admin-menu-images/demos-image.png',
                    },
                    {
                        id: 'promotions',
                        name: 'Promotions',
                        description: 'Manage your Promotions',
                        icon: '/assets/images/admin-menu-images/promotions-image.png',
                    }, */

                    {
                        id: 'roles',
                        name: 'Roles',
                        description: 'Segregate users by roles for privacy',
                        icon: '/assets/images/admin-menu-images/roles-image.png'
                    },
                    {
                        id: 'gold-images',
                        name: 'Gold Images',
                        description: 'Manage all your OS versions',
                        icon: '/assets/images/admin-menu-images/gold-image.png'
                    },
                    {
                        id: 'devices',
                        name: 'Devices',
                        description: 'Manage organization devices',
                        icon: '/assets/images/admin-menu-images/cloud-computers-image.png'
                    },
                    {
                        id: 'computers',
                        name: 'Cloud Computers',
                        description: 'Manage your PCCs',
                        icon: '/assets/images/admin-menu-images/cloud-computers-image.png'
                    },
                    {
                        id: 'disks',
                        name: 'Cloud Disks',
                        description: 'Manage your user data disks',
                        icon: '/assets/images/admin-menu-images/cloud-disks-image.png'
                    },
                    {
                        id: 'shared-folders',
                        name: 'Shared Folders',
                        description: 'Manage your shared folders',
                        icon: '/assets/images/admin-menu-images/shared-folders-image.png'
                    },
                    {
                        id: 'printers',
                        name: 'Printers',
                        description: 'Manage your remote printers',
                        icon: '/assets/images/admin-menu-images/printers-image.png'
                    },
                    {
                        id: 'plugins',
                        name: 'Plugins',
                        description: 'Interface to other services',
                        icon: '/assets/images/admin-menu-images/plugins.jpg'
                    },
                    {
                        id: 'vpns',
                        name: 'VPN',
                        description: 'Manage secure connection to your company',
                        icon: '/assets/images/admin-menu-images/vpn-image.png'
                    },
                    {
                        id: 'static-routes',
                        name: 'Static Routes',
                        description: 'Add routes to your remote networks',
                        icon: '/assets/images/admin-menu-images/static-routes-iamge.png'
                    },
                    {
                        id: 'backups',
                        name: 'Backups',
                        description: 'Manage all your backups',
                        icon: '/assets/images/admin-menu-images/backup-image.png'
                    },
                    {
                        id: 'bfit-job-requests',
                        name: 'Job requests',
                        description: 'Check Internal workflow',
                        icon: '/assets/images/admin-menu-images/job-requests-image.png'
                    },

                    {
                        id: 'bfit-external-requests',
                        name: 'Ext. requests',
                        description: 'Requests from external services',
                        icon: '/assets/images/admin-menu-images/do-something2.png'
                    },
                ],
            },
            { 
                id: 'faq-admin', 
                name: 'FAQ admin', 
                icon: '/assets/images/admin-menu-images/faqs.jpg', 
                url: '/faq-admin' 
            },
            {
                id: 'demos',
                name: 'Manage Demos',
                description: 'Manage your demo invitations and computers',
                icon: '/assets/images/admin-menu-images/demos-image.png',
            },
            {
                id: 'promotions',
                name: 'Promotions',
                description: 'Manage your Promotions',
                icon: '/assets/images/admin-menu-images/promotions-image.png',
            },

            { 
                id: 'store-admin', 
                name: 'Store admin', 
                icon: '/assets/images/admin-menu-images/store-admin.png', 
                url: '/store-admin'
            },
            { 
                id: 'news-admin', 
                name: 'News admin', 
                icon: '/assets/images/admin-menu-images/news-admin.png', 
                url: '/news-admin' 
            },
            {
                id: 'help-desk',
                name: 'Help Desk',
                icon: '/assets/images/admin-menu-images/help-desk-admin.png', 
                url: '/help-desk' 
            },
            {
                id: 'bfit-job-requests',
                name: 'Job requests',
                description: 'Internal workflow',
                icon: '/assets/images/admin-menu-images/job-requests-image.png'
            },

            {
                id: 'bfit-external-requests',
                name: 'Ext. requests',
                description: 'Requests from external services',
                icon: '/assets/images/admin-menu-images/do-something2.png'
            },
        ];

        return keyBy(cloneDeep(menus), 'id');
    }

    constructor(

        private store: Store,
        private actions: Actions,
    ) { }

    ngxsOnInit(ctx: StateContext<StateModel>): void {
        // console.error ('AppState on init');

        this.actions.pipe (ofActionDispatched(FaqRequestHelp)).subscribe (() => {
            ctx.dispatch(new NavigateRoot('/help-desk/create'));
        });
    }

    ngxsAfterBootstrap(ctx: StateContext<StateModel>): void {

        this.store.select(BfitAppState.readyAndAuthenticated).subscribe(ready => {
            if (ready) {
                ctx.dispatch(new AppRefreshList());
            } else {
                ctx.setState(DEFAULTS);
            }
        });
    }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';



@Injectable({ providedIn: 'root' })
export class MsiAuthUserManagementAsyncQueue {

    constructor(
        _afs: AngularFirestore,
    ) {

    }

    /* FIXME: Probably needs to be deleted

    async createUser(user: User): Promise<User> {

        const newUser = cleanDeep(user, cleanDeepOptions);
        const newUsersRef: AngularFirestoreCollection<User> = this.afs.collection<User>('new-users');

        return newUsersRef.add(newUser).then((docref) => {
            return docref.get().then(snap => {
                return snap.data() as User;
            });
        });
    } */
}

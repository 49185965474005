import { FaqSection, Faq } from '../models/faqs';

const topic = '[faq]';

export class FaqRequestHelp {
    static readonly type: string = `${topic} request help`;
}

export class FaqAskQuestion {
    static readonly type: string = `${topic} ask question`;
    constructor(public question: string) {}
}

export class FaqInitialize {
    static readonly type: string = `${topic} faq initialize`;  
}

export class FaqSelectTopic {
    static readonly type: string = `${topic} select topic`;  
    constructor(public faqTopic: FaqSection) {}
}

export class FaqSelect {
    static readonly type: string = `${topic} select`;
    constructor(public faq: Faq) {}
}

export class FaqSelectSection {
    static readonly type: string = `${topic} select section`;
    constructor(public section: FaqSection) {}
}

export class FaqSelectSuggestion {
    static readonly type: string = `${topic} select suggestion`;
    constructor(public faq: Faq) {}
}

export class FaqSelectQuickLink {
    static readonly type: string = `${topic} select quicklink`;
    constructor(public faq: Faq) {}
}

export class FaqLoadForCurrentTopic {
    static readonly type: string = `${topic} load current topic faqs`;
    constructor(public faqTopic: FaqSection) {}
}

<div class="auth_form_main_wrapper">
    <h1 class="mb30">Enter you credentials</h1>
    <!--*********** Form ***********-->
    <form [formGroup]="loginForm"  (ngSubmit)="login(email.value, password.value)" (keyup.enter)="checkSubmit()">

        <ion-item class="ion-no-padding custom_input">
            <ion-label position="stacked">Username</ion-label>
            <ion-input type="email" placeholder="Email" formControlName="email" [class.invalid]="!email.valid && email.dirty">
            </ion-input>
        </ion-item>

        <ion-item class="ion-no-padding custom_input">
            <ion-label position="stacked">Password</ion-label>
            <ion-input type="password" placeholder="Password" formControlName="password" [class.invalid]="!password.valid && password.dirty">
            </ion-input>
        </ion-item>

        <div class="login_link_wrapper mb30 mt30 flex flex_centered flex_space">
            <ion-item class="ion-no-padding custom_input" lines="none">
                <ion-label class="mfs_12">Keep me logged in</ion-label>
                <ion-checkbox class="mr10" slot="start" color="secondary"></ion-checkbox>
            </ion-item>
            <a (click)="recover()" class="mb10 no_wrap forgot_password_link">Forgot your password?</a>
        </div>

        <div class="error_message">
            <p *ngIf="!email.valid  &&
                  email.dirty">*Please enter a valid email.</p>
            <p *ngIf="!password.valid  &&
                  password.dirty">*Password needs more than 6 characters.</p>
        </div>

        <ion-button type="submit" [disabled]="!loginForm.valid" class="btn_primary w-100 btn_medium">Continue</ion-button>
       
    </form>
    
    <div class="sign_up_link mt30">
        <span class="separator mr5"></span>
        <div class="flex ml10 mr10">
            <span class="no_wrap mr10">Don’t have account?</span>
            <a class="no_wrap" (click)="createAccount()">Sign up</a>
        </div>
        <span class="separator"></span>
    </div>


</div>
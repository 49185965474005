<!-- Search section -->
<div class="search_section">
    <div class="search_wrapper section">
        <input [(ngModel)]="question" autofocus (keyup.escape)="clear()" (keyup.enter)="ask()" placeholder="Type your query here" type="text" />
        <div class="result_list">

            <ng-container *ngIf="suggestions$ | async as suggestions">
                <div *ngIf="suggestions.length > 0 && question" class="suggestion_list">
                    <h3 class="list_title">Suggestions</h3>
                    <a (click)="selectSuggestion(link)" class="list_links" *ngFor="let link of suggestions; trackBy: trackByObjId">{{ link.question }}</a>
                </div>
            </ng-container>


            <div class="quick_list">
                <h3 class="list_title">Quick Links</h3>
                <!-- <a class="list_links"><span class="match">Lo</span>rem Ipsum</a> -->
                <a (click)="selectQuickLink(link)" class="list_links" *ngFor="let link of quickLinks$ | async; trackBy: trackByObjId">{{ link.question }}</a>
            </div>
        </div>

    </div>
    <!-- <div class="submit_question_wrapper" *ngIf="showButton">
        <ion-button color="secondary" class="btn_medium btn_primary w-100" (click)="contactSupport()">Or Ask a Question</ion-button>
    </div> -->
</div>
<!-- Search section / -->

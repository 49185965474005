import { BfitContextBasedObject } from '../bfit-context.service';


export enum BfitDeviceType {
  DataServer = 'data-server',
  VirtualFirewall = 'virtual-firewall',
  ProxyVpn = 'proxy-vpn',
  DcFirewallIn = 'dc-fw-in',
  DcFirewallOut = 'dc-fw-out',
  VpnEndpointVm = 'vpn-endpoint-vm',
  VpnEndpointPi = 'vpn-endpoint-pi',
  ProxyDataCenter = 'proxy-dc',
  ZabbixServer = 'zabbix-server',
}

export enum BfitDeviceChannel {
  Dev = 'dev',
  Beta = 'beta',
  Prod = 'prod',
}
export interface BfitDevice extends BfitContextBasedObject {
    name: string;
    type: BfitDeviceType;
    channel: BfitDeviceChannel;
    description: string;
    serial: string;
    uuid: string;
    ipaddr: string;
    externalIpAddress: string;
    photoURL?: string;

    token?: string;
}


import { State, NgxsOnInit, StateContext, Selector, NgxsAfterBootstrap } from '@ngxs/store';

import { Platform } from '@ionic/angular';
import { MsiAuthState } from './auth.state';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Injectable } from '@angular/core';

interface StateModel {
  ready: boolean;
}

const DEFAULTS: StateModel = {
  ready: false,
};
@State<StateModel>({
  name: 'msiAuthDeviceState',
  defaults: DEFAULTS
})
@Injectable({ providedIn: 'root' })
export class MsiDeviceContextState implements NgxsOnInit, NgxsAfterBootstrap {

  @Selector([MsiAuthState.ready])
  static ready(state: StateModel, authReady: boolean): boolean { return authReady && state.ready; }

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen
  ) { }

  ngxsOnInit(_ctx: StateContext<StateModel>): void {

  }

  ngxsAfterBootstrap(ctx: StateContext<StateModel>): void {


    this.platform.ready()
      .then(() => {
        this.splashScreen.hide();
        ctx.patchState({ ready: true });
      }).catch(err => {
        console.error(`platform not ready: ${err}`);
      });
  }

}

<div class="form-container" *ngIf="(ready$ | async)">

    <div class="card-container"></div>

    <form card [formGroup]="editForm" container=".card-container" card-width="300" [messages]="messages" [placeholders]="placeholders"
        formatting="true" [debug]="debug">
        <div fxLayout="row">
            <div fxFlex="50" class="wrap_input100 validate-input mr10" data-validate="Enter username">
                <input class="input100" type="text" name="number" formControlName="number" placeholder="Card number" card-number />
                <span class="focus_input100" data-placeholder="Card number"></span>
            </div>

            <div fxFlex="50" class="wrap_input100 validate-input" data-validate="Enter username">
                <input class="input100" type="text" name="last-name"  formControlName="nameOnCard" placeholder="Name on card" card-name />
                <span class="focus_input100" data-placeholder="Name on card"></span>
            </div>
        </div>

        <div fxLayout="row">
            <div fxFlex="50" class="wrap_input100 validate-input mr20" data-validate="Enter username">
                <input class="input100" type="text" name="expiry"  formControlName="expiry" placeholder="MM/YY" card-expiry />
                <span class="focus_input100" data-placeholder="MM/YY"></span>
            </div>

            <div fxFlex="50" class="wrap_input100 validate-input" data-validate="Enter username">
                <input class="input100" type="text" name="cvc"  formControlName="cvc" placeholder="CVC" card-cvc />
                <span class="focus_input100" data-placeholder="CVC"></span>
            </div>
        </div>

        <div fxLayout="row">
            <div fxFlex="100" fxLayoutAlign="start center" class="mt10">
                <ion-item class="ion-no-padding custom_input mb0" lines="none">
                    <ion-label>Save this card as a default</ion-label>
                    <ion-checkbox class="mr10" slot="start" color="secondary" [value]="defaultCard"></ion-checkbox>
                </ion-item>
            </div>
        </div>

    </form>
</div>
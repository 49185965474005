

import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';
import { BfitUser } from './entities/bfit-user';

import { of, Observable, combineLatest } from 'rxjs';
import { BfitOrganization } from './entities/bfit-organization';
import { switchMap } from 'rxjs/operators';
import { uniqBy, sortBy, flatten } from 'lodash';
import { DBService } from '@msi/core/dao/db';

export * from './entities/bfit-user';


@Injectable({ providedIn: 'root' })
export class BfitUsersService extends DBService<BfitUser> {

    constructor(db: FirestoreService) {
        super('users', db);
        this.patchDefaults({
            orgs: {},
            photoURL: 'https://goo.gl/7kz9qG',
        });
    }

    findAllForOrganization(org: BfitOrganization): Observable<BfitUser[]> {
        return org ? this.getAll(ref => ref.where('org_id', '==', org.id)) : of([]);
    }

    findAllAdminForOrganization(org: BfitOrganization): Observable<BfitUser[]> {

        if (!org) {
            return of([]);
        }

        const users$ = this.getAll(ref => ref.where('org_id', '==', org.id));
        const admin$ = this.getAll(ref => ref.where('orgs.all', '==', true));
        const multi$ = this.getAll(ref => ref.where(`orgs.${org.id}`, '==', true));

        return combineLatest([users$, admin$, multi$]).pipe(
            switchMap(users => {
                const uniqs = uniqBy(flatten(users), user => user.id);
                return of(sortBy(uniqs, user => user.displayName));
            })
        );
    }
}

import { State, StateContext, Action, Actions, ofActionDispatched, Store } from '@ngxs/store';

import {
    MsiNotifyInfo,
    MsiNotifySuccess,
    MsiNotification,
    MsiNotifyWarning,
    MsiNotifyAlert,
    MsiNotifyDebug,
    MsiNotify,
    MsiShowDesktopNotification,
    ToasterType,
    ToasterCloseMessage,
    ToasterShowProgressMessage,
    ToasterOptions,
    MsiNotifyClose,
    MsiHideNotification
} from './notify.actions';

import * as md5 from 'md5';

import { Observable } from 'rxjs';
import { produce } from '@ngxs-labs/immer-adapter';
import { NgZone, Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';

import { get, merge, omit } from 'lodash';
import { Platform, ToastController } from '@ionic/angular';
import { take, tap } from 'rxjs/operators';

interface StateModel {
    toastIds: { [key: string]: string; };
    md5s: { [key: string]: string; };
    closedIds: { [key: string]: boolean; };
}

export const DEFAULTS: StateModel = {
    toastIds: {},
    md5s: {},
    closedIds: {},
};

@State<StateModel>({
    name: 'msiToasterState',
    defaults: DEFAULTS
})
@Injectable({ providedIn: 'root' })
export class MsiToasterState {

    private ionicToasts: { [key: string]: HTMLIonToastElement; } = {};

    constructor(
        private notifyCtrl: ToastController,
        private actions: Actions,
        private toasterService: ToastrService,
        private ngZone: NgZone,
        private platform: Platform,
        private store: Store,
    ) {


        this.actions.pipe(ofActionDispatched(MsiNotifyInfo)).subscribe((action: MsiNotifyInfo) => {
            const notification: MsiNotification = {
                id: action.id,
                type: ToasterType.info,
                title: action.title,
                message: action.msg,
                options: { color: 'primary' },
                sourceType: ''

            };
            this.store.dispatch(new MsiNotify([notification]));
        });

        this.actions.pipe(ofActionDispatched(MsiNotifySuccess)).subscribe((action: MsiNotifySuccess) => {
            const notification: MsiNotification = {
                id: action.id,
                type: ToasterType.success,
                title: action.title,
                message: action.msg,
                options: { color: 'success' },
                sourceType: ''

            };
            this.store.dispatch(new MsiNotify([notification]));
        });

        this.actions.pipe(ofActionDispatched(MsiNotifyWarning)).subscribe((action: MsiNotifyWarning) => {
            const notification: MsiNotification = {
                id: action.id,
                type: ToasterType.warning,
                title: action.title,
                message: action.msg,
                options: { color: 'warning' },
                sourceType: ''

            };
            this.store.dispatch(new MsiNotify([notification]));
        });

        this.actions.pipe(ofActionDispatched(MsiNotifyAlert)).subscribe((action: MsiNotifyAlert) => {
            const notification: MsiNotification = {
                id: action.id,
                type: ToasterType.error,
                title: action.title,
                message: action.msg,
                options: { color: 'danger' },
                sourceType: ''

            };
            this.store.dispatch(new MsiNotify([notification]));
        });

        this.actions.pipe(ofActionDispatched(MsiNotifyDebug)).subscribe((action: MsiNotifyDebug) => {
            const notification: MsiNotification = {
                id: action.id,
                type: ToasterType.info,
                title: action.title,
                message: action.msg,
                options: { color: 'light' },
                sourceType: ''

            };
            this.store.dispatch(new MsiNotify([notification]));
        });

        this.actions.pipe(ofActionDispatched(MsiNotifyClose)).subscribe((action: MsiNotifyClose) => {
            
            this.store.dispatch(new ToasterCloseMessage(action.id));
        });
    }

    @Action(MsiNotify)
    async notify(ctx: StateContext<StateModel>, action: MsiNotify): Promise<any[]> {
        const state = ctx.getState();

        const now: number = Date.now();
        const notifications = action.notifications.filter(it => !state.closedIds[it.id] && !(it.expires && it.expires.getTime() < now));
        const platforms = new Set(this.platform.platforms());

        return notifications.map(async notification => {

            const title = `${notification.message}`.toLowerCase() === `${notification.title}`.toLowerCase() ? 'Please wait ...' : notification.title;
            
            if (platforms.has('desktop') || platforms.has('tablet') || platforms.has('laptop') || this.platform.width() >= 500) {
                const toastId = state.toastIds[notification.id];
                const toastMD5 = md5(JSON.stringify(omit(notification, [ 'expires'])));

                if (state.md5s[notification.id] !== toastMD5) {
                    if (toastId) {
                        this.toasterService.remove(parseInt(toastId));
                    }

                    const defaultOptions: ToasterOptions = {
                        closeButton: true,
                        preventDuplicates: true,
                        tapToDismiss: true,
                        disableTimeOut: true,
                        enableHtml: true,
                        newestOnTop: false,
                    };

                    let toast: ActiveToast<any> = null;

                    

                    switch (notification.type) {

                        case ToasterType.error:
                            toast = this.toasterService.error(notification.message, title, defaultOptions);
                            break;
                        case ToasterType.success:
                            toast = this.toasterService.success(notification.message, title, defaultOptions);
                            break;
                        case ToasterType.info:
                            toast = this.toasterService.info(notification.message, title, defaultOptions);
                            break;
                        case ToasterType.warning:
                            toast = this.toasterService.warning(notification.message, title, defaultOptions);
                            break;
                        default:
                            toast = this.toasterService.info(notification.message, title, defaultOptions);
                            break;
                    }

                    produce(ctx, draft => {
                        draft.toastIds[notification.id] = `${toast.toastId}`;
                        draft.md5s[notification.id] = toastMD5;
                    });

                    return toast.onTap.pipe(
                        take(1),
                        tap(() => {
                            
                            this.toasterService.remove(toast.toastId);
                            produce(ctx, draft => {
                                delete draft.toastIds[notification.id];
                                draft.closedIds[notification.id] = true;
                            });
                            ctx.dispatch(new MsiHideNotification(notification));
                        })
                        
                    ).toPromise();
                } else {
                    return Promise.resolve(null);
                }


            } else {
                if (state.toastIds[notification.id]) {
                    this.ionicToasts[notification.id].dismiss({ id: notification.id });
                } else {

                    const toast = await this.notifyCtrl.create({
                        header: title,
                        message: notification.message,
                        duration: get(notification.options, 'duration', 2000),
                        buttons: [
                            {
                                icon: 'close',
                                role: 'cancel',
                                handler: () => {
                                    produce(ctx, draft => {
                                        delete this.ionicToasts[notification.id];
                                        draft.closedIds[notification.id] = true;
                                    });
                                    ctx.dispatch(new MsiHideNotification(notification));
                                }
                            }
                        ]
                    });

                    produce(ctx, draft => {
                        draft.toastIds[notification.id] = toast.id;
                    });

                    this.ionicToasts[notification.id] = toast;
                    return toast.present();
                }
            }
        });
    }


    @Action(MsiShowDesktopNotification)
    showToast(ctx: StateContext<StateModel>, action: MsiShowDesktopNotification): Observable<any> {

        const state = ctx.getState();

        const toastId = state.toastIds[action.id];
        const toastMD5 = md5(JSON.stringify(action));

        if (toastId && state.md5s[action.id] !== toastMD5) {
            this.toasterService.remove(parseInt(toastId));
        }

        const options = { opacity: 1, ...action.options };

        return this.ngZone.run(() => {
            let toast;

            switch (action.type) {

                case ToasterType.error:
                    toast = this.toasterService.error(action.message, action.title, options);
                    break;
                case ToasterType.success:
                    toast = this.toasterService.success(action.message, action.title, options);
                    break;
                case ToasterType.info:
                    toast = this.toasterService.info(action.message, action.title, options);
                    break;
                case ToasterType.warning:
                    toast = this.toasterService.warning(action.message, action.title, options);
                    break;
                default:
                    toast = this.toasterService.info(action.message, action.title, options);
                    break;
            }

            if (toast) {
                produce(ctx, draft => {
                    draft.toastIds[action.id] = toast.toastId;
                    draft.md5s[action.id] = toastMD5;
                });
            }

            return toast;
        });
    }

    @Action(ToasterShowProgressMessage)
    showProgress(ctx: StateContext<StateModel>, action: ToasterShowProgressMessage): void {

        const options: Partial<ToasterOptions> = {
            closeButton: true,
            disableTimeOut: false,
            progressBar: true,
            timeOut: 60 * 10000,
            progressAnimation: 'increasing',
            tapToDismiss: true,
        };

        this.showToast(ctx, merge({}, action, { options }));
    }

    @Action(ToasterCloseMessage)
    closeMessage(ctx: StateContext<StateModel>, action: ToasterCloseMessage): void {

        const toastId = ctx.getState().toastIds[action.id];

        if (toastId) {
            this.toasterService.remove(parseInt(toastId));
            produce(ctx, draft => {
                delete draft.toastIds[action.id];
            });
        }

    }

   
}

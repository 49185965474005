import { Component, OnDestroy } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';


@Component({
    selector: 'checkbox-renderer',
    template: `
    <input 
      type="checkbox" 
      (click)="checkedHandler($event)"
      [checked]="params.value"
    />
`,
})
export class AgGridCheckboxRenderer implements ICellRendererAngularComp, OnDestroy {

    public params: any;

    refresh(): boolean {
        return false;
    }

    // afterGuiAttached?(_params?: IAfterGuiAttachedParams): void {

    // }

    agInit(params: any): void {
        this.params = params;
    }

    checkedHandler(event: any): void {
        const checked = event.target.checked;
        const colId = this.params.column.colId;
        this.params.node.setDataValue(colId, checked);
    }

    ngOnDestroy(): void { }
}

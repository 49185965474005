import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { ShoppingProductCategory } from './product-categories';

import { ShoppingProduct } from './entities/product';
import { DBService } from '@msi/core/dao/db';
export * from './entities/product';


@Injectable({ providedIn: 'root'})
export class ProductService extends DBService<ShoppingProduct> {

    constructor(db: FirestoreService) {
        super ('products', db);
        this.patchDefaults({
            features: [],
            addOns: [],
            specs: [],
        });
    }

    findAllByCategory(category: string | ShoppingProductCategory): Observable<ShoppingProduct[]> {
        const id = (typeof category === 'string') ? category : category.id;
        return this.getAll(ref => ref.where ('category_id', '==', id));
    }
}

import { FormlyFieldConfig } from '@ngx-formly/core';
import { BfitContextBasedObject } from '../bfit-context.service';

export enum IWorkflowState {
    STATE_UNSPECIFIED = 0,
    ACTIVE = 1,
    SUCCEEDED = 2,
    FAILED = 3,
    CANCELLED = 4
}

// XXX: Taken from protos.google.cloud.workflow
export interface IWorkflow {
    /** Workflow name */
    name?: (string | null);

    /** Workflow state */
    state?: any;

    /** Workflow revisionId */
    revisionId?: (string | null);

    /** Workflow createTime */
    createTime?: any;

    /** Workflow updateTime */
    updateTime?: any;

    /** Workflow revisionCreateTime */
    revisionCreateTime?: any;

}

export const IWorkflowProps: string[] = ['name', 'state', 'revisionId', 'createTime', 'updateTime', 'revisionCreateTime'];

export enum BfitJobRequestStatus {
    created = '',
    pending = 'pending',
    started = 'started',
    inProgress = 'in-progress',
    failed = 'failed',
    completed = 'completed',
    waiting = 'waiting',
    retry = 'retry',
    resume = 'resume',
}

export interface BfitJobRequestStep {

    id?: string;
    name: string;
    workflow_yaml: string;
    workflow_id?: string | null;

    args: string;
    status: BfitJobRequestStatus;
    message?: string;
    error?: string | null;
}

export interface BfitJobRequestStats {
    step: string;
    start: Date;
    end?: Date;
    status?: BfitJobRequestStatus;
}

export interface BfitJobRequestCheck {
    id: string;
    name: string;
    description?: string;
    check: string;
    fix: string;
    formConfig: FormlyFieldConfig[];
}

export const BfitChecks: BfitJobRequestCheck[] = [
    {
        id: 'windows-password',
        name: 'Windows password',
        check: '',
        fix: '',
        formConfig: [
            {
                key: 'password',
                type: 'password',
                templateOptions: {
                    label: 'Password',
                    required: true,
                }
            }
        ]
    },
    {
        id: 'gateway-missing',
        name: 'Gateway missing',
        check: '',
        fix: '',
        formConfig: [
           
        ]
    }
];

export interface BfitJobRequest extends BfitContextBasedObject {
    partner_id: string;

    name: string;
    parent_id?: string;
    user_id?: string;

    workflow_yaml: string | null;
    workflow_id?: string | null;
    workflowState_id?: string | null;

    args: string;
    status: BfitJobRequestStatus;
    statusDate: Date;
    message?: string;
    error?: string | null;

    audience?: string[];

    steps?: BfitJobRequestStep[];

    stats: BfitJobRequestStats[];
    archived: boolean;

    read: {
        [userId: string]: boolean;
    }

}


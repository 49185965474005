import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';

import { MsiIonicRouterPluginModule } from './ionic-router/public_api';
import { environment } from '@env';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,


        NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
        // NgxsLoggerPluginModule.forRoot(),
        NgxsDispatchPluginModule.forRoot(),
        MsiIonicRouterPluginModule.forRoot(),
        NgxsSelectSnapshotModule.forRoot(),
        NgxsActionsExecutingModule.forRoot(),
    ],
    exports: [

        MsiIonicRouterPluginModule,
        NgxsLoggerPluginModule,
        NgxsDispatchPluginModule,
        NgxsSelectSnapshotModule,
    ],
    providers: [],
})
export class MsiNgxsModule { }

import { Injectable } from '@angular/core';

import { AngularFireStorage } from '@angular/fire/storage';
import { MsiAppContext } from '../states/app-context.state';
import { Store } from '@ngxs/store';


@Injectable({ providedIn: 'root'})
export class AvatarUploadService {

    constructor(
        private storage: AngularFireStorage,
        private store: Store,
    ) { }


    async uploadAvatarImage(path: string, file: File): Promise<string> {
        // Create a root reference

        const orgId = this.store.selectSnapshot(MsiAppContext.currentOrg);
        const fullPath = `${orgId}/avatars/${path}`;
        const storageRef = this.storage.ref(fullPath);


        return storageRef.put(file, { contentType: file.type }).then(async (_snapshot) => {
            const url = await storageRef.getDownloadURL().toPromise();
            return url;
        });
    }

    async uploadAvatarDataUrl(path: string, dataUrl: string): Promise<string> {
        // Create a root reference

        const orgId = this.store.selectSnapshot(MsiAppContext.currentOrg);
        const fullPath = `${orgId}/avatars/${path}`;
        const storageRef = this.storage.ref(fullPath);


        return storageRef.putString(dataUrl, 'data_url').then(async (_snapshot) => {
            const url = await storageRef.getDownloadURL().toPromise();
            return url;
        });
    }
}


import { Injectable } from '@angular/core';

import { DBService } from '../db';
import { FirestoreService } from '../firebase/firestore';


export interface Upload {
    id: string;
    path: string;
    storageRef: string;
    mimeType: string;
    url: string;
    user_id: string;
    org_id: string;

}

@Injectable({ providedIn: 'root'})
export class UploadsService extends DBService<Upload> {
    constructor(db: FirestoreService) {
        super ('uploads', db);
    }
}

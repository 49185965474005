import { ObjectReference } from './../../../../app/@bfit/core/models/entities/ref';
// TODO: replace by our own User

import { Injectable } from '@angular/core';
import { DBService } from '@msi/core/dao/db';
import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { PostalAddress } from './address';
import { MsiAccount } from '@msi/account/core/models/accounts';

export interface UserSettings {
  idleTimeoutAction: 'logout' | 'lock' | 'ask' | 'nothing';
  idleTimeoutMinutes: number;  
  protectPccs: boolean;
}

export interface User {
    id: string;
    uid?: string;
    firstName?: string;
    lastName?: string;
    displayName: string;
    middleName?: string;
    address?: PostalAddress;
    formattedAddress?: string;
    email?: string;
    emailVerified?: boolean;
    changePassword?: boolean;
    phone?: string;
    mobile: string;
    photoURL?: string;
    providerId?: string;
    org_id: string;
    roles?: string[];
    orgs: { [org: string]: boolean };

    firstTimeLogin: boolean;
    requires2fa: boolean;

    disabled: boolean;

    settings?: UserSettings;

    account_ref?: ObjectReference<MsiAccount>;
}

@Injectable({ providedIn: 'root'})
export class UsersService extends DBService<User> {

  constructor(protected db: FirestoreService) {
    super ('users', db);
  }

}


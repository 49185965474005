
const topic = '[msi-notify]';

export interface ToasterOptions {
    closeButton: boolean;
    timeOut?: number;
    extendedTimeOut?: number;
    disableTimeOut: boolean;
    easing?: string;
    enableHtml?: boolean;
    progressBar?: boolean;
    progressAnimation?: 'decreasing' | 'increasing';
    positionClass?: string;
    tapToDismiss: boolean;
    preventDuplicates: boolean;
    newestOnTop: boolean;
}

export enum ToasterType {
    error = 'error',
    success = 'success',
    info = 'info',
    warning = 'warning'
}

export interface MsiNotification {
    id: string;
    title: string;
    type: ToasterType;
    message: string;
    options?: MsiNotifyOptions;
    expires?: Date;
    sourceType: string;
}
export interface MsiNotifyOptions {
    duration?: number;
    showCloseButton?: boolean;
    position?: 'top' | 'bottom' | 'middle';
    color?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark';
    keyboardClose?: boolean;
}

export class MsiNotify {
    static readonly type: string = `${topic} send notifications`;
    constructor(public notifications: MsiNotification[]) {}
}
export class MsiNotifyInfo {
    static readonly type: string = `${topic} info`;
    constructor(public id: string, public msg: string, public title?: string, public options?: MsiNotifyOptions) { }
}

export class MsiNotifySuccess {
    static readonly type: string = `${topic} success`;
    constructor(public id: string, public msg: string, public title?: string, public options?: MsiNotifyOptions) { }
}


export class MsiNotifyAlert {
    static readonly type: string = `${topic} alert`;
    constructor(public id: string, public msg: string, public title?: string, public options?: MsiNotifyOptions) { }
}

export class MsiNotifyDebug {
    static readonly type: string = `${topic} debug`;
    constructor(public id: string, public msg: string, public title?: string, public options?: MsiNotifyOptions) { }
}

export class MsiNotifyWarning {
    static readonly type: string = `${topic} warning`;
    constructor(public id: string, public msg: string, public title?: string, public options?: MsiNotifyOptions) { }
}

export class MsiNotifyClose {
    static readonly type: string = `${topic} close`;
    constructor(public id: string) { }
}
export class MsiShowDesktopNotification {
    static readonly type: string = `${topic} show desktop toaster message`;
    // tslint:disable-next-line: max-line-length
    constructor(public id: string, public title: string, public message: string, public type?: ToasterType, public options?: Partial<ToasterOptions>) {}
}

export class ToasterShowProgressMessage {
    static readonly type: string = `${topic} show toaster progress message`;
    // tslint:disable-next-line: max-line-length
    constructor(public id: string, public title: string, public message: string, public type?: ToasterType, public options?: Partial<ToasterOptions>) {}
}

export class ToasterCloseMessage {
    static readonly type: string = `${topic} close toaster message`;
    constructor(public id: string) {}
}

export class MsiHideNotification {
    static readonly type: string = `${topic} hide notification`;
    constructor(public notification: MsiNotification) {}
}

import { merge } from 'lodash';

import { DBService, IdentityObject } from '../db';
import { QueryFn } from '@angular/fire/firestore';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { FirestoreService } from '../firebase/firestore';

import { Organization } from '../models/organization';
import { switchMap } from 'rxjs/operators';


export interface OrgBasedObject extends IdentityObject {
    org_id: string;
}

export const CURRENT_ORG_ID$: BehaviorSubject<string> = new BehaviorSubject(null);

export abstract class OrgBasedDbService<T extends OrgBasedObject> extends DBService<T> {

    constructor(protected dbname: string, protected db: FirestoreService) {
        super (dbname, db);
        CURRENT_ORG_ID$.subscribe (_orgId => {
            // if the org change we force org_id new value on all created instances
            this.patchDefaults ({ });
        });
    }

    protected get collectionPath (): string {
        return `organizations/${CURRENT_ORG_ID$.value}/${this.dbname}`;
    }

    protected get currentOrgId(): string {
        return CURRENT_ORG_ID$.value;
    }

    protected getAll(queryFn?: QueryFn): Observable<T[]> {
        return CURRENT_ORG_ID$.pipe (
            switchMap (org => {
                return org ? super.getAll (queryFn) : [];
            })
        );
    }

    findAllForOrganization (org: Organization): Observable<T[]> {
        return org ? this.getAll (ref => ref.where ('org_id', '==', org.id)) : of([]);
      }


    patchDefaults (data: Partial<T>): void {
        super.patchDefaults ( merge (data, { org_id: CURRENT_ORG_ID$.value } ) );
    }
}

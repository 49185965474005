
import { Injectable } from '@angular/core';

import { DBService } from '../../core/dao/db';
import { FirestoreService } from '../../core/dao/firebase/firestore';
import { BlogEntry } from './entities/blog-entry';


@Injectable({ providedIn: 'root'})
export class BlogEntriesService extends DBService<BlogEntry> {
    constructor(db: FirestoreService) {
        super ('blog-entries', db);
    }
}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AppHeaderComponent } from './header.component';
import { ShoppingCartIconModule } from '@msi/e-commerce/ionic/shopping/components/cart-icon/cart-icon.component.module';

import { RouterModule } from '@angular/router';

import { CurrentUserHeaderSlotModule } from '@bfit/ionic/components/current-user-header-slot/current-user-header-slot.module';
import { BfitContextHelpModule } from '@bfit/ionic/context-help/context-help.module';

@NgModule({
    declarations: [
        AppHeaderComponent,

    ],
    imports: [
        CommonModule,
        IonicModule,
        ShoppingCartIconModule,
        CurrentUserHeaderSlotModule,
        BfitContextHelpModule,
        RouterModule,
    ],
    exports: [
        AppHeaderComponent,
        CurrentUserHeaderSlotModule,
    ],
    providers: [],
})
export class AppHeaderModule { }

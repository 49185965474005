import { environment } from '@env';

import { AlertController, ModalController, LoadingController } from '@ionic/angular';
import { Organization, OrganizationsService } from '../models/organization';
import { User } from '../models/user';

import { Store, State, Selector, Action, StateContext, NgxsOnInit } from '@ngxs/store';

import { produce } from '@ngxs-labs/immer-adapter';


import { MsiAuthRequestLogin } from '@msi/ionic/core/auth/actions/nav.actions';
import { IdleTimeoutModal } from '@bfit/ionic/auth/modals/idle-timeout/idle-timeout.modal';

import {
    MsiAppIsReady,
    AppContextSwitchOrganization,
    AppContextUpdateLogoAndName,
    MsiAppNewVersionAvailable,
    MsiAppUpdateVersion,
    MsiSetIdleTimeout,
    MsiAppCheckForNewUpdate,
} from './app-context.actions';

import { tap, filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CURRENT_ORG_ID$ } from '../services/org-based-db.service';

import { MsiAuthContextHasChanged, MsiAuthRequestHomePage, MsiAuthLogout } from '@msi/ionic/core/auth/actions/auth.actions';
import { MsiAuthState } from '@msi/ionic/core/auth/states/auth.state';
import { Actions } from '@ngxs/store';
import { ActionStatus } from '@ngxs/store/src/actions-stream';
import { SwUpdate } from '@angular/service-worker';
import { Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';


export interface MsiAppContextModel {
    currentUser: User;
    currentOrg: Organization;
    ready: boolean;
    profile: any; // FIXME: Change to UserProfle
}

@State<MsiAppContextModel>({
    name: 'msiAppContextState',
    defaults: {
        currentUser: null,
        currentOrg: null,
        ready: false,
        profile: null,
    }
})
@Injectable({ providedIn: 'root' })
export class MsiAppContext implements NgxsOnInit {


    @Selector()
    static currentUser(state: MsiAppContextModel): User { return state.currentUser; }

    @Selector()
    static currentOrg(state: MsiAppContextModel): Organization { return state.currentOrg; }

    @Selector()
    static ready(state: MsiAppContextModel): boolean { return state.ready; }

    @Selector()
    static readyAndAuthenticated(state: MsiAppContextModel): boolean { return state.ready && !!state.currentUser && !!state.currentOrg; }

    @Selector()
    static getState(state: MsiAppContextModel): MsiAppContextModel { return state; }

    constructor(
        private store: Store,
        private organizationService: OrganizationsService,
        private actions$: Actions,
        private alertCtrl: AlertController,
        private swUpdate: SwUpdate,
        private idleService: Idle,
        private modalCtrl: ModalController,
        private loadingCtrl: LoadingController,
    ) {

        if (!environment.production) {
            this.actions$.pipe(
                filter(ctx => ctx.status === ActionStatus.Dispatched),
                map(ctx => ctx.action),
            ).subscribe(action => {
                const log = action.__proto__.constructor.type;
                // tslint:disable-next-line: no-console
                console.time(log);
                console.log('DISPATCHED: ', log, action);
            });

            this.actions$.pipe(
                filter(ctx => ctx.status === ActionStatus.Canceled),
                map(ctx => ctx.action)
            ).subscribe(action => {
                const log = action.__proto__.constructor.type;
                // tslint:disable-next-line: no-console
                console.timeEnd(log);
                console.log('CANCELED: ', log, action);
            });

            this.actions$.pipe(
                filter(ctx => ctx.status === ActionStatus.Successful),
                map(ctx => ctx.action),
            ).subscribe(action => {
                const log = action.__proto__.constructor.type;
                // tslint:disable-next-line: no-console
                console.timeEnd(log);
                console.log('SUCCESSFUL: ', log, action);
            });

            this.actions$.pipe(
                filter(ctx => ctx.status === ActionStatus.Errored),
                map(ctx => ctx.action),
            ).subscribe(action => {
                const log = action.__proto__.constructor.type;
                // tslint:disable-next-line: no-console
                console.timeEnd(log);
                console.error('ERRORED: ', log, action);
            });
        }
    }

    ngxsOnInit(_ctx: StateContext<MsiAppContextModel>): void {

        if (this.swUpdate.isEnabled) {

            this.swUpdate.available.subscribe((version) => {

                this.store.dispatch(new MsiAppNewVersionAvailable(version));
            });
        }
    }

    @Action(MsiAuthContextHasChanged, { cancelUncompleted: true })
    contextHasChanged(ctx: StateContext<MsiAppContextModel>, _action: MsiAuthContextHasChanged): Observable<Organization> {

        const user = this.store.selectSnapshot(MsiAuthState.currentUser);

        if (user) {
            produce(ctx, (draft) => {
                draft.currentUser = user as User;  // FIXME:
                draft.currentOrg = null;
            });

            return this.organizationService.get(user.org_id).pipe(
                tap(org => {
                    CURRENT_ORG_ID$.next(org.id);
                    ctx.patchState({ currentOrg: org });
                    ctx.dispatch([
                        new MsiAppIsReady(),
                        new AppContextUpdateLogoAndName(org.photoURL, org.name),
                    ]);

                })
            );
        } else {
            CURRENT_ORG_ID$.next(null);
            produce(ctx, (draft) => {
                draft.currentUser = null;
                draft.currentOrg = null;
                draft.ready = false;
            });

        }
    }


    @Action(MsiAppIsReady, { cancelUncompleted: true })
    appIsReady(ctx: StateContext<MsiAppContextModel>): Observable<Organization> {
        return this.store.select(MsiAppContext.currentOrg).pipe(
            tap(org => {
                if (org) {
                    ctx.patchState({ ready: true });
                    // return OrgBasedDbService.setOrgObservable (this.store.select(MsiAppContext.currentOrg));
                    CURRENT_ORG_ID$.next(org.id);
                    ctx.dispatch([
                        // new MsiSetIdleTimeout(600),
                        new MsiAuthRequestHomePage()
                    ]);
                } else {
                    ctx.patchState({ ready: false });
                    CURRENT_ORG_ID$.next(null);
                    ctx.dispatch(new MsiAuthRequestLogin());
                }
            })
        );
    }

    @Action(AppContextSwitchOrganization)
    switchOrg(ctx: StateContext<MsiAppContextModel>, action: AppContextSwitchOrganization): void {
        ctx.patchState({ currentOrg: action.organization });
    }

    @Action(MsiAppNewVersionAvailable)
    async newVersionAvailable(ctx: StateContext<MsiAppContextModel>, action: MsiAppNewVersionAvailable): Promise<void> {

        const alert = await this.alertCtrl.create({
            header: 'New version available',
            message: 'Do you want to update now?',
            buttons: [
                {
                    text: 'Later',
                    role: 'cancel',
                },
                {
                    text: 'Update now',
                    handler: () => { ctx.dispatch(new MsiAppUpdateVersion(action.version)); }
                }
            ]
        });

        await alert.present();
    }

    @Action(MsiAppUpdateVersion)
    async updateVersion(_ctx: StateContext<MsiAppContextModel>, _action: MsiAppUpdateVersion): Promise<void> {

        await this.swUpdate.activateUpdate();
        window.location.reload();
    }

    @Action(MsiAppCheckForNewUpdate)
    async checkFornewUpdate(_ctx: StateContext<MsiAppContextModel>): Promise<void> {

        const progress = await this.loadingCtrl.create({ message: 'Checking for new version',  backdropDismiss: true, keyboardClose: true });
        await progress.present();
        try {
            await  this.swUpdate.checkForUpdate();
        } catch(err) {
            console.error(`check for new update: ${err.message}`);
        }
        await progress.dismiss();
    }

    @Action(MsiSetIdleTimeout)
    setIdletimeout(ctx: StateContext<MsiAppContextModel>, action: MsiSetIdleTimeout): Observable<any> {
        this.idleService.setIdle(action.timeout);
        this.idleService.setTimeout(10);
        this.idleService.setInterrupts(DEFAULT_INTERRUPTSOURCES);


        // this.idleService.onIdleStart.subscribe(() => console.error('idle has started'));
        // this.idleService.onIdleEnd.subscribe(() => console.error('you are not idle anymore'));
        // this.idleService.onTimeout.subscribe(() => console.error('idle timeout'));

        this.idleService.watch();

        return this.idleService.onTimeout.pipe(
            tap(async () => {
                console.error('idle has timeout')
                const modal = await this.modalCtrl.create({
                    component: IdleTimeoutModal,
                });

                modal.onDidDismiss().then(result => {
                    if (!result || !result.data) {
                        ctx.dispatch(new MsiAuthLogout());
                    }
                });

                await modal.present();
            })
        );
    }
}

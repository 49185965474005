

import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';
import { BfitGoldImage } from './entities/bfit-gold-image';

import { CURRENT_ORGANIZATION_CONTEXT$ } from './bfit-context.service';
import { BfitCloud } from './bfit-clouds';
import { BfitUser } from './entities/bfit-user';
import { BfitOrganization } from './bfit-organizations';
import { Observable, of } from 'rxjs';
import { CollectionReference, QueryFn } from '@angular/fire/firestore';
import { switchMap, map } from 'rxjs/operators';
import { DBService } from '@msi/core/dao/db';

export * from './entities/bfit-gold-image';


@Injectable({ providedIn: 'root' })
export class BfitGoldImagesService extends DBService<BfitGoldImage> {


  constructor(db: FirestoreService) {
    super('bfit-gold-images', db);
  }

  findAll(queryFn?: QueryFn): Observable<BfitGoldImage[]> {
    return CURRENT_ORGANIZATION_CONTEXT$.pipe(
      switchMap(ctx => {
        if (ctx.currentOrg) {
          return this.getAll(this.buildQueryForOrg(ctx.currentOrg, queryFn));
        } else {
          return of([]);
        }
      })
    );
  }

  findAllForOrganization(org: BfitOrganization): Observable<BfitGoldImage[]> {
    return org ? super.getAll(ref => ref.where('bfit_org_ref.id', '==', org.id)) : of([]);
  }

  findAllAvailableToUser(_user: BfitUser): Observable<BfitGoldImage[]> {
    return this.findAll().pipe(
      // FIXME: We need to check with the user role
      map(images => images.filter(image => !image.allowedRole_refs || image.allowedRole_refs.length === 0))
    );
  }

  private buildQueryForOrg(org: BfitOrganization, queryFn?: QueryFn): QueryFn {
    return (ref: CollectionReference) => (queryFn ? queryFn(ref) : ref).where('bfit_org_ref.id', '==', org && org.id || null);
  }

  findAllWithUUID(uuid: string): Observable<BfitGoldImage[]> {
    return this.getAll(ref => ref.where('uuid', '==', uuid));
  }

  findAllOwnedByOrg(org: BfitOrganization): Observable<BfitGoldImage[]> {
    return org ? this.getAll(ref => ref.where('bfit_org_ref.id', '==', org.id)) : of([]);
  }

  findAllUsedByUser(user: BfitUser): Observable<BfitGoldImage[]> {
    return user ? this.getAll(ref => ref.where('bfit_user_ref.id', '==', user.id)) : of([]);
  }

  findAllInCloud(cloud: BfitCloud): Observable<BfitGoldImage[]> {
    return cloud ? this.getAll(ref => ref.where('bfit_cloud_ref.id', '==', cloud.id)) : of([]);
  }

  findAllInherited(org: BfitOrganization, image: BfitGoldImage): Observable<BfitGoldImage[]> {
    return org && image ?
      this.getAll(ref => ref.where('bfit_provider_ref.id', '==', org.id).where('serial', '==', image.serial)) :
      of([]);
  }
}

import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';


@Component({
  selector: 'formly-input-time',
  template: `
      <!-- <input matInput [ngxTimepicker]="timePicker" [placeholder]="to.placeholder" [formControl]="formControl" [formlyAttributes]="field" readonly/>
      <ngx-material-timepicker-toggle [for]="timePicker"></ngx-material-timepicker-toggle>
      <ngx-material-timepicker #timePicker [appendToInput]="true"></ngx-material-timepicker> -->
      <ngx-timepicker-field [controlOnly]="true" [formControl]="formControl" [formlyAttributes]="field"
        [format]="to.format"
      ></ngx-timepicker-field>

  `,
  styles: [
    `::ng-deep .ngx-timepicker {
        border-bottom: none !important;
    }`
  ]
})
// tslint:disable-next-line: component-class-suffix
export class FormlyInputTimeTemplate extends FieldType implements OnInit {

  ngOnInit(): void {

    if (this.to.label) {
      this.to.floatLabel = 'always';
      // this.to.appearance = 'outline';
    }

  }

}
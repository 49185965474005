import { ShoppingProductCategory } from '../models/product-categories';
import { ShoppingProduct } from '../models/product';
import { CatalogLayoutColumn, ShoppingCatalog } from '../models/catalog';


const topic = '[Shopping]';

// --- Product Category ----

export class UpdateProductCategories {
    static readonly type: string = `${topic} refresh product categories`;
}

export class ResetProductCategoryFilter {
    static readonly type: string = `${topic} reset product category filter`;
}


export class SetProductCategoryFilter {
    static readonly type: string = `${topic} set product category filter`;
    constructor(public filter: string) { }
}

export class SelectCatalog {
    static readonly type: string = `${topic} select catalog`;
    constructor(public catalog: string | ShoppingCatalog) {}
}

export class SelectProductCategory {
    static readonly type: string = `${topic} select product category`;
    constructor(public category: string | ShoppingProductCategory) { }
}

export class SelectCatalogLayoutColumn {
    static readonly type: string = `${topic} select catalog layout column`;
    constructor(public column: CatalogLayoutColumn) {}
}

// --- Product ----

export class UpdateProductList {
    static readonly type: string = `${topic} update product list`;
}

export class ResetProductFilter {
    static readonly type: string = `${topic} reset product filter`;
}


export class SetProductFilter {
    static readonly type: string = `${topic} set product filter`;
    constructor(public filter: string) { }
}


export class SelectProduct {
    static readonly type: string = `${topic} select product`;
    constructor(public product: ShoppingProduct | string) { }
}

export class ShoppingNotificationError {
    static readonly type: string = `${topic} error`;
    constructor(public error: string) { }
}

export class ShoppingNotificationSuccess {
    static readonly type: string = `${topic} success`;
    constructor(public msg: string, public duration?: number) { }
}

export class ShoppingShowFullScreeenImage {
    static readonly type: string = `${topic} show image fullscreen`;
    constructor(public url: string) { }
}

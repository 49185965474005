import { NgModule } from '@angular/core';
import { TextToggleComponent } from './text-toggle.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        TextToggleComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
    ],
    exports: [
        TextToggleComponent
    ]
})
export class MsiTextToggleModule { }



import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-autocomplete',
    template: `
        <ion-item lines="none">
            <ion-label position="stacked">{{ to.label }}</ion-label>
            <ng-select class="w-100" *ngIf="to.items" [formControl]="formControl" [formlyAttributes]="field" 
                [appendTo]="to.appendTo || 'body'"
                [appearance]="to.appearance || 'floating'"
                [bindValue]="to.bindValue"
                [bindLabel]="to.bindLabel"
                [clearable]="to.clearable"
                [items]="to.items"
                [multiple]="!!to.multiple"
                [readonly]="!!to.readonly"
                [addTag]="to.addTag"
                [addTagText]="to.addTagText"
            >
            </ng-select>
        </ion-item>
  `,
})
export class FormlySelectTemplate extends FieldType {



}
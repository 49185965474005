import { OrgBasedDbService } from '@msi/core/dao/services/org-based-db.service';

import { Injectable } from '@angular/core';
import { FirestoreService } from '@msi/core/dao/firebase/firestore';
import { BfitCampaign } from './entities/bfit-campaign';

export * from './entities/bfit-campaign';

@Injectable({ providedIn: 'root' })
export class BfitCampaignsService extends OrgBasedDbService<BfitCampaign> {

    constructor(db: FirestoreService) {
        super('bfit_campaigns', db);
    }
}

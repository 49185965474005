
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

/// Notify users about errors and other helpful stuff
export interface Msg {
  content: string;
  style: string;
}


@Injectable({ providedIn: 'root' })
export class NotifyService {

  private _msgSource: Subject<Msg | null> = new Subject<Msg | null>();

  msg: Observable<Msg> = this._msgSource.asObservable();

  update(content: string, style: 'error' | 'info' | 'success'): void {
    const msg: Msg = { content, style };
    this._msgSource.next(msg);
  }

  clear(): void {
    this._msgSource.next(null);
  }
}

export const environment = {
  production: true,

  appname: 'Butterfly IT',
  version: '1.11.10',
  
  firebaseConfig: {
    apiKey: 'AIzaSyCx94lE0JcCz4wnWgPDAwwMgHDfLwMkoTc',
    authDomain: 'butterfly-it-7f77b.firebaseapp.com',
    databaseURL: 'https://butterfly-it-7f77b.firebaseio.com',
    projectId: 'butterfly-it-7f77b',
    storageBucket: 'butterfly-it-7f77b.appspot.com',
    messagingSenderId: '175436737987'
  },

  theme: 'corporate',
  debugDbCalls: false,

  socialLinks: [
    {
      url: 'https://google.com/plus/butterflyit',
      target: '_blank',
      icon: 'socicon-google',
    },
    {
      url: 'https://www.facebook.com/butterflyit/',
      target: '_blank',
      icon: 'socicon-facebook',
    },
    {
      url: 'https://twitter.com/butterflyit',
      target: '_blank',
      icon: 'socicon-twitter',
    },
  ],

  authConfig: {
    loginPage: '/auth/login',
    registerPage: '/auth/register',
    profilePage: '/auth/profile',
    firstTimeLoginPage: '/auth/first-time-login',
    homePage: '/home',
    registerDevicePage: '/auth/register-device',
    introPage: '/intro',
    unlockDevicePage: '/auth/unlock',
    lockDevicePage: '/auth/lock',
    verifyMobilePage: '/auth/verify-mobile',
    appName: 'Butterfly IT',
    theme: 'default',
    socialLinks: [],
    backgroundImg: '/assets/img/background.jpg',
    twofactorAuthPage: '/auth/2fa',
  },

  stripeApiURL: 'https://us-central1-butterfly-it-7f77b.cloudfunctions.net/stripe-api1',
  stripePublishableKey: 'pk_test_MAjJveW0mByJSWM331jGsKWT',

  uploadMediaUrl: 'https://us-central1-butterfly-it-7f77b.cloudfunctions.net/upload-v1',

  debug: {
    createComputers: false,
    createUsers: false,
    createSingleUserPcc: false,
    selectGoldImage: false,
    createOrg: false,
    createComputer: false,
    createUserDisk: false,
    createUser: false,
    createVpn: false,
    creditCardEntry: false,
    publishGoldImage: false,
    pushGoldImageToclients: false,
    accountNeeded: false,
    cloneGoldImage: false,
  }
};


import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-clock',
    template: `
    <msi-time-picker></msi-time-picker>
  `,
    styles: [
        'input { border: none; width: 100%; }',

    ]
})
export class FormlyClockTemplate extends FieldType implements OnInit {

    editForm: FormGroup = new FormGroup({});

    ngOnInit(): void {
        this.editForm.valueChanges.subscribe(changes => {
            this.formControl.setValue(changes);
        });

    }

}


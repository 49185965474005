

const topic = '[msi-auth-nav]';

export enum MsiAuthPage {
    loginPage = 'login',
    logoutPage = 'logout',
    registerPage = 'register',
    verifyMobilePage = 'verifyMobile',
    profilePage = 'profile',
    firstTimeLoginPage = 'firstTimeLogin',
    homePage = 'home',
    registerDevicePage = 'registerDevice',
    introPage = 'intro',
    unlockPage = 'unlock',
    requestPasswordPage = 'requestPassword',
    resetPasswordPage = 'resetPassword',
    changePasswordPage = 'changePassword',
}

export class MsiNavRequestPage {
    static readonly type = `${topic} request page`;
    constructor(public pageName: string) { }
}

export class MsiAuthRequestRegister {
    static readonly type = `${topic} request register page`;
}


export class MsiAuthRequestLogin {
    static readonly type = `${topic} request login page`;
}


export class MsiAuthRequestLogout {
    static readonly type = `${topic} request logout page`;

}

export class MsiAuthNavigateTo {
    static readonly type = `${topic} navigate to page`;
    constructor(public pageName: MsiAuthPage, public params?: any) { }
}

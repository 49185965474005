import { OrgBasedObject } from '@msi/core/dao/services/org-based-db.service';
import { BfitPluginType } from './bfit-plugin';

export enum BfitExternalRequestStatus {
    created = '',
    pending = 'pending',
    started = 'started',
    inProgress = 'in-progress',
    failed = 'failed',
    completed = 'completed',
    waiting = 'waiting',
    retry = 'retry',
    resume = 'resume',
  }

export interface BfitExternalRequest extends OrgBasedObject {
    name: string;
    description?: string;
    status: BfitExternalRequestStatus;
    plugin: BfitPluginType;
    dataJson: string;

}
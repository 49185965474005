<div class="login-container" fxLayout="row" fxLayoutAlign="center start" fxlayoutGap="8px">
  <img class="logo" src="/assets/img/auth/padlock.png" style="width:15rem" />

  <!--*********** Form ***********-->
  <form [formGroup]="passwordForm" (submit)="changePassword()" novalidate>

    <ion-list class="ion-padding">
      <ion-item>
        <ion-label position="stacked">Current password</ion-label>
        <ion-input tappable #currentPassword type="password" formControlName="currentPassword">
        </ion-input>
        <ion-note><a (click)="forgotPassword()">I forgot my password</a></ion-note>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">New Password</ion-label>
        <ion-input tappable #password type="password" formControlName="password">
        </ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">Verify Password</ion-label>
        <ion-input tappable #verifyPassword type="password" formControlName="verifyPassword">
        </ion-input>
      </ion-item>

    </ion-list>

    <div class="submit-box">
      <div class="ion-padding">
        <ion-button size="block" type="submit" color="primary"
          [disabled]="passwordForm.touched && (passwordForm.invalid || password.value != verifyPassword.value)">
          Change password
        </ion-button>
      </div>
    </div>
  </form>
</div>
import { LoadingController, AlertController } from '@ionic/angular';
import { Component } from '@angular/core';
import { FormBuilder, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { MsiAuthService } from '@msi/ionic/core/auth/services/auth.service';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { MsiAuthRequestNewPassword } from '@msi/ionic/core/auth/actions/auth-login.actions';
import { MsiAuthRequestLogin } from '@msi/ionic/core/auth/actions/nav.actions';


@Component({
  selector: 'msi-auth-reset-password-form',
  templateUrl: 'reset-password.html',
  styleUrls: ['./reset-password.scss']
})
export class ResetPasswordFormComponent {

  public resetPasswordForm: FormGroup;
  public backgroundImage: any = '/assets/bg3.jpg';

  constructor(
    public authData: MsiAuthService,
    public fb: FormBuilder,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController) {

    const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    this.resetPasswordForm = fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(EMAIL_REGEXP)])],
    });
  }

  get email(): AbstractControl { return this.resetPasswordForm.get('email'); }

  @Dispatch()
  resetPassword = (email: string) => new MsiAuthRequestNewPassword(email)

  @Dispatch()
  goBack = () => new MsiAuthRequestLogin()

}

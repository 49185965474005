

import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';
import { BfitComputer, BfitComputerStatus } from './entities/bfit-computer';

import { Observable, of, combineLatest } from 'rxjs';
import { BfitUser } from './entities/bfit-user';

import { BfitCloud } from './bfit-clouds';
import { DBService } from '@msi/core/dao/db';
import { BfitOrganization } from './bfit-organizations';
import { CURRENT_ORGANIZATION_CONTEXT$ } from './bfit-context.service';
import { switchMap } from 'rxjs/operators';
import { QueryFn, CollectionReference } from '@angular/fire/firestore';
import { OVirtTemplate } from '@bfit/core/interfaces/ovirt';


export * from './entities/bfit-computer';


@Injectable({ providedIn: 'root' })
export class BfitComputerService extends DBService<BfitComputer> {
  
  
  constructor(db: FirestoreService) {
    super('bfit-computers', db);
    this.patchDefaults({
      status: BfitComputerStatus.Init,
      monitors: 1,
      applications: []
    });
  }

  getDesktopsForUser(user: BfitUser): Observable<BfitComputer[]> {

    if (user) {
      const mine$ = this.getAll(ref => ref.where('bfit_user_ref.id', '==', user.id));
      const other$ = this.getAll(ref => ref.where('bfit_shared_with_ref.id', '==', user.id));
      return combineLatest([mine$, other$]).pipe(
        switchMap(([mine, other]) => {
          return of(mine.concat(other));
        })

      );
    } else {
      return of([]);
    }
  }

  findAll(queryFn?: QueryFn): Observable<BfitComputer[]> {
    return CURRENT_ORGANIZATION_CONTEXT$.pipe(
      switchMap(ctx => {
        if (ctx.currentOrg) {
          return this.getAll(this.buildQueryForOrg(ctx.currentOrg, queryFn));
        } else {
          return of([]);
        }
      })
    );
  }

  findAllForOrganization(org: BfitOrganization): Observable<BfitComputer[]> {
    return org ? super.getAll(ref => ref.where('bfit_org_ref.id', '==', org.id)) : of([]);
  }

  private buildQueryForOrg(org: BfitOrganization, queryFn?: QueryFn): QueryFn {
    return (ref: CollectionReference) => (queryFn ? queryFn(ref) : ref).where('bfit_org_ref.id', '==', org && org.id || null);
  }

  findAllWithUUID(uuid: string): Observable<BfitComputer[]> {
    return this.getAll(ref => ref.where('uuid', '==', uuid));
  }

  findAllOwnedByOrg(org: BfitOrganization): Observable<BfitComputer[]> {
    return org ? this.getAll(ref => ref.where('bfit_org_ref.id', '==', org.id)) : of([]);
  }

  findAllSharedWithUser(user: BfitUser): Observable<BfitComputer[]> {
    return user ? this.getAll(ref => ref.where('bfit_shared_with_ref.id', '==', user.id)) : of([]);
  }

  findAllUsedByUser(user: BfitUser): Observable<BfitComputer[]> {
    return user ? this.getAll(ref => ref.where('bfit_user_ref.id', '==', user.id)) : of([]);
  }

  findAllInCloud(cloud: BfitCloud): Observable<BfitComputer[]> {
    return cloud ? this.getAll(ref => ref.where('bfit_cloud_ref.id', '==', cloud.id)) : of([]);
  }

  findAllForTemplate(template: OVirtTemplate): Observable<BfitComputer[]> {
    return template ? this.getAll(ref => ref.where('template_id', '==', template.id)) : of([]);
  }

}

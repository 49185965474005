import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { CommonModule } from '@angular/common';
import { AppMenuState } from './store/app-menu.state';
import { NgxsModule } from '@ngxs/store';

import { SidemenuComponent } from './components/body/sidemenu';
import { SidemenuHeaderComponent } from './components/header/menu-header';

@NgModule({
    declarations: [
        SidemenuComponent,
        SidemenuHeaderComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,

        NgxsModule.forFeature ([ AppMenuState ]),
    ],
    exports: [

        SidemenuComponent,
    ],
})
export class MsiSideMenuModule { }

<ng-template #pleaseWait>
    <ion-card>
        <ion-card-content fxLayout="row" fxLayoutAlign="center center">
            <ion-item>
                <ion-label>Please wait</ion-label>
                <ion-spinner slot="start"></ion-spinner>
            </ion-item>
        </ion-card-content>
    </ion-card>
</ng-template>

<div class="auth_form_main_wrapper"  *ngIf="!(busy$ | async); else pleaseWait">
    <h1 class="mb0">Enter your 6-digit code</h1>
    <div fxLayout="column" fxLayoutAlign="center">
        <p class="secondary_text">Lets make sure it’s you. We’ve sent {{ useSMS ? 'a text' : 'an email' }} to:</p>
        <p class="verification_label text_center"> send to: {{ useSMS ? getMaskPhoneNo(user) : getMaskEmail(user) }} </p>
    </div>
    <form>
        <div class="verification_code_field mb30" fxLayoutAlign="center">
            <input #input1 name="pin1" type="text" [(ngModel)]="pin1" pattern="[0-9]" inputmode="numeric" maxlength="1" size="1" (keyup)="onKey($event, input2, input1)" [autofocus]="true"/>
            <input #input2 name="pin2" type="text" [(ngModel)]="pin2" pattern="[0-9]" inputmode="numeric" maxlength="1" size="1" (keyup)="onKey($event, input3, input1)" />
            <input #input3 name="pin3" type="text" [(ngModel)]="pin3" pattern="[0-9]" inputmode="numeric" maxlength="1" size="1" (keyup)="onKey($event, input4, input2)" />
            <input #input4 name="pin4" type="text" [(ngModel)]="pin4" pattern="[0-9]" inputmode="numeric" maxlength="1" size="1" (keyup)="onKey($event, input5, input3)" />
            <input #input5 name="pin5" type="text" [(ngModel)]="pin5" pattern="[0-9]" inputmode="numeric" maxlength="1" size="1" (keyup)="onKey($event, input6, input4)" />
            <input #input6 name="pin6" type="text" [(ngModel)]="pin6" pattern="[0-9]" inputmode="numeric" maxlength="1" size="1" (keyup)="onKey($event, input1, input5)" />
        </div>

        <div class="error_message" fxLayout="row" fxLayoutAlign="center">
            <p>{{ message }}</p>
        </div>

        <msi-text-toggle textOn="Send SMS" textOff="Send Email" [value]="useSMS" (toggle)="toggleSms($event)"></msi-text-toggle>

        <div class="login_link_wrapper mb30 mt30" fxLayout="column" fxLayoutAlign="center center">
            <!-- <ion-item *ngIf="!canPostpone" class="ion-no-padding custom_input" lines="none">
                <ion-label class="mfs_12">Remember this device</ion-label>
                <ion-checkbox class="mr10" slot="start" color="primary"></ion-checkbox>
            </ion-item> -->
            <ion-item *ngIf="canPostpone" class="ion-no-padding custom_input" lines="none">
                <ion-label class="mfs_12">
                    Don't ask me again for the next
                </ion-label>
                <ion-checkbox [checked]="dontAskMeAgain" (ionChange)="dontAskMeAgain = !dontAskMeAgain" class="mr10" slot="start" color="primary"></ion-checkbox>
                <ion-select *ngIf="dontAskMeAgain" name="pause" [value]="pause" [(ngModel)]="pause">
                    <ion-select-option [value]="5">5 minutes</ion-select-option>
                    <ion-select-option [value]="10">10 minutes</ion-select-option>
                    <ion-select-option [value]="15">15 minutes</ion-select-option>
                    <ion-select-option [value]="20">20 minutes</ion-select-option>
                    <ion-select-option [value]="25">25 minutes</ion-select-option>
                    <ion-select-option [value]="30">30 minutes</ion-select-option>
                </ion-select>
            </ion-item>
           
            <p class="m0">Didn’t receive your code ? <a class="bold" (click)="resendCode(user, useSMS)">Resend a code via {{ useSMS ? 'SMS' : 'Email' }}</a></p>
          
        </div>

       
        <div fxLayout="row" fxLayoutAlign="space-between">
            <ion-button (click)="checkPin(pin)" class="btn_primary w-100 btn_medium" [disabled]="pin.length !== 6">Continue</ion-button>
        </div>

        <!-- <ng-template #pleaseWait>
            <div class="w-100"  fxLayout="row" fxLayoutAlign="center center">
                <ion-item>
                  <ion-label>Please wait</ion-label>
                  <ion-spinner name="dots" slot="start"></ion-spinner>
                </ion-item>
            </div>
        </ng-template> -->
    </form>
</div>
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';


 

@Component({
  selector: 'formly-input-email',
  template: `
    <div>
      <input matInput type="email" [pattern]="emailRegex" [formControl]="formControl" [formlyAttributes]="field"/>
    </div>
  `,
  styles: [
      'input { border: none; width: 100%; }',

  ]
})
// tslint:disable-next-line: component-class-suffix
export class FormlyInputEmailTemplate extends FieldType {

    // tslint:disable-next-line: max-line-length
    emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); 

    constructor() {
      super();
    }
}


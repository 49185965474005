import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'msi-text-toggle',
    templateUrl: './text-toggle.component.html',
    styleUrls: [ './text-toggle.component.scss' ]
})
export class TextToggleComponent {

    @Input() value: boolean;
    @Input() textOn: string;
    @Input() textOff: string;

    @Output() toggle: EventEmitter<boolean> = new EventEmitter();

}

import { ObjectReference } from '@bfit/core/models/entities/ref';
import { OrgBasedObject } from '@msi/core/dao/services/org-based-db.service';
import { BfitOrganization } from './bfit-organization';

export enum BfitInvitationType {
    BfitOrganizationOnboarding = 'bfit-org-onboarding',
    BfitUserOnboarding = 'bfit-user-onboarding',
    BfitPccOnboarding = 'bfit-pcc-onboarding',
}
export interface BfitInvitationPoolRef {
    id: string;
    type: 'pool' | 'pcc';
    name: string;
    photoURL: string;
}

export interface BfitInvite extends OrgBasedObject {
    bfit_org_ref: ObjectReference<BfitOrganization>;
    name: string;
    description: string;
    org: BfitOrganization;
    
    availablePools: BfitInvitationPoolRef[];
    type: BfitInvitationType,
    
    emailSubject: string;
    emailTemplate: string;

    timeAllowance: string;
    expiration: Date;

    invitations: { id: string; name: string; email: string; mobile: string; code: string; url: string }[];
    promoCode: string;
    
    totalCost: number;
    totalPrice: number;
    acceptTerms: boolean;

    params: any;
    
}
import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { ShoppingProductCategory } from './entities/product-category';
import { DBService } from '@msi/core/dao/db';

export * from './entities/product-category';

@Injectable({ providedIn: 'root'})
export class ProductCategoryService extends DBService<ShoppingProductCategory> {

    constructor(db: FirestoreService) {
        super ('product_categories', db);
    }
}

import { MatTabsModule } from '@angular/material/tabs';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FaqArticleComponent } from './faq-article/faq-article.component';
import { FaqArticlesComponent } from './faq-articles/faq-articles.component';
import { FaqSelectSubSectionComponent } from './select-sub-section/select-sub-section.component';
import { FaqSearchBoxComponent } from './search-box/search-box.component';
import { IonicModule } from '@ionic/angular';
import { FaqTopicIconsComponent } from './faq-icons/faq-icons.component';
import { FaqSelectSectionComponent } from './select-section/select-section.component';
import { FormsModule } from '@angular/forms';
import { FaqSimilarQuestionsComponent } from './similar-questions/faq-similar-questions.component';
import { SafePipeModule } from 'safe-pipe';
import { FaqSectionTabsComponent } from './faq-section-tabs/faq-section-tabs.component';
import { FaqSummaryComponent } from './faq-summary/faq-summary.component';
import { MsiPipesModule } from '@msi/core/pipes/pipes.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FaqMiniSearchBoxComponent } from './mini-search-box/mini-search-box.component';


@NgModule({
    declarations: [
        FaqTopicIconsComponent,
        FaqSummaryComponent,
        FaqArticleComponent,
        FaqArticlesComponent,
        FaqSelectSubSectionComponent,
        FaqSelectSectionComponent,
        FaqSearchBoxComponent,
        FaqSimilarQuestionsComponent,
        FaqSectionTabsComponent,
        FaqMiniSearchBoxComponent,
    ],
    imports: [
        CommonModule,
        MsiPipesModule,
        IonicModule,
        FormsModule,
        SafePipeModule,
        MatTabsModule,

        FlexLayoutModule,
    ],
    exports: [
        FaqTopicIconsComponent,
        FaqSummaryComponent,
        FaqArticleComponent,
        FaqArticlesComponent,
        FaqSelectSubSectionComponent,
        FaqSelectSectionComponent,
        FaqSearchBoxComponent,
        FaqMiniSearchBoxComponent,
        FaqSimilarQuestionsComponent,
        FaqSectionTabsComponent,
    ]
})
export class MsiFaqComponentsModule { }

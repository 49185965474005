import { Component, Input, SimpleChange, OnChanges } from '@angular/core';
import { SidemenuOptions, AppMenuItem, AppSelectMenuItem, AppMenuGoBack } from '../../typedefs/sidemenu-options';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

@Component({
  selector: 'msi-sidemenu',
  templateUrl: './sidemenu.html',
  styleUrls: [ './sidemenu.scss' ]
})
export class SidemenuComponent implements OnChanges {

  @Input() public options: SidemenuOptions;

  constructor() { }

  ngOnChange(): void {

    if (this.options === undefined || this.options === null) {
      console.error('[Sidemenu] options are not defined.');
      return;
    }
  }

  ngOnChanges(_changes: { [propName: string]: SimpleChange }): void {


  }

  @Dispatch()
  onClick  = (menuItem: AppMenuItem) => new AppSelectMenuItem(menuItem)


  @Dispatch()
  goBack = () => new AppMenuGoBack()

}



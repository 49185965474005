import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { BfitDevice } from './entities/bfit-device';
import { BfitOrganization } from './bfit-organizations';
import { Observable, of } from 'rxjs';
import { DBService } from '@msi/core/dao/db';

export * from './entities/bfit-device';


@Injectable({ providedIn: 'root' })
export class BfitDevicesService extends DBService<BfitDevice> {

    constructor(db: FirestoreService) {
        super('bfit_devices', db);
    }

    findAllForOrganization(org: BfitOrganization): Observable<BfitDevice[]> {
        return org ? super.getAll(ref => ref.where('bfit_org_ref.id', '==', org.id)) : of([]);
    }

}

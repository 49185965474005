import { State, Selector, Action, StateContext, NgxsOnInit } from '@ngxs/store';

import { produce } from '@ngxs-labs/immer-adapter';

import * as lodash from 'lodash';

import { AppMenuSetBadge, AppMenuRegister, AppMenuChange } from './app-menu.actions';
import { AppMenuGoBack, AppSelectMenuItem, AppMenuItem, AppMenu, AppSelectPageMenu } from '../typedefs/sidemenu-options';
import { NavigateRoot, NavigateForward } from '@msi/core/ngxs/ionic-router/ionic-router.actions';
import { MenuController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export class AppMenuConfig {
    id: string;
    icon: string;
    label?: string;
    tooltip?: string;
    badge?: string;
    badgeStatus?: string;
    badgePosition?: string;
    disabled?: boolean;
    dispatch?: (menu: AppMenuConfig, event: MouseEvent) => any[]

}
interface StateModel {
    appMenus: AppMenuConfig[];
    currentMenu: AppMenu;
    currentMenuItem: AppMenuItem;
}

@State<StateModel>({
    name: 'msiThemeMenuState',
    defaults: {
        currentMenu: null,
        currentMenuItem: null,
        appMenus: [],
    }
})
@Injectable({ providedIn: 'root' })
export class AppMenuState implements NgxsOnInit {

    @Selector()
    static appMenus(state: StateModel): AppMenuConfig[] { return state.appMenus; }

    @Selector()
    static currentMenu(state: StateModel): AppMenu {
        return state.currentMenu;
    }

    @Selector()
    static currentMenuItem(state: StateModel): AppMenuItem {
        return state.currentMenuItem;
    }

    constructor(private menuCtrl: MenuController) { }

    ngxsOnInit(_ctx: StateContext<StateModel>): void {
        // console.log ('ngxsOnInit: ', 'AppMenuStateModel');
    }

    @Action(AppMenuSetBadge)
    setBadge(ctx: StateContext<StateModel>, action: AppMenuSetBadge): void {
        produce(ctx, (draft) => {
            const idx = lodash.findIndex(draft.appMenus, it => it.id === action.id);
            if (idx >= 0) {
                draft.appMenus[idx].badge = action.badge;
                draft.appMenus[idx].badgeStatus = action.badgeStatus;
            }
        });
    }

    @Action(AppMenuRegister)
    register(ctx: StateContext<StateModel>, action: AppMenuRegister): void {
        produce(ctx, (draft) => {
            if (action.config && draft.appMenus.findIndex(it => it.id === action.config.id) < 0) {
                draft.appMenus.push(action.config);
            }
        });
    }

    @Action(AppSelectMenuItem)
    selectMenuItem(ctx: StateContext<StateModel>, action: AppSelectMenuItem): void {

        this.menuCtrl.close();
        produce(ctx, draft => {
            draft.currentMenuItem = action.menuItem;
        });

        if (action.menuItem) {
            if (action.menuItem.page) {
                ctx.dispatch(new NavigateRoot(action.menuItem.page));
            } else if (action.menuItem.url) {
                Object.assign(document.createElement('a'), { target: '_blank', href: action.menuItem.url }).click();
                // window.location.href = action.menuItem.url;
            } else if (action.menuItem.dispatch) {
                ctx.dispatch(action.menuItem.dispatch);
            }
        }
    }

    @Action(AppSelectPageMenu)
    selectMenu(ctx: StateContext<StateModel>, action: AppSelectPageMenu): Observable<any> {
      if (action.nav && action.nav.url) {
        return ctx.dispatch(new NavigateForward(action.nav.url));
      } else if (action.nav && action.nav.dispatch) {
        return ctx.dispatch(action.nav.dispatch(action.nav));
      } else {
        return ctx.dispatch(new NavigateForward(`/${action.nav.id}`));
      }
    }
    @Action(AppMenuChange)
    changeMenu(ctx: StateContext<StateModel>, action: AppMenuChange): void {
        ctx.patchState({ currentMenu: action.menu, currentMenuItem: null });
    }

    @Action(AppMenuGoBack)
    goBack(ctx: StateContext<StateModel>): void {
        ctx.patchState({ currentMenu: null, currentMenuItem: null });
    }
}

import { Injectable } from '@angular/core';
import { ObjectReference } from '@bfit/core/models/entities/ref';
import { MsiAccount } from '@msi/account/core/models/accounts';

import { DBService } from '@msi/core/dao/db';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';


import { PostalAddress } from './address';

export interface Organization {
  id: string;
  name?: string;
  contactName?: string;
  taxId?: string;
  address?: string;
  addressDetail?: PostalAddress;
  photoURL?: string;
  phone: string;
  email: string;
  fax?: string;

  account_ref?: ObjectReference<MsiAccount>;
}

@Injectable({ providedIn: 'root' })
export class OrganizationsService extends DBService<Organization> {


  constructor(db: FirestoreService) {

    super('organizations', db);
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { ShoppingProductCategory } from './product-categories';

import { ShoppingCatalog } from './entities/catalog';
import { DBService } from '@msi/core/dao/db';
export * from './entities/catalog';


@Injectable({ providedIn: 'root'})
export class CatalogService extends DBService<ShoppingCatalog> {

    constructor(db: FirestoreService) {
        super ('catalogs', db);
    }

    findAllByCategory(category: string | ShoppingProductCategory): Observable<ShoppingCatalog[]> {
        const id = (typeof category === 'string') ? category : category.id;
        return this.getAll(ref => ref.where ('category_id', '==', id));
    }
}

import { ShoppingCatalog } from '../models/catalog';

export const TEST_CATALOGS: ShoppingCatalog[] = [
    {
        id: 'main',
        name: 'Main Catalog',

        photoURL: '/assets/images/backgrounds/membership-background.jpg',
        sections: [
            {
                row: 1,
                type: 'hero',
                title: 'Personal cloud computers',
                subtitle: 'Best value plans',
                description: 'Select from below our best value plans',
                photoURL: '/assets/images/backgrounds/membership-background.jpg',
                columns: [
                    {
                        col: 1,
                        type: 'plan',
                        title: 'Basic',
                        subtitle: 'Monthly subscription',
                        image: '',
                        ref_id: '3ayj0dECnrNmoAnxKen0',
                        other: {
                            price: 30,
                            features: [
                                { enabled: true, name: 'Access from any device'},
                                { enabled: true, name: 'Daily backups'},
                                { enabled: true, name: '2-Factor Authentication'},
                                { enabled: true, name: 'Malware protection'},
                                { enabled: true, name: 'Always On and Available'},
                                { enabled: true, name: 'Local Printers/Scanners'},
                                { enabled: true, name: 'LibreOffice'},
                                { enabled: true, name: 'Multiple Browsers'},
                                { enabled: true, name: 'Adobe Reader'},
                                { enabled: true, name: '1 CPU - dedicated'},
                                { enabled: true, name: '2 GB RAM - dedicated'},
                                { enabled: true, name: '20 GB User Disk space'},
                            ]
                        }
                    },
                    {
                        col: 2,
                        type: 'plan',
                        title: 'Standard',
                        subtitle: 'Monthly subscription',
                        ref_id: 'wIBzZCmIurXmL2TUpUkw',
                        other: {
                            price: 35,
                            features: [
                                { enabled: true, name: 'Access from any device'},
                                { enabled: true, name: 'Daily backups'},
                                { enabled: true, name: '2-Factor Authentication'},
                                { enabled: true, name: 'Malware protection'},
                                { enabled: true, name: 'Always On and Available'},
                                { enabled: true, name: 'Local Printers/Scanners'},
                                { enabled: true, name: 'LibreOffice'},
                                { enabled: true, name: 'Multiple Browsers'},
                                { enabled: true, name: 'Adobe Reader'},
                                { enabled: true, name: '1 CPU - dedicated'},
                                { enabled: true, name: '2 GB RAM - dedicated'},
                                { enabled: true, name: '20 GB User Disk space'},
                            ]
                        }
                    },
                    {
                        col: 3,
                        type: 'plan',
                        title: 'Premium',
                        subtitle: 'Monthly subscription',
                        ref_id: 'mPxMNEGKvodH0L0mauWG',
                        other: {
                            price: 40,
                            features: [
                                { enabled: true, name: 'Access from any device'},
                                { enabled: true, name: 'Daily backups'},
                                { enabled: true, name: '2-Factor Authentication'},
                                { enabled: true, name: 'Malware protection'},
                                { enabled: true, name: 'Always On and Available'},
                                { enabled: true, name: 'Local Printers/Scanners'},
                                { enabled: true, name: 'LibreOffice'},
                                { enabled: true, name: 'Multiple Browsers'},
                                { enabled: true, name: 'Adobe Reader'},
                                { enabled: true, name: '1 CPU - dedicated'},
                                { enabled: true, name: '2 GB RAM - dedicated'},
                                { enabled: true, name: '20 GB User Disk space'},
                            ]
                        }
                    }
                ]
            },
            {
                row: 2,
                type: 'row',
                columns: [
                    {
                        col: 1,
                        type: 'category',
                        title: 'Monitors',
                        image: 'https://firebasestorage.googleapis.com/v0/b/butterfly-it-7f77b.appspot.com/o/product_categories%2Fmonitors.jpg?alt=media&token=05b9cc41-5d54-4ca4-b601-c44af77ce30e',
                        ref_id: 'monitors',
                    },
                    {
                        col: 2,
                        type: 'category',
                        title: 'Printers',
                        image: 'https://firebasestorage.googleapis.com/v0/b/butterfly-it-7f77b.appspot.com/o/product_categories%2Fprinters.jpg?alt=media&token=9ac95eb6-47e3-4912-affd-a0df2ad6f27e',
                        ref_id: 'printers',
                    },
                    {
                        col: 3,
                        type: 'category',
                        title: 'Scanner',
                        image: 'https://firebasestorage.googleapis.com/v0/b/butterfly-it-7f77b.appspot.com/o/product_categories%2Fscanners.jpg?alt=media&token=d26747a2-2723-4cc7-b795-e6f0e256e6f3',
                        ref_id: 'scanners'
                    }
                ]
            },
            {
                row: 3,
                type: 'row',
                columns: [
                    {
                        col: 1,
                        type: 'category',
                        title: 'Accessories',
                        image: 'https://firebasestorage.googleapis.com/v0/b/butterfly-it-7f77b.appspot.com/o/product_categories%2Faccessories.jpg?alt=media&token=0e06e00f-d331-4ee8-8adf-a5693f56ea08',
                        ref_id: 'accessories',
                    },
                    {
                        col: 2,
                        type: 'category',
                        title: 'Phones',
                        image: 'https://firebasestorage.googleapis.com/v0/b/butterfly-it-7f77b.appspot.com/o/product_categories%2Fphones.png?alt=media&token=afb5af3f-f13d-4c3e-8805-e441a3547a87',
                        ref_id: 'phones',
                    },
                    {
                        col: 3,
                        type: 'category',
                        title: 'PCC',
                        subtitle: 'Memberships',
                        image: 'https://firebasestorage.googleapis.com/v0/b/butterfly-it-7f77b.appspot.com/o/product_categories%2Fwindows-linux.png?alt=media&token=e3f823f1-ff0c-40de-8c8a-f2c86b2f9bdd',
                        ref_id: 'desktops'
                    }
                ]
            }

        ]
    }
];

import { BfitUser } from '@bfit/core/models/bfit/entities/bfit-user';
import { OrgBasedDbService } from '@msi/core/dao/services/org-based-db.service';


import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';
import { BfitBackup } from './entities/bfit-backup';
import { Observable, of } from 'rxjs';

export * from './entities/bfit-backup';
export * from './entities/bfit-backup-file';

@Injectable({ providedIn: 'root' })
export class BfitBackupsService extends OrgBasedDbService<BfitBackup> {
   
    constructor(db: FirestoreService) {
        super('bfit_backups', db);
    }

    findAllForUser(user: BfitUser): Observable<BfitBackup[]> {
        return user ? this.getAll(ref => ref.where('user_id', '==', user.id)) : of([]);
    }

}

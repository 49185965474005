import { NgModule } from '@angular/core';

import { MsiAuthService } from './services/auth.service';
import { MsiDeviceContextState } from './states/device.state';
import { NgxsModule } from '@ngxs/store';
import { MsiAuthState } from './states/auth.state';
import { AngularFireAuthModule, AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { MsiAuthViewState } from './states/auth-view.state';
import { MsiAuthLoginService } from './services/login.service';
import { NotifyService } from './services/notify.service';
import { PresenceService } from './services/presence.service';
import { MsiAuthUserManagementAsyncQueue } from './services/new-user.service';
import { AuthGuard } from './services/auth.guard';
import { MsiAuthAdminState } from './states/auth-admin.state';
import { AngularFireDatabaseModule } from '@angular/fire/database';

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
    if (parentModule) {
        throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
    }
}

@NgModule({

    imports: [
        NgxsModule.forFeature([
            MsiAuthState,
            MsiAuthAdminState,
            MsiAuthViewState,
            MsiDeviceContextState
        ]),
        AngularFireDatabaseModule,
        AngularFireStorageModule,
        AngularFireAuthModule,

    ],
    exports: [
        AngularFireStorageModule,
        AngularFireAuthModule,
    ],
    providers: [
        AngularFireAuth,
        MsiAuthService,
        MsiAuthLoginService,
        NotifyService,
        PresenceService,
        MsiAuthUserManagementAsyncQueue,
        AuthGuard,
    ]
})
export class MsiAuthIonicModule { }

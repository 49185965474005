import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';


@Component({
  selector: 'formly-input-ipaddr',
  template: `
    <input matInput type="text" [pattern]="regex" [formControl]="formControl" [formlyAttributes]="field"/>
  `,
  styles: [
      'input { border: none; width: 100%; }',

  ]
})
// tslint:disable-next-line: component-class-suffix
export class FormlyInputIpAddressTemplate extends FieldType {

    // tslint:disable-next-line: max-line-length
    regex = new RegExp(/^(25[0-5]|2[0-4][0-9]|[01]?[1-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-4]|2[0-4][0-9]|[01]?[1-9][0-9]?)\/([1-9]|1[0-9]|2[0-9]|3[0-2]|(((128|192|224|240|248|252|254)\.0\.0\.0)|(255\.(0|128|192|224|240|248|252|254)\.0\.0)|(255\.255\.(0|128|192|224|240|248|252|254)\.0)|(255\.255\.255\.(0|128|192|224|240|248|252|254))))$/); 

}


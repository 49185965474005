<ion-app>

    <ion-split-pane contentId="main">

        <ng-container *ngIf="authenticated$ | async as authenticated">
            <ion-menu side="start" contentId="main" *ngIf="menu$ | async as menu">
                <msi-sidemenu [options]="menu"></msi-sidemenu>
            </ion-menu>

            <ion-menu side="end" menuId="context-help" type="overlay" contentId="main" *ngIf="(contextHelpOpen$ | async)" >
                <app-context-help menuId="context-help"></app-context-help>
            </ion-menu>
            
        </ng-container>

        <ion-router-outlet id="main"></ion-router-outlet>

    </ion-split-pane>

</ion-app>



import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-button-toggle',
    template: `
        <ion-item nolines>
            <ion-label>{{ to.label }}</ion-label>
            <ion-toggle  [formControl]="formControl" [formlyAttributes]="field" [color]="to.color">
            </ion-toggle>
        </ion-item>
  `,
})
export class FormlyButtonToggleTemplate extends FieldType {



}
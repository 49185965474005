import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot} from '@angular/router';
import { Store } from '@ngxs/store';
import { MsiAuthState } from '../states/auth.state';

import { MsiAuthRequestLogin } from '../actions/nav.actions';
import { MsiAuthChangeFirstPage } from '../actions/auth.actions';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private store: Store) { }

    canActivate(next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> | boolean {

        // console.log("[MsiAuthGuard] checking authentication: ", next.toString());

        const ready = this.store.selectSnapshot(MsiAuthState.ready);
        const loggedIn = this.store.selectSnapshot(MsiAuthState.authenticated);

        if (!ready) {
            this.store.dispatch(new MsiAuthChangeFirstPage(next.url.toString()));
        } else {

            if (loggedIn) {
                return true;
            } else {
                this.store.dispatch(new MsiAuthRequestLogin());
            }
        }

        return false;
    }
}

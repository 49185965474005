

import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { BfitPrinter } from './entities/bfit-printer';
import { BfitContextService } from './bfit-context.service';

export * from './entities/bfit-printer';

@Injectable({ providedIn: 'root' })
export class BfitPrintersService extends BfitContextService<BfitPrinter> {

    constructor(db: FirestoreService) {
        super('bfit_printers', db);
    }

}

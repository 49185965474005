import { DBService } from '@msi/core/dao/db';
import { mergeMap, tap } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';


import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { BfitPrintJob } from './entities/bfit-print-job';
import { BfitComputer } from './bfit-computers';
import { chain, isEmpty } from 'lodash';
import { BfitUser } from './bfit-users';
import { BfitOrganization } from './entities/bfit-organization';

export * from './entities/bfit-print-job';

@Injectable({ providedIn: 'root' })
export class BfitPrintJobsService extends DBService<BfitPrintJob> {

    constructor(db: FirestoreService) {
        super('bfit-printjobs', db);
    }

    findAllForComputers(computers: BfitComputer[]): Observable<BfitPrintJob[]> {

        // FIXME:Not workiing

        const groups = chain(computers)
            .groupBy(pcc => pcc.bfit_org_ref && pcc.bfit_org_ref.id || '')
            .filter((_pccs, orgId) => !isEmpty(orgId))
            .value();

        const result =  from(groups).pipe(
            mergeMap(pccs => this.getAll(ref => ref.where('bfit_computer_ref.id', 'in', pccs.map(pcc => pcc.id)))),
            tap(list => console.table(list)),
        );    
    
        return result;
       
    }

    findAllForUser(user: BfitUser): Observable<BfitPrintJob[]> {

        return user ? this.getAll(ref => ref.where('bfit_user_ref.id', '==', user.id)) : of([]);
    }

    findAllForOrganization(org: BfitOrganization): Observable<BfitPrintJob[]> {
        return org ? super.getAll(ref => ref.where('bfit_org_ref.id', '==', org.id)) : of([]);
    }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { HotTableComponent } from 'ng2-handsontable';

const template = `
    <div [ngStyle]="style">
        <hot-table [data]="model" 
            class="hot h-100 w-100"
            [columns]="to.columns"
            [colHeaders]="to.colHeaders" 
            [options]="to.gridOptions" 
            (afterInit)="onAfterInit()"
            (afterChange)="onAfterChange($event)">
        </hot-table>
    </div>`;

@Component({
  selector: 'formly-sheet',
  template,
})
export class FormlySheetTemplate  extends FieldArrayType implements OnInit {

    @ViewChild(HotTableComponent, { static: true }) hotTableComponent: HotTableComponent;

    // options: any = {
    //     // height: 396,
    //     rowHeaders: true,
    //     stretchH: 'all',
    //     columnSorting: true,
    //     contextMenu: true,
    //     className: 'htCenter htMiddle',
    //     readOnly: false,
    //     minRows: 30,
    //     minSpareRows: 1,
    //     manualColumnResize: true,
    //     manualRowResize: true,
    //     autoColumnSize: true,
    //     autoRowSize: true,
    //     colWidths: 200,
    // };

    style: any = {};

    ngOnInit(): void {
        this.style = {
            width: this.to.width,
            height: this.to.height,
            overflow: 'auto',
            // border: '1px solid red',
            color: 'rgba(0, 0, 0, 0.87)',
            'font-size': '14px'
          };
    }

    onAfterInit(): void {

        const hot = this.hotTableComponent.getHandsontableInstance();
        hot.render();
    }

    onAfterChange($event: any): void {

        const op = $event[1];
        const changes = $event[0];

        switch (op) {
            case 'loadData':
                // console.log ('loadData');

                // console.log ('load Data: ', this);
                break;
            case 'edit':

                break;
            default:
                console.log('on after change:', changes, op);
        }
    }
}

import { NgModule } from '@angular/core';
import { SocialShareComponent } from './social-share.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgxSocialShareModule } from 'ngx-social-share';

@NgModule({
    declarations: [
        SocialShareComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        NgxSocialShareModule
    ],
    exports: [
        SocialShareComponent
    ],
})
export class AppSocialShareModule { }

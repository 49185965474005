<ion-header>
    <ion-toolbar>

        <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
        </ion-buttons>

        <ion-title *ngIf="user">Edit {{ user.displayName }} Settings</ion-title>

    </ion-toolbar>
</ion-header>


<ion-content fullscreen class="ion-padding">

    <div class="page_container" fxLayout="row" fxLayoutAlign="center">

        <div class="msi-card edit-settings-card">
            <div class="msi-card-header">
                <h1>Edit Settings</h1>
                <div class="msi-card-close-icon" (click)="cancel()">
                    <ion-icon name="close-outline"></ion-icon>
                </div>
            </div>

            <div class="msi-card-body" *ngIf="editForm">
                <form [formGroup]="editForm" autocomplete="disabled" novalidate>
                    <formly-form [model]="changes" [fields]="formConfig" [form]="editForm" [options]="formOptions">

                    </formly-form>
                </form>
            </div>

            <div class="msi-card-footer" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px">
                <ion-button color="light" (click)="cancel()">Cancel</ion-button>
                <ion-button class="ml10" color="primary" [disabled]="!editForm || editForm?.invalid"
                    (click)="save(user, changes)">
                    Save changes
                </ion-button>
            </div>


        </div>
    </div>
</ion-content>
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'msi-formly-debug',
  templateUrl: './formly-debug.template.html',
  styleUrls: [ './formly-debug.template.scss']
})
export class FormlyDebugTemplate extends FieldType {

}

import { BfitComputer } from '@bfit/core/models/bfit/bfit-computers';
import { BfitDisk } from '@bfit/core/models/bfit/bfit-disks';
import { BfitGoldImage } from '@bfit/core/models/bfit/bfit-gold-images';
import { ObjectReference } from '@bfit/core/models/entities/ref';
import { IdentityObject } from '@msi/core/dao/db';

export enum OVirtJobRequestType {

    // Disk
    Disk_Create = 'disk.create',
    Disk_AttachToVm = 'disk.attach-disk',
    Disk_DetachfromVm = 'disk.detach-disk',
    Disk_WaitToBeMountable = 'disk.wait-for-drive-to-be-mountable',
    Disk_Format = 'disk.format',
    Disk_MountInVm = 'disk.mount-in-vm',

    // VM
    VMS_CreateDataServer = 'vms.create-data-server',
    VMS_WaitRunning = 'vms.wait-running',
    VMS_CreatePCC = 'vms.create-stateless',


    // Organizations
    Org_CreateInCloud = 'org.create-in-cloud',
    Org_FindAvailableCloud = 'org.find-available-cloud',
    Org_FindAvailableNetwork = 'org.find-available-network',
    Org_FindAvailableCluster = 'org.find-available-cluster',
    Org_CreateAffinityGroup = 'org.org-affinity-group',
    Org_CreateNetwork = 'org.create-network',
    Org_CreateDataServer = 'org.create-data-server',
    Org_CreateMasterImages = 'org.create-master-images',

    // Clusters
    Clusters_SyncWithOrganizations = 'cluster.sync-organizations',

    // Cloud
    Cloud_CreateOrg = 'cloud.create-organization',
}

export enum OVirtJobRequestStatus {
    Created = 'created',
    Started = 'started',
    Success = 'completed',
    Fail = 'failed',
    InProgress = 'in-progress',
    Waiting = 'waiting',
    Canceled = 'Canceled',
}

export interface JobRequest extends IdentityObject {
    name: string;
    queue: 'ovirt' | 'bfit' | 'stripe';
}

export interface OVirtJobRequest extends JobRequest {
    queue: 'ovirt';
    cloud_id: string;
    type: OVirtJobRequestType;
    args: { [key: string]: BfitComputer | BfitDisk | BfitGoldImage };
    status: OVirtJobRequestStatus;
    dependsOn: ObjectReference<OVirtJobRequest>[];

    start_time: number;
    end_time: number;
}

export interface StripeJobRequest extends JobRequest {
    queue: 'stripe';
    stripe_id: string;
}

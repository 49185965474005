
import { Organization } from '@msi/core/dao/models/organization';
import { BfitContextBasedObject } from '../bfit-context.service';
import { ObjectReference } from '../../entities/ref';
import { BfitCloudRef } from './bfit-cloud';
import { BfitAccount } from './bfit-account';


export function getOrgRef(org: BfitOrganization): ObjectReference<BfitOrganization> {
    return org ? { id: org.id, name: org.name, photoURL: org.photoURL } : null;
}

export interface OrganizationPermission {
    selected: boolean;
    module_id: string;
    name: string;
}

export enum BfitOrganizationType {
    SysAdmin = 'SysAdmin',
    Master = 'master',
    Partner = 'partner',
    Consumer = 'consumer',
    SingleUser = 'single-user,'
}

// tslint:disable-next-line: no-empty-interface
export interface BfitOrganization extends Organization, BfitContextBasedObject {
    comment?: string;

    // cloud_id?: string;
    // network_id?: string;
    // cluster_id?: string;

    datacenter: {
        id: string;
        region: string;
    };

    network: {
        id: string;
        name: string;
        cidr: string;
    };


    code: string;
    uuid: string;
    cidr: string;
    vlan: number;
    defaultPassword: string;

    type: BfitOrganizationType;

    self_managed: boolean;

    bfit_provider_ref: ObjectReference<BfitOrganization> | null;
    bfit_cloud_ref: BfitCloudRef;
    bfit_account_ref: ObjectReference<BfitAccount> | null;

}

import { NgModule, Injectable } from '@angular/core';
import { RouterModule, Routes, PreloadingStrategy, Route } from '@angular/router';
import { InvitationGuard } from '@bfit/guards/invitation-valid.guard';
import { environment } from '@env';
import { AuthGuard } from '@msi/ionic/core/auth/services/auth.guard';
import { Observable, of } from 'rxjs';


const routes: Routes = [

    // {
    //     path: '',
    //     redirectTo: 'home',
    //     pathMatch: 'full'
    // },

    /*** Application pages ***********************************************************************/

    {
        path: 'home',
        pathMatch: 'prefix',
        canActivate: [ AuthGuard ],
        loadChildren: () => import('@bfit/ionic/home/home-page.module')
            .then(m => m.HomePageModule)
    },

    {
        path: 'shared',
        pathMatch: 'prefix',
        loadChildren: () => import('@bfit/ionic/home/pages/_shared/shared-pages.module')
            .then(m => m.SharedPageModule)
    },

    {
        path: 'account',
        pathMatch: 'prefix',
        canActivate: [ AuthGuard ],
        loadChildren: () => import('@bfit/ionic/account/account.routes')
            .then(m => m.BfitAccountRoutesModule)
    },

    {
        path: 'my-account',
        pathMatch: 'prefix',
        canActivate: [ AuthGuard ],
        loadChildren: () => import('@bfit/ionic/account/my-account.routes')
            .then(m => m.BfitMyAccountRoutesModule)
    },


    /**** Admin pages ****************************************************************************/

    {
        path: 'bfit-cloud',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () => import('@ovirt/ionic/cloud-admin/bfit-cloud.module')
            .then(m => m.BfitCloudAdminModule)
    },

    {
        path: 'bfit-org-admin',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () => import('@bfit/ionic/admin/submodules/organizations-admin/bfit-org-admin.module')
            .then(m => m.OrganizationsAdminModule)
    },

    {
        path: 'current-org-admin',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () => import('@bfit/ionic/admin/submodules/current-org-admin/current-org-admin.routes')
            .then(m => m.CurrentOrgAdminRouteModule)
    },

    {
        path: 'my-org-admin',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () => import('@bfit/ionic/admin/submodules/my-org-admin/my-org-admin.routes')
            .then(m => m.MyOrganizationAdminModule)
    },

    /*** Authentication pages ********************************************************************/

    {
        path: 'auth',
        pathMatch: 'prefix',
        loadChildren: () => import('@bfit/ionic/auth/bfit-auth.module')
            .then(m => m.BfitAuthModule)
    },

    /**** E-commerce ******************************************************************************/

    {
        path: 'store-admin',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () => import('@bfit/ionic/admin/pages/e-commerce/e-commerce-admin.module')
            .then(m => m.StoreAdminPagesModule),
        data: {
            breadcrumb: 'Store Admin'
        }
    },

    {
        path: 'special-services',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () => import('@bfit/ionic/special-services/special-services.module')
            .then(m => m.BfitSpecialServicesModule),
        data: {
            breadcrumb: 'Store Admin'
        }
    },


    {
        path: 'service-admin',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () => import('@bfit/ionic/services-admin/service.module')
            .then(m => m.ServicesAdminModule),
        data: {
            breadcrumb: 'Store Admin'
        }
    },

    {
        path: 'accounts',
        pathMatch: 'prefix',
        loadChildren: () => import('@bfit/ionic/admin/pages/accounts/accounts.module')
            .then(m => m.BfitAccountAdmingModule),
        data: {
            breadcrumb: 'Accounts',
            preload: false,
        }
    },

    {
        path: 'partners',
        pathMatch: 'prefix',
        loadChildren: () => import('@bfit/ionic/partners/partners.module')
            .then(m => m.PartneringModule),
        data: {
            breadcrumb: 'Partners',
            preload: false,
        }
    },

    {
        path: 'shopping',
        pathMatch: 'prefix',
        loadChildren: () => import('@bfit/ionic/shopping/shopping.routes')
            .then(m => m.BfitShoppingRoutesModule),
        data: {
            breadcrumb: 'Shopping',
            preload: true,
        }
    },

    { 
        path: 'my-pcc',
        pathMatch: 'prefix',
        loadChildren: () => import('@bfit/ionic/my-pccs/my-pccs.module')
            .then(m => m.MyPccsModule)
    },


    { 
        path: 'my-backups',
        pathMatch: 'prefix',
        loadChildren: () => import('@bfit/ionic/my-backups/my-backups.module')
            .then(m => m.MyBackupsModule)
    },

    /*** Embedded pages *********************************************************************/

    {
        path: 'accept-referral/:referralCode',
        pathMatch: 'full',
        loadChildren: () => import('@bfit/ionic/info/pages/accept-referral/accept-referral.module')
            .then(m => m.BfitAcceptReferralModule)
    },

    {
        path: 'invite/:code',
        canActivate: [ InvitationGuard ],
        pathMatch: 'prefix',
        loadChildren: () => import('@bfit/ionic/invitation/invitation.module')
            .then (m => m.BfitInvitationModule)
    },

    {
        path: 'onboarding',
        pathMatch: 'prefix',
        loadChildren: () => import('@bfit/ionic/onboarding/onboarding.module')
            .then (m => m.OnboardingModule)
    },

    /*** Info pages *************************************************************************/

    {
        path: 'faq',
        pathMatch: 'prefix',
        loadChildren: () => import('@bfit/ionic/faq/faq.routes')
            .then(m => m.BfitFaqModule)
    },

    {
        path: 'news-admin',
        pathMatch: 'prefix',
        loadChildren: () => import('@bfit/ionic/news/news.routes')
            .then(m => m.BfitNewsModule)
    },

    {
        path: 'help-desk',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () => import('@bfit/ionic/help-desk/help-desk.module')
            .then(m => m.BfitHelpDeskModule)
    },

    {
        path: 'bfit-job-requests',
        loadChildren: () => import('@bfit/ionic/admin/submodules/current-org-admin/pages/job-requests/job-request-admin.module')
            .then(m => m.JobRequestAdminPageModule)
    },

    {
        path: 'bfit-external-requests',
        loadChildren: () => import('@bfit/ionic/admin/submodules/current-org-admin/pages/external-requests/external-request-admin.module')
            .then(m => m.ExternalRequestAdminPageModule)
    },
    
    {
        path: 'intro',
        redirectTo: '/info/intro'
    },
    {
        path: 'about-us',
        redirectTo: '/info/about-us'
    },
    {
        path: 'contact-us',
        redirectTo: '/info/contact-us',
    },
    {
        path: 'privacy',
        redirectTo: '/info/privacy'
    },
    {
        path: 'terms',
        redirectTo: '/info/terms'
    },

    {
        path: 'error-page',
        loadChildren: () => import('@bfit/ionic/info/pages/error-page/error-page.module')
            .then(m => m.ErrorPageModule)
    },

    {
        path: 'info',
        loadChildren: () => import('@bfit/ionic/info/info-pages.module')
            .then (m => m.InfoPagesModule)
    },

    /**** Admin pages ************************************************************************/

    {
        path: 'faq-admin',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () => import('@bfit/ionic/admin/submodules/faq-admin/faq-admin.routes')
            .then(m => m.BfitFaqAdminModule)
    },
    
    /*** Standard pages **********************************************************************/

    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/home'
    },

    {
        path: '**',
        redirectTo: '/error-page'
    }
];


// https://blog.cloudboost.io/angular-faster-performance-and-better-user-experience-with-lazy-loading-a4f323b2cf4a

@Injectable({ providedIn: 'root' })
export class CustomPreloadingStrategy implements PreloadingStrategy {
  preloadedModules: string[] = [];

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data.preload) {
      this.preloadedModules.push(route.path);
      return load();
    } else {
      return of(null);
    }
  }
}

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: CustomPreloadingStrategy, // PreloadAllModules,
            useHash: true,
            enableTracing: !environment.production
        }),
    ],
    exports: [
        RouterModule
    ],
    providers: [
        CustomPreloadingStrategy,
        InvitationGuard,
    ]
})
export class AppRoutingModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatTabsModule } from '@angular/material/tabs';

import { MsiBlogArticlePage } from './blog-article.page';
import { MsiFaqComponentsModule } from '@msi/faq/components/faq-components.module';




@NgModule({
    declarations: [
        MsiBlogArticlePage
    ],
    imports: [
        CommonModule,
        IonicModule,
        MatTabsModule,
        MsiFaqComponentsModule,
    ],
    exports: [
        MsiBlogArticlePage,
        MsiFaqComponentsModule,
    ],
})
export class MsiBlogArticlePageModule { }

import { MsiNotifyAlert } from './../../../@msi/ionic/ui/notify/store/notify.actions';
import { Injectable } from "@angular/core";
import { AngularFireFunctions } from "@angular/fire/functions";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { InvitationSetCurrent } from '@bfit/ionic/invitation/store/invitation.actions';

@Injectable()
export class InvitationGuard implements CanActivate {
    
    
      constructor(
        private couldFunctions: AngularFireFunctions,
        private store: Store) {
      }
    
      canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
        // if (this.store.selectSnapshot(MsiAccountState.ready)) {
        //   const menu = this.store.selectSnapshot(BfitAccountState.getAccountMenu);
        //   const account = this.store.selectSnapshot(MsiAccountState.currentAccount);
    
        //   this.store.dispatch(new AppMenuChange (menu));
    
        //   if (account) {
        //       return true;
        //   }
    
        //   // navigate to create account page
        //   this._router.navigateByUrl(`/account/create-account`);
        //   // you can save redirect url so after authing we can move them back to the page they requested
        //   return false;
        // } else {
        //   return false;
        // }

        const code = route.paramMap.get('code');
        return this.couldFunctions.httpsCallable('inviteFn-check')(code).pipe(
          catchError(error => {
            this.store.dispatch(new MsiNotifyAlert('invite', error));
            return of(false);
          }),
          switchMap (_invitation => {
            this.store.dispatch(new InvitationSetCurrent(_invitation))
            return of(true);

          })
        );

      }
}
import { NgModule } from '@angular/core';

import { ProfileShowMoreMenu } from './profile-show-more';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';


@NgModule({
  declarations: [
    ProfileShowMoreMenu,
    ],
  imports: [

      CommonModule,
      IonicModule,
  ],
  exports: [
    ProfileShowMoreMenu,
  ]
})
export class ProfileShowMoreMenuModule {}

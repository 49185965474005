import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { SafePipeModule } from 'safe-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { MsiCardGridComponent } from './card-grid.component';
import { MsiCardComponentModule } from './card/card.module';

export interface CardInfo {
    id: string;
    name: string;
    displayName?: string;
    comment?: string;
    address: string;
    phone: string;
    email: string;
    photoURL: string;
}

@NgModule({
    declarations: [
        MsiCardGridComponent,

    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        NgPipesModule,
        FlexLayoutModule,
        SafePipeModule,
        MsiCardComponentModule,
    ],
    exports: [
        MsiCardGridComponent
    ],
    providers: [],
})
export class MsiCardGridModule { }


import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-input-password',
    template: `
         
        <ion-item>
            <ion-label position="stacked">{{ to.label }}</ion-label>
            <ion-input [type]="showPassword ? 'text' : 'password'"  [formControl]="formControl" [formlyAttributes]="field"
                [placeholder]="to.placeholder"></ion-input>
            <ion-icon class="suffix-icon" slot="end" [name]="showPassword ? 'eye-off' : 'eye'"
                (click)="showPassword = !showPassword"></ion-icon>
            <ion-note *ngIf="to.description">{{ to.description }}</ion-note>
        </ion-item>
                
  `,
})
export class FormlyPasswordTemplate extends FieldType {

    showPassword = false;

}
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'formly-input-avatar',
  template: `
     <msi-input-avatar  class="w-100" 
        [photoURL]="formControl.value"
          [path]="to.folder || 'photos'" 
          [placeholder]="to.placeholder || '/assets/images/placeholder-image/person-placeholder.jpg'"
          (urlChange)="to.addPhoto($event)">
      </msi-input-avatar>
  `,
})
export class FormlyAvatarTemplate extends FieldType {

}


import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';
import { BfitSharedFolder } from './entities/bfit-shared-folder';
import { BfitContextService } from './bfit-context.service';

export * from './entities/bfit-shared-folder';

@Injectable({ providedIn: 'root' })
export class BfitSharedFoldersService extends BfitContextService<BfitSharedFolder> {

    constructor(db: FirestoreService) {
        super('bfit_shares', db);
        this.patchDefaults({
            readonly: false,
            readOnlyRole_ids: [],
            readWriteRole_ids: [],
        });
    }

}



import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { BfitCloud } from './entities/bfit-cloud';
import { DBService } from '@msi/core/dao/db';
import { BfitOrganization } from './entities/bfit-organization';
import { Observable } from 'rxjs';

export * from './entities/bfit-cloud';

@Injectable({ providedIn: 'root' })
export class BfitCloudsService extends DBService<BfitCloud> {
 
    constructor(db: FirestoreService) {
        super('bfit_clouds', db);
    }

    findAllAvailableToOrganization(_currentOrg: BfitOrganization): Observable<BfitCloud[]> {
        // FIXME: Implement some filtering
        return this.findAll();
    }

}

import { BfitOrganizationsService } from '@bfit/core/models/bfit/bfit-organizations';
import { BfitUsersService } from '@bfit/core/models/bfit/bfit-users';

import { Injectable } from '@angular/core';

import { BfitComputer, BfitComputerOptions } from '@bfit/core/models/bfit/bfit-computers';
import { MsiAuthProtectedActionModal } from '@bfit/ionic/auth/modals/protected-action/protected-action.modal';
import { ModalController } from '@ionic/angular';
import { MsiPatchedAngularFireFunctions } from '@msi/core/dao/services/patched-cloud-function';

import { defaults, sampleSize } from 'lodash';
import { take } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class BfitComputerManagementService {

    readonly backend = 'bfit2Fn-desktops';

    constructor(
        private cloudService: MsiPatchedAngularFireFunctions,
        private modalCtrl: ModalController,

        private userService: BfitUsersService,
        private orgService: BfitOrganizationsService,
    ) {
    }

    async open(computer: BfitComputer, options: BfitComputerOptions = {}): Promise<any> {

        try {

            const url = await this.desktop(computer, 'open', options);

            if (url) {
                console.log('opening desktop: ', url);
                if (computer.viewer === 'web') {
                    // window.open(url, '_blank').focus();
                    Object.assign(document.createElement('a'), { target: '_blank', href: url }).click();
                } else {
                    window.location.href = url;
                }

                return url;
            } else {
                throw new Error(`url is empty`);
            }
        } catch (err) {

            console.error(`error opening desktop: ${err}`);
            throw err;
        }
    }

    pause(computer: BfitComputer, options: BfitComputerOptions): Promise<any> {
        return this.desktop(computer, 'pause', options);
    }

    start(computer: BfitComputer, options: BfitComputerOptions): Promise<any> {
        return this.desktop(computer, 'start', options);
    }

    reboot(computer: BfitComputer, options: BfitComputerOptions): Promise<any> {
        return this.desktop(computer, 'reboot', options);
    }

    restore(computer: BfitComputer, options: BfitComputerOptions): Promise<any> {
        return this.desktop(computer, 'restore', options);
    }

    shutdown(computer: BfitComputer, options: BfitComputerOptions): Promise<any> {
        return this.desktop(computer, 'shutdown', options);
    }

    poweroff(computer: BfitComputer, options: BfitComputerOptions): Promise<any> {
        return this.desktop(computer, 'stop', options);
    }

    upgrade(computer: BfitComputer, options: BfitComputerOptions): Promise<any> {
        return this.desktop(computer, 'upgrade', options);
    }

    async desktop(computer: BfitComputer, action: string, options: BfitComputerOptions): Promise<any> {

        if (computer.bfit_org_ref && computer.bfit_org_ref.id && computer.bfit_user_ref && computer.bfit_user_ref.id) {

           
            const user = await this.userService.get(computer.bfit_user_ref.id).pipe(take(1)).toPromise();
            const org = await this.orgService.get(computer.bfit_org_ref.id).pipe(take(1)).toPromise();

            if (user.settings && user.settings.protectPccs) {

                const token = { value: sampleSize('abcdefghijklmnopqrstuvwxyz0123456789', 8).join('') };

                const modal = await this.modalCtrl.create({
                    component: MsiAuthProtectedActionModal,
                    componentProps: {
                        title: action,
                        token,
                        user,
                        org,
                    },
                    showBackdrop: true
                });


                // modal.onDidDismiss().then(async result => {
                //     if (result && result.data) {
                //         return this.cloudService.httpsCallable(this.backend)({
                //             computer,
                //             action,
                //             options
                //         }).toPromise();
                //     }
                // });

                await modal.present();
                const result = await modal.onDidDismiss();
                if (!result || !result.data) {
                    return null;
                }
            }
        }

        return this.cloudService.httpsCallable(this.backend)({
            computer,
            action,
            options: defaults(options, { apiVersion: 1 }),
        }).toPromise();

    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ChangePasswordPage } from './change-password.page';
import { MsiAuthChangePasswordFormModule } from '@msi/ionic/ui/auth/forms/change-password/change-password.module';

@NgModule({
    declarations: [
        ChangePasswordPage,
    ],
    imports: [ 
        CommonModule,
        IonicModule,
        MsiAuthChangePasswordFormModule,
    ],
    exports: [
        ChangePasswordPage
    ],
    providers: [],
})
export class ChangePasswordPageModule {}

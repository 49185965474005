import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-context-help-video-player',
    templateUrl: './context-help-video.component.html',
    styleUrls: [ './context-help-video.component.scss' ]
})
export class ContextHelpVideoPlayerComponent implements OnInit {

    @Input() url: string;
    @Input() text: string;
    
    safeUrl: SafeUrl;
    
    constructor(
        public modalCtrl: ModalController,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit(): void {
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url + '?autoplay=1&badge=0&autopause=0&player_id=0&app_id=58479');
    }
}

import { Injectable } from '@angular/core';

import { DBService } from '../../core/dao/db';
import { FirestoreService } from '../../core/dao/firebase/firestore';


import { Faq } from './entities/faq';
import { Observable, of } from 'rxjs';
import { FaqSection } from './entities/faq-section';

export * from './entities/faq';
export * from './entities/faq-section';

@Injectable({ providedIn: 'root'})
export class FaqService extends DBService<Faq> {
    constructor(db: FirestoreService) {
        super ('faqs', db);
        this.patchDefaults({ subsection_ids: [] });
    }

    findAllTopAnswers(): Observable<Faq[]> {
        return this.getAll(ref => ref.where('isTopAnswer', '==', true));
    }

    findAllQuicklinks(): Observable<Faq[]> {
        return this.getAll(ref => ref.where('isQuickLink', '==', true));
    }

    findAllTopAnswersForTopic(topic: FaqSection): Observable<Faq[]> {
        return topic ?
            this.getAll(ref => ref.where('section_id', '==', topic.id).where('isTopAnswer', '==', true)) : 
            this.findAllTopAnswers();
    }

    findAllQuicklinksForTopic(topic: FaqSection): Observable<Faq[]> {
        return topic ?
            this.getAll(ref => ref.where('section_id', '==', topic.id).where('isQuickLink', '==', true)) :
            this.findAllQuicklinks();
    }

    findAllForTopic(topic: FaqSection): Observable<Faq[]> {
        return topic && topic.id ?
            this.getAll(ref => ref.where('subsection_ids', 'array-contains', topic.id)) :
            of([]);
    }
}

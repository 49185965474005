import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppHeaderCurrentUserSlotComponent } from './current-user-header-slot.component';
import { AppHeaderCurrentUserMenuComponent } from './current-user-header-menu/current-user-header-menu.component';

@NgModule({
    // entryComponents: [
    //     AppHeaderCurrentUserMenuComponent,
    // ],
    declarations: [
        AppHeaderCurrentUserSlotComponent,
        AppHeaderCurrentUserMenuComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
    ],
    exports: [
        AppHeaderCurrentUserSlotComponent,
        AppHeaderCurrentUserMenuComponent,
    ],
})
export class CurrentUserHeaderSlotModule { }
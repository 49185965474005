import { FirestoreService } from '@msi/core/dao/firebase/firestore';


import { Injectable } from '@angular/core';
import { OVirtCluster } from './entities/ovirt-cluster';
import { CloudContextService } from './ovirt-context.service';


@Injectable({ providedIn: 'root' })
export class OVirtClustersService extends CloudContextService<OVirtCluster> {

    constructor(db: FirestoreService) {
        super('ovirt_clusters', db);
    }

}

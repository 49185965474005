import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MsiPageMenuComponent } from './page-menu.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
    declarations: [
        MsiPageMenuComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
    ],
    exports: [
        MsiPageMenuComponent
    ],
    providers: [],
})
export class MsiPageMenuModule { }

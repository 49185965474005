import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { SafePipeModule } from 'safe-pipe';
import { NgPipesModule } from 'ngx-pipes';

import { MsiGenericCardComponentModule } from './card/card.module';
import { MsiGenericCardGridComponent } from './generic-card-grid.component';

export interface GenericCardInfo {
    id: string;
    title: string;
    footer?: string;
    subtitle: string;
    description?: string;
    photoURL?: string;

    badge?: string;
    badgeColor?: string;
}

@NgModule({
    declarations: [
        MsiGenericCardGridComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        NgPipesModule,
        FlexLayoutModule,
        SafePipeModule,
        MsiGenericCardComponentModule,
    ],
    exports: [
        MsiGenericCardGridComponent
    ],
    providers: [],
})
export class MsiGenericCardGridModule { }

const topic = '[current-user]';

export class CurrentUserChangePassword {
    static readonly type: string = `${topic} request change password`;
}

export class CurrentUserEditProfile {
    static readonly type: string = `${topic} request edit profile`;
}

export class CurrentUserEditSettings {
    static readonly type: string = `${topic} request edit settings`;
}


export class CurrentUserLogout {
    static readonly type: string = `${topic} request logout`;
}

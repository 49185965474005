    <mat-chip-list #segmentList>


        <mat-chip
            *ngFor="let segment of segments"
            [selectable]="true"
            [removable]="true"
            (removed)="remove(segment)">
            {{segment}}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>


        <input #segmentsInput
            [placeholder]="to.placeholder"
            [matAutocomplete]="auto"
            [matChipInputFor]="segmentList"
            [matChipInputSeparatorKeyCodes]="to.separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>


    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let seg of filteredSegments$ | async" [value]="seg">
        {{seg}}
        </mat-option>
    </mat-autocomplete>

    <mat-hint *ngIf="to.description" >{{ to.description }}</mat-hint>

import { NavigationMenu } from '@msi/core/ngxs/ionic-router/ionic-router.actions';

export interface AppMenuHeader {
  title: string;
  background: string;
  picture: string;
  username: string;
  email: string;
  dispatch?: any;
}

export interface AppMenuBadge {
  text: string;
}

export interface AppMenuItem {

  title: string;
  badge?: AppMenuBadge;
  leftIcon?: string;
  rightIcon?: string;
  url?: string;
  page?: string;
  dispatch?: any; // FIXME:
  children?: AppMenuItem[];
  roles?: string[];

  disabled?: boolean;
}

export interface AppMenuDivider {
  isDivider: boolean;
}

export interface AppMenu {
  header: AppMenuHeader;
  entries: (AppMenuItem | AppMenuDivider)[];
}

export interface SidemenuHeaderOptions {
  background: string;
  picture: string;
  username: string;
  email?: string;
  dispatch: any; // FIXME:
}

export class SidemenuBadge {
  text?: string;
  color?: string;
}

export interface SidemenuItemOptions {
  title?: string;
  isSelected?: boolean;
  isDivider?: boolean;
  component?: any;
  leftIcon?: string;
  rightIcon?: string;
  classes?: string;

  badge?: SidemenuBadge;
  disabled?: boolean;
}

export interface SidemenuOptions {
  header: SidemenuHeaderOptions;
  entries: SidemenuItemOptions[];
}

const topic = `[sidemenu]`;

export class AppSelectMenuItem {
  static readonly type: string = `${topic} select menu item`;
  constructor(public menuItem: AppMenuItem) { }
}

export class AppSelectPageMenu {
  static readonly type: string = `${topic} select page menu`;
  constructor(public nav: NavigationMenu) { }
}

export class AppMenuGoBack {
  static readonly type: string = `${topic} select menu item`;
}

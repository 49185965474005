import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { User } from "@msi/core/dao/models/user";

@Component({
    templateUrl: './protected-action.modal.html',
    styleUrls: [ './protected-action.modal.scss']
})
export class MsiAuthProtectedActionModal {

    @Input() user: User;
    @Input() title: string;
    @Input() token: any;
    @Input() success: any;
    @Input() failure: any;

    constructor(public modalCtrl: ModalController) {}
}
<div class="auth_form_main_wrapper">
  <h1 class="mb30">Reset your password</h1>
  <form [formGroup]="resetPasswordForm" (submit)="resetPassword(email.value)" novalidate>
    <ion-item class="ion-no-padding custom_input mb30">
      <ion-label position="stacked">Email</ion-label>
      <ion-input
        tappable
        formControlName="email"
        type="email"
        placeholder="Enter you email address"
        [class.invalid]="
          !resetPasswordForm.controls.email.valid &&
          resetPasswordForm.controls.email.dirty
        "
      ></ion-input>
    </ion-item>

    <div class="error_message">
      <p
        *ngIf="
          !resetPasswordForm.controls.email.valid &&
          resetPasswordForm.controls.email.dirty
        "
      >
        Please enter a valid email.
      </p>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between">
      <ion-button (click)="goBack()" color="secondary" class="btn_secondary mr15 btn_medium">
        <ion-icon name="arrow-back" color="light"></ion-icon>
      </ion-button>
      <ion-button type="submit" [disabled]="resetPasswordForm.invalid" class="btn_primary btn_medium flex_1">
        Reset Password
      </ion-button>
    </div>
  </form>
</div>

import { BfitUser } from './bfit-user';

export enum BfitUserRole {
    master = 'Master',
    partner = 'Partner',
    manager = 'Manager',
    user = 'User',
    guest = 'Guest,'
}

// tslint:disable-next-line: no-empty-interface
export interface BfitOrgUser extends BfitUser {
    bfit_user_role: BfitUserRole;

}

import { State, Selector, Store, Action, StateContext, NgxsOnInit } from '@ngxs/store';
import { Observable } from 'rxjs';

import { produce } from '@ngxs-labs/immer-adapter';


import {
    UpdateProductCategories,
    ResetProductCategoryFilter,
    SetProductCategoryFilter,
    SelectProductCategory,
    ResetProductFilter,
    SetProductFilter,
    SelectProduct,
    UpdateProductList,
    SelectCatalogLayoutColumn,
    SelectCatalog
} from '../actions/shopping.actions';


import { tap } from 'rxjs/operators';

import { ShoppingProductCategory, ProductCategoryService } from '../models/product-categories';
import { ShoppingProduct, ProductService } from '../models/product';
import { MsiAppContext } from '@msi/core/dao/states/app-context.state';
import { ShoppingCatalog, CatalogService } from '../models/catalog';
import { TEST_CATALOGS } from '../constants/catalog';
import { ShowProductListPage, ShowProductDetailPage, ShowCatalogPage } from '@msi/e-commerce/ionic/shopping/store/shopping-nav.actions';
import { Injectable } from '@angular/core';


export interface ShoppingStateModel {
    ready: boolean;
    categoryFilter: string;
    categories: ShoppingProductCategory[];
    catalogs: ShoppingCatalog[];

    currentCatalog: ShoppingCatalog;
    currentCategory: ShoppingProductCategory;

    productFilter: string;
    products: ShoppingProduct[];
    currentProduct: ShoppingProduct;
}

const DEFAULTS: ShoppingStateModel = {
    ready: false,
    categoryFilter: null,
    catalogs: TEST_CATALOGS,
    categories: [],
    currentCatalog: TEST_CATALOGS[0],
    currentCategory: null,
    productFilter: null,
    products: [],
    currentProduct: null,
};

@State<ShoppingStateModel>({
    name: 'shoppingState',
    defaults: DEFAULTS
})
@Injectable({ providedIn: 'root' })
export class ShoppingState implements NgxsOnInit {

    @Selector()
    static ready(state: ShoppingStateModel): boolean { return state.ready; }

    @Selector()
    static catalogs(state: ShoppingStateModel): ShoppingCatalog[] {
        return state.catalogs;
    }

    @Selector()
    static currentCatalog(state: ShoppingStateModel): ShoppingCatalog {
        return state.currentCatalog;
    }

    @Selector()
    static categories(state: ShoppingStateModel): ShoppingProductCategory[] {
        // FIXME: do filtering
        // return state.categoryFilter ?
        //     lodash.filter(state.categories, (cat) => lodash.indexOf(cat.name.toLowerCase(), state.categoryFilter) >= 0) :
        //     [];
        return state.categories;
    }

    @Selector()
    static currentCategory(state: ShoppingStateModel): ShoppingProductCategory {
        return state.currentCategory;
    }

    @Selector()
    static products(state: ShoppingStateModel): ShoppingProduct[] {
        // FIXME: do filtering
        // return lodash.filter(state.products, (product) => product.name.toLowerCase().includes(state.productFilter)) || [];
        return state.products;
    }

    @Selector()
    static currentProduct(state: ShoppingStateModel): ShoppingProduct {
        return state.currentProduct;
    }

    @Selector()
    static state(state: ShoppingStateModel): ShoppingStateModel {
        return state;
    }

    constructor(
        private catalogService: CatalogService,
        private productCategoryService: ProductCategoryService,
        private productService: ProductService,
        private store: Store,
    ) { }

    ngxsOnInit(ctx?: StateContext<ShoppingStateModel>): void {
        this.store.select(MsiAppContext.readyAndAuthenticated).subscribe(ready => {
            if (ready) {
                this.store.dispatch(new UpdateProductCategories());
            } else {
                ctx.setState(DEFAULTS);
            }
        });
    }
    // ---- Actions ---------------------------------------------------------------------------

    @Action(UpdateProductCategories, { cancelUncompleted: true })
    updateCategories(ctx: StateContext<ShoppingStateModel>, _action: UpdateProductCategories): Observable<any> {
        return this.productCategoryService.findAll().pipe(
            tap(list => {
                // console.log ('got categories: ', list);
                produce(ctx, (draft) => {
                    draft.ready = true;
                    draft.categories = list;
                });
            })
        );
    }

    @Action(ResetProductCategoryFilter)
    resetCategoryFilter(ctx: StateContext<ShoppingStateModel>, _action: ResetProductCategoryFilter): void {
        ctx.patchState({ categoryFilter: '' });
    }

    @Action(SetProductCategoryFilter)
    setCategoryFilter(ctx: StateContext<ShoppingStateModel>, action: SetProductCategoryFilter): void {
        ctx.patchState({ categoryFilter: action.filter.toLocaleLowerCase() });
    }

    @Action(SelectCatalog)
    selectCatalog(ctx: StateContext<ShoppingStateModel>, action: SelectCatalog): Observable<any> {
        if (typeof action.catalog === 'string') {
            return this.catalogService.get(action.catalog).pipe(
                tap(catalog => {
                    ctx.patchState({ currentCatalog: catalog });
                })

            );
        } else {
            ctx.patchState({ currentCatalog: action.catalog });
        }
    }
    @Action(SelectProductCategory)
    selectCategory(ctx: StateContext<ShoppingStateModel>, action: SelectProductCategory): Observable<any> {

        if (typeof action.category === 'string') {
            return this.productCategoryService.get(action.category).pipe(
                tap(category => {
                    ctx.patchState({ currentCategory: category });
                    ctx.dispatch(new UpdateProductList());
                })

            );
        } else {
            ctx.patchState({ currentCategory: action.category });
            ctx.dispatch(new UpdateProductList());
        }
    }

    @Action(UpdateProductList, { cancelUncompleted: true })
    updateProducts(ctx: StateContext<ShoppingStateModel>): Observable<any[]> {
        const cat = ctx.getState().currentCategory;
        if (cat) {
            return this.productService.findAllByCategory(cat.id).pipe(
                tap(list => {
                    produce(ctx, (draft) => {
                        draft.products = list;
                        draft.productFilter = null;
                        draft.currentProduct = null;
                    });
                })
            );
        } else {
            return this.productService.findAll().pipe(
                tap(list => {
                    produce(ctx, (draft) => {
                        draft.products = list;
                        draft.productFilter = null;
                        draft.currentProduct = null;
                    });
                })
            );
        }
    }

    @Action(ResetProductFilter)
    resetProductFilter(ctx: StateContext<ShoppingStateModel>, _action: ResetProductFilter): void {
        ctx.patchState({ productFilter: '' });
    }

    @Action(SetProductFilter)
    setProductFilter(ctx: StateContext<ShoppingStateModel>, action: SetProductFilter): void {
        ctx.patchState({ productFilter: action.filter.toLocaleLowerCase() });
    }

    @Action(SelectProduct, { cancelUncompleted: true })
    selectProduct(ctx: StateContext<ShoppingStateModel>, action: SelectProduct): Observable<any> {

        if (typeof action.product === 'string') {
            return this.productService.get(action.product).pipe(
                tap(product => ctx.patchState({ currentProduct: product }))
            );
        } else {
            ctx.patchState({ currentProduct: action.product });
        }
    }

    @Action(SelectCatalogLayoutColumn)
    selectColumn(ctx: StateContext<ShoppingStateModel>, action: SelectCatalogLayoutColumn): void {
        switch (action.column.type) {
            case 'product':
                ctx.dispatch([
                    new SelectProduct(action.column.ref_id),
                    new ShowProductDetailPage(),
                ]);
                break;
            case 'category':
                ctx.dispatch([
                    new SelectProductCategory(action.column.ref_id),
                    new ShowProductListPage()
                ]);
                break;
            case 'catalog':
                ctx.dispatch([
                    new SelectCatalog(action.column.ref_id),
                    new ShowCatalogPage(),
                ]);
                break;
            case 'plan':
                // FIXME: We should be selecting a plan which is composed of multiple product plans
                ctx.dispatch([
                    new SelectProduct(action.column.ref_id),
                    new ShowProductDetailPage(),
                ]);
                break;
        }
    }
}

import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { IonVirtualScroll } from '@ionic/angular';
import { GenericCardInfo } from './generic-card-grid.module';
import { IdentityObject } from '@msi/core/dao/db';

export declare type TRANSFORMER = (obj: any, row?: any[], list?: any[]) => GenericCardInfo;

@Component({
    selector: 'msi-generic-card-grid',
    templateUrl: './generic-card-grid.component.html',
    styleUrls: ['./generic-card-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MsiGenericCardGridComponent {

    @Input() list: IdentityObject[];
    @Input() filterText?: string;
    @Input() rowHeight: number;
    @Input() transformer?: TRANSFORMER;
    
    @Output() select: EventEmitter<IdentityObject> = new EventEmitter();
    @Output() delete: EventEmitter<IdentityObject> = new EventEmitter();

    cols = 1;
    style = 'calc(100% - 20px)';

    @ViewChild(IonVirtualScroll, { static: true }) virtualScroll: IonVirtualScroll;


    constructor(
        private breakpointObserver: BreakpointObserver,
    ) {
        this.breakpointObserver.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge
        ]).subscribe((state: BreakpointState) => {
            if (state.breakpoints[Breakpoints.XSmall]) {
                console.log('Matches XSmall viewport');
                this.cols = 1;
            } else if (state.breakpoints[Breakpoints.Small]) {
                console.log('Matches Small viewport');
                this.cols = 2;
            } else if (state.breakpoints[Breakpoints.Medium]) {
                console.log('Matches Medium  viewport');
                this.cols = 2;
            } else if (state.breakpoints[Breakpoints.Large]) {
                console.log('Matches Large viewport');
                this.cols = 3;
            } else if (state.breakpoints[Breakpoints.XLarge]) {
                console.log('Matches XLarge viewport');
                this.cols = 4;
            }
            this.style = `calc((100% / ${this.cols}) - 20px);`;
            if (this.virtualScroll) {
                this.virtualScroll.checkEnd();
            }
        });
    }

    onSelectCard (card: GenericCardInfo, _event: MouseEvent): void {
        this.select.emit(this.list.find(it => it.id === card.id));
    }

    makeGenericCards(row: any[]): GenericCardInfo[] {
        return this.transformer ? row.map (col => this.transformer(col, row, this.list)) : row;
    }

    trackById(idx: number, obj: any): string {
        return 'id' in obj ? obj.id : idx;
    }
}


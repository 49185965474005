import { Component, OnInit } from '@angular/core';



import { MsiAuthVerifyMobile } from '@msi/ionic/core/auth/actions/auth.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import {
  MsiAuthRegisterWithGoogle,
  MsiAuthRegisterWithFacebook,
  MsiAuthRegisterWithTwitter
} from '@msi/ionic/core/auth/actions/register.actions';



// let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
// tslint:disable-next-line: max-line-length
// tslint:disable-next-line: max-line-length
// let URL_REGEXP = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;


@Component({
  selector: 'app-register-page',
  templateUrl: 'register.html',
  styleUrls: [ './register.scss' ]
})
export class RegisterPage implements OnInit {

  public backgroundImage = '/assets/img/auth/background.png';
  public recaptchaVerifier: firebase.auth.RecaptchaVerifier;


  ngOnInit() {


    // this.recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha-container');

  }


  @Dispatch()
  verifyMobile = () => new MsiAuthVerifyMobile()

  @Dispatch()
  registerWithGoogle = () => new MsiAuthRegisterWithGoogle()

  @Dispatch()
  registerWithFacebook = () => new MsiAuthRegisterWithFacebook()

  @Dispatch()
  registerWithTwitter = () => new MsiAuthRegisterWithTwitter()

}

<ion-content fullscreen class="auth_main_wrapper">
    <div class="flex auth_inner_wrapper">
        <div class="left_column_auth column">
            <msi-auth-login-form [config]="config$ | async"></msi-auth-login-form>
        </div>
        <div class="right_column_auth column">
            <!-- <img alt="logo" src="/assets/img/app-logo/butterflyIT_Logo.svg"/> -->
            <msi-image-slider [slides]="slides" [config]="slideConfig"></msi-image-slider>
        </div>
    </div>
</ion-content>
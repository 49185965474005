import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { User } from '@msi/core/dao/models/user';

@Component({
    selector: 'two-factor-auth-modal',
    templateUrl: './two-factor-auth.modal.html',
    styleUrls: [ './two-factor-auth.modal.scss' ]
})
export class TwoFactorAuthModal {

    @Input() user: User;
    @Input() token: any;
    @Input() success: any;
    @Input() failure: any;

    constructor(public modalCtrl: ModalController) {}

}
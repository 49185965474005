import { State, Action, StateContext, Selector, Store, NgxsOnInit } from '@ngxs/store';

import {

    MsiAuthWalkthroughCompleted,
    MsiAuthOrganizationHasChanged,

    MsiAuthContextHasChanged,
    MsiAuthLogout,
    MsiAuthExitApp,
    MsiAuthStartApp,
    MsiAuthStartWalkThrough,
    MsiAuthRequestHomePage,

    MsiAuthUnlockDevice,
    MsiAuthDeviceUnlocked,

    MsiAuthFirstTimeLoginCompleted,
    MsiAuthCheckFirstTimeLogin,
    MsiAuthLoginCompleted,
    MsiAuthLegalAgree,
    MsiAuthLegalDisagree,
    LoadAppContext,
    MsiAuthRequestFirstTimeLoginPage,
    FirebaseAuthContextHasChanged,
    MsiAuthChangeFirstPage,
} from '../actions/auth.actions';

import {
    MsiNavRequestPage,
    MsiAuthRequestLogin,
    MsiAuthRequestLogout,
    MsiAuthNavigateTo,
    MsiAuthPage
} from '../actions/nav.actions';

import * as lodash from 'lodash';


import { Organization, OrganizationsService } from '@msi/core/dao/models/organization';
import { User, UsersService } from '@msi/core/dao/models/user';

import { tap, switchMap } from 'rxjs/operators';

import { Observable } from 'rxjs';

import { LoadingController, AlertController } from '@ionic/angular';
import { MsiAuthConfig } from '../models/config';


import { MsiAuthRequestRegisterDevice, MsiAuthRegisterDevice } from '../actions/register.actions';
import { environment } from '@env';
import { MsiAuthCheckLoginWithEmail } from '../actions/login.actions';
import { MsiAuthLoginService } from '../services/login.service';
import { NavigateBackward, NavigateRoot } from '@msi/core/ngxs/ionic-router/public_api';
import { MsiAuthRequiresTwoFactorAuth, MsiAuthCheckTwoFactorAuth, MsiAuthResendTwoFactorCode } from '../actions/auth-login.actions';
import { Injectable } from '@angular/core';


export enum MsiAppState {
    Loading = 'loading',
    Starting = 'starting',
    Login = 'login',
    Ready = 'ready',
    Locked = 'locked',
    Logout = 'logout',
}
interface StateModel {
    config: MsiAuthConfig;
    currentUser: User;
    currentOrg: Organization;
    authenticated: boolean;
    appState: MsiAppState;
    firstPage: string;
}

const DEFAULTS: StateModel = {
    config: environment.authConfig,
    currentUser: null,
    currentOrg: null,
    authenticated: false,
    appState: MsiAppState.Loading,
    firstPage: '/auth/login'
};

export const AppConfig: any = {};

@State<StateModel>({
    name: 'msiAuthState',
    defaults: DEFAULTS
})
@Injectable({ providedIn: 'root' })
export class MsiAuthState implements NgxsOnInit {

    constructor(
        private authService: MsiAuthLoginService,
        private store: Store,

        private userService: UsersService,
        private organizationService: OrganizationsService,

        private alertCtrl: AlertController,
        private loadingCtrl: LoadingController,
    ) { }

    @Selector()
    static getConfig(state: StateModel): MsiAuthConfig { return state.config; }

    @Selector()
    static currentUser(state: StateModel): User { return state.currentUser; }

    @Selector()
    static currentOrg(state: StateModel): Organization { return state.currentOrg; }

    @Selector()
    static authenticated(state: StateModel): boolean { return state.authenticated; }

    @Selector()
    static currentState(state: StateModel): MsiAppState { return state.appState; }

    @Selector()
    static ready(state: StateModel): boolean { return state.appState === MsiAppState.Ready; }


    ngxsOnInit(_ctx: StateContext<StateModel>): void { }

    @Action(FirebaseAuthContextHasChanged)
    contextHasChanged(ctx: StateContext<StateModel>, action: FirebaseAuthContextHasChanged): void {

        /* // FIXME: review conversion of as User
        ctx.patchState({
            ready: true,
            currentUser: action.user as User,
            authenticated: action.authenticated,
        });
        ctx.dispatch(new MsiAuthContextHasChanged()); */

        if (action.user) {
            ctx.dispatch(new LoadAppContext(action.user.id || action.user.uid));
        } else {
            ctx.dispatch(new MsiAuthRequestLogin());
        }

    }

    @Action(MsiAuthOrganizationHasChanged)
    orgHasChanged(ctx: StateContext<StateModel>, action: MsiAuthOrganizationHasChanged) {
        ctx.patchState({ currentOrg: action.org });
    }

    // @Action(MsiAuthUserHasChanged)
    // userHasChanged(ctx: StateContext<StateModel>, action: MsiAuthUserHasChanged) {
    //     ctx.patchState({ currentUser: action.user });
    //     if (action.user && action.user.requires2fa) {
    //         ctx.dispatch(new MsiAuthRequiresTwoFactorAuth(action.user, ));
    //     }
    // }

    @Action(MsiAuthCheckTwoFactorAuth)
    async check2FA(ctx: StateContext<StateModel>, action: MsiAuthCheckTwoFactorAuth): Promise<any> {
       
        const org = ctx.getState().currentOrg;
        const result = await this.authService.verify2FA(org?.id, action.pin);
        if (result.verified) {
            ctx.dispatch(new MsiAuthRequestHomePage());
        } else {
            const alert = await this.alertCtrl.create({ message: result.msg });
            return alert.present();
        }
    }

    @Action(MsiAuthChangeFirstPage)
    changeFirstPage(ctx: StateContext<StateModel>, action: MsiAuthChangeFirstPage): void {
        ctx.patchState({ firstPage: action.url });
        if (ctx.getState().appState === MsiAppState.Ready) {
            ctx.dispatch(new NavigateRoot(action.url));
        }
    }

    @Action(MsiAuthWalkthroughCompleted, { cancelUncompleted: true })
    walkthroughCompleted(ctx: StateContext<StateModel>): Observable<boolean> {

        return this.store.select(MsiAuthState.ready).pipe(
            tap(ready => {
                if (ready) {

                    const appState = ctx.getState().appState;
                    const authenticated = ctx.getState().authenticated;

                    if (authenticated) {
                        if (appState === MsiAppState.Starting) {

                            ctx.dispatch(new MsiAuthCheckFirstTimeLogin());

                        } else {
                            ctx.dispatch(new MsiAuthRequestHomePage());
                        }
                    } else {
                        ctx.dispatch(new MsiAuthRequestLogin());
                    }
                }
            })
        );
    }

    @Action(LoadAppContext, { cancelUncompleted: true })
    loadAppContext(ctx: StateContext<StateModel>, action: LoadAppContext): Observable<any> {
        return this.userService.get(action.userId).pipe(
            switchMap(user => {
                return this.organizationService.get(user.org_id).pipe(
                    tap(org => {
                        ctx.patchState({ 
                            currentUser: user, 
                            currentOrg: org,
                            authenticated: !!user && !!org , 
                            appState: MsiAppState.Ready 
                        });
                        if (user.requires2fa) {
                            ctx.dispatch(new MsiAuthRequiresTwoFactorAuth(user, new MsiAuthContextHasChanged(), new MsiAuthLogout()));
                        } else {
                            ctx.dispatch(new MsiAuthContextHasChanged());
                        }
                    })
                );
            })          
        );
    }

    @Action(MsiAuthLoginCompleted, { cancelUncompleted: true })
    loginCompleted(ctx: StateContext<StateModel>): void {

        ctx.dispatch(new MsiAuthCheckFirstTimeLogin());

    }

    @Action(MsiAuthCheckFirstTimeLogin)
    checkFirstTimeLogin(ctx: StateContext<StateModel>) {
        const user = ctx.getState().currentUser;
        if (user.firstTimeLogin) {
            ctx.dispatch(new MsiAuthRequestFirstTimeLoginPage());
        } else {
            ctx.dispatch(new MsiAuthRequestHomePage());
        }
    }

    @Action(MsiAuthRequestFirstTimeLoginPage)
    requestFirstTimeLoginPage(_ctx: StateContext<StateModel>): void {

    }

    @Action(MsiAuthFirstTimeLoginCompleted)
    firstTimeLoginCompleted(ctx: StateContext<StateModel>) {
        ctx.patchState({ appState: MsiAppState.Locked });
        ctx.dispatch(new MsiAuthRequestHomePage());

    }

    @Action(MsiAuthRequestLogout)
    async requestLogout(ctx: StateContext<StateModel>): Promise<void> {
        const alert = await this.alertCtrl.create({
            header: 'Do you want to logout?',
            message: 'When you log out the application will close and you will have to provide your credential next time you start the app',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'Logout',
                    handler: () => { ctx.dispatch(new MsiAuthLogout()); }
                }
            ]
        });

        return await alert.present();
    }

    @Action(MsiAuthLogout)
    async logout(ctx: StateContext<StateModel>): Promise<any> {
        return this.authService.logout().then(() => {
            return ctx.dispatch(new MsiAuthExitApp());
        });
    }

    @Action(MsiAuthExitApp)
    exitApp(_ctx: StateContext<StateModel>) {
        // this.platform.exitApp();
        return setTimeout(() => {
            // If the application hasn't exited (browser) we restart it.
            window.location.reload();
        }, 1000);
    }

    @Action(MsiAuthStartApp, { cancelUncompleted: true })
    startApp(ctx: StateContext<StateModel>): Observable<any> {

        return this.store.select(MsiAuthState.currentUser).pipe(
            switchMap(async user => {

                ctx.patchState({ appState: MsiAppState.Starting });

                if (!user || user.firstTimeLogin) {
                    return ctx.dispatch(new MsiAuthStartWalkThrough());
                }

                if (!user) {
                    return ctx.dispatch(new MsiAuthRequestLogin());
                } else {

                    return ctx.dispatch(new MsiAuthRequestHomePage());

                }
            })
        );
    }

    @Action(MsiAuthRequestHomePage)
    home(ctx: StateContext<StateModel>): Observable<any> {

        const appState = ctx.getState().appState;
        const user = ctx.getState().currentUser;
        // const org = ctx.getState().currentOrg;

        if (!user) {
            return ctx.dispatch(new MsiAuthRequestLogin());
        }

        if (appState === MsiAppState.Locked && user && lodash.get(user, 'settings.useFingerprints')) {
            return ctx.dispatch(new MsiAuthUnlockDevice());
        }

        // if (!user.org_id) {
        //     return ctx.dispatch (new MsiAuthRequestRegisterDevice());
        // }

        ctx.patchState({ appState: MsiAppState.Ready });
        ctx.dispatch(new MsiAuthNavigateTo(MsiAuthPage.homePage));
    }

    @Action(MsiAuthRequestRegisterDevice)
    requestRegisterDevice(ctx: StateContext<StateModel>) {

        ctx.dispatch(new MsiAuthNavigateTo(MsiAuthPage.registerDevicePage));
    }

    @Action(MsiAuthRegisterDevice)
    registerDevice(ctx: StateContext<StateModel>) {

        const user = ctx.getState().currentUser;
        user.org_id = 'testco';
        ctx.patchState({ currentUser: user });

        // FIXME: We need to register the device here
        /* ctx.patchState(
            produce (ctx.getState(), draft => {
                draft.currentUser.org_id = 'testco';
            })
        ); */
        ctx.dispatch(new MsiAuthRequestHomePage());
    }

    @Action(MsiAuthStartWalkThrough)
    walktrough(ctx: StateContext<StateModel>) {

        ctx.dispatch(new MsiAuthNavigateTo(MsiAuthPage.introPage));
    }

    @Action(MsiNavRequestPage)
    navigate(_ctx: StateContext<StateModel>) {
        // return .push(action.pageName, action.navParams);
    }

    @Action(MsiAuthUnlockDevice)
    unlockDevice(ctx: StateContext<StateModel>) {
        ctx.dispatch(new MsiAuthNavigateTo(MsiAuthPage.unlockPage));
    }

    @Action(MsiAuthDeviceUnlocked)
    deviceUnlocked(ctx: StateContext<StateModel>) {
        ctx.dispatch(new MsiAuthRequestHomePage());
    }

    @Action(MsiAuthLegalAgree)
    agree(ctx: StateContext<StateModel>) {
        ctx.dispatch(new NavigateBackward('/home'));
    }

    @Action(MsiAuthLegalDisagree)
    disagree(ctx: StateContext<StateModel>) {
        ctx.dispatch(new NavigateBackward('/home'));
    }

    /*     @Action(MsiAuthChangePassword)
        async changePassword(ctx: StateContext<MsiAuthStateModel>, action: MsiAuthChangePassword): Promise<any> {
            return this.auth.changePassword(action.password).then(() => {
                return this.userService.update(ctx.getState().currentUser.id, { changePassword: false }).then(() => {
                    ctx.dispatch(new MsiAuthRequestHomePage());
                });
            });
        } */

    /* @Action(UpdateCurrentUserProfile)
    updateCurrentUserProfile(ctx: StateContext<MsiAuthStateModel>, action: UpdateCurrentUserProfile): Promise<User> {

        const user = ctx.getState().currentUser;

        if (ctx.getState().currentUser) {
            return this.auth.updateUserData(user.id, action.profile);
        } else {
            return Promise.reject('User not logged');
        }
    }
 */
    @Action(MsiAuthCheckLoginWithEmail)
    async emailLogin(_ctx: StateContext<StateModel>, action: MsiAuthCheckLoginWithEmail): Promise<any> {

        const loadingPopup = await this.loadingCtrl.create({
            spinner: 'crescent',
            message: 'Login ...'
        });
        await loadingPopup.present();

        return this.authService.signInWithEmailAndPassword(action.email, action.password).then(() => {

            loadingPopup.dismiss();


            // setTimeout(() => {
            //     loadingPopup.dismiss();
            //     ctx.dispatch(new MsiAuthLoginCompleted()); // FIXME: Doesn't make sense here
            // }, 1000);

        }).catch(async error => {
            const errorMessage: string = error.message;
            await loadingPopup.dismiss();
            return this.presentAlert(errorMessage);
        });
    }

    @Action(MsiAuthResendTwoFactorCode)
    async send2FA (_ctx: StateContext<StateModel>, action: MsiAuthResendTwoFactorCode): Promise<any> {
        const org = _ctx.getState().currentOrg;
        return this.authService.request2FA(org.id, action.sms);
    }

    private async presentAlert(title: string, subtitle?: string) {
        const alert = await this.alertCtrl.create({
            header: title,
            subHeader: subtitle,
            buttons: ['OK']
        });
        await alert.present();
    }

   
}

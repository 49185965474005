<ion-header>
    <ion-toolbar>

        <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
        </ion-buttons>

        <ion-title>Change password</ion-title>

    </ion-toolbar>
</ion-header>


<ion-content fullscreen class="ion-padding">

    <div class="page_container" fxLayout="row" fxLayoutAlign="center">

        <div class="msi-card edit-profile-card">
            <div class="msi-card-header">
                <h1>Change my password</h1>
                <div class="msi-card-close-icon" (click)="cancel()">
                    <ion-icon name="close-outline"></ion-icon>
                </div>
            </div>
            <div class="msi-card-body">

                <msi-auth-change-password-form></msi-auth-change-password-form>
            </div>
        </div>
    </div>
</ion-content>
<div class="auth_form_main_wrapper">
  <h1 *ngIf="!!title" class="mb30">{{ title || 'Sign up' }}</h1>
  
  <form [formGroup]="registerForm" novalidate>


    <ion-item class="ion-no-padding custom_input">
      <ion-label position="floating">Name:</ion-label>
      <ion-input tappable autofocus placeholder="Name" formControlName="profileName" type="text" [class.invalid]="profileName.invalid && profileName.touched"></ion-input>
    </ion-item>

    <ion-item class="ion-no-padding custom_input">
      <ion-label position="floating">Email:</ion-label>
      <ion-input tappable placeholder="Email Address" formControlName="email" type="email" [class.invalid]="email.invalid && email.touched"></ion-input>
    </ion-item>

    <ion-item class="ion-no-padding custom_input">
      <ion-label position="floating">Mobile:</ion-label>
      <ion-input tappable placeholder="Mobile" mask="(000) 000-0000" formControlName="phone" type="tel" [class.invalid]="phone.invalid && phone.touched"></ion-input>
    </ion-item>

    <ion-item class="ion-no-padding custom_input">
      <ion-label position="floating">Password</ion-label>
      <ion-input tappable placeholder="Password" formControlName="password" type="password" [class.invalid]="password.invalid && password.touched"></ion-input>
    </ion-item>

    <ion-item class="ion-no-padding custom_input">
      <ion-label position="floating">Confirm your password:</ion-label>
      <ion-input tappable placeholder="Confirm your password" formControlName="confirmPassword" type="password" [class.invalid]="confirmPassword.invalid && confirmPassword.touched"></ion-input>
    </ion-item>


    <!-- <ion-item>
        <ion-label>I have read and agree to terms and conditions</ion-label>
        <ion-checkbox formControlName="terms" [class.invalid]="terms.invalid && terms.touched"></ion-checkbox>
      </ion-item>
    -->


    <div class="error_message">
      <p *ngIf="profileName.invalid && profileName.dirty">*Please enter your name.</p>
      <p *ngIf="email.invalid && email.dirty">*Please enter a valid email.</p>
      <p *ngIf="phone.invalid  && phone.dirty">*Please enter phone number.</p>
      <p *ngIf="password.invalid  && password.dirty">*Password needs more than 6 characters.</p>
      <p *ngIf="confirmPassword.valid && confirmPassword.dirty && password.value != confirmPassword.value">*Passwords needs to match</p>
    </div>

    <div id="recaptcha-container"></div>

    <div class="login_link_wrapper mb30 mt30 flex flex_centered flex_space">
      <ion-item class="ion-no-padding custom_input" lines="none">
        <ion-label class="mfs_14">I agree to the Butterfly IT <a href="">terms and conditions</a></ion-label>
        <ion-checkbox class="mr10" slot="start" color="primary"></ion-checkbox>
      </ion-item>
    </div>

    <ion-button (click)="registerUser()" class="btn_primary w-100 btn_medium" *ngIf="!(isUserRegistered$ | async)" [disabled]="registerForm.invalid">
      Create account
    </ion-button>

  </form>
</div>



import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { DBService } from '@msi/core/dao/db';
import { Observable } from 'rxjs';
import { BfitSubscription } from './entities/subscription';
import { Injectable } from '@angular/core';

export * from './entities/subscription';

@Injectable({ providedIn: 'root'})
export class BfitSubscriptionService extends DBService<BfitSubscription> {

        constructor(db: FirestoreService) {
            super ('subscriptions', db);
        }

        findAllPlans(): Observable<BfitSubscription[]> {
            return this.getAll (ref => ref.where ('type', '==', 'plan'));
        }
}

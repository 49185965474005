import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { OVirtDataCentersService } from './models/ovirt/ovirt-data-centers';
import { OVirtVmsService } from './models/ovirt/ovirt-vms';

import { OVirtVmsProcessing } from './services/ovirt-vms-mgmt.service';
import { OVirtClustersService } from './models/ovirt/ovirt-clusters';
import { OVirtDisksService } from './models/ovirt/ovirt-disks';
import { OVirtNetworksService } from './models/ovirt/ovirt-networks';
import { OVirtTemplatesService } from './models/ovirt/ovirt-templates';

import { OVirtAffinityGroupsService } from './models/ovirt/ovirt-affinity-groups';
import { OVirtTemplateProcessing } from './services/ovirt-template-mgmt.service';
import { OVirtJobRequestsService } from './models/ovirt/ovirt-job-requests';
import { OVirtJobsService } from './models/ovirt/ovirt-jobs';
import { OVirtEventsService } from './models/ovirt/ovirt-events';
import { OVirtClusterProcessing } from './services/ovirt-cluster-mgmt.service';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        IonicModule,

    ],
    exports: [],
    providers: [
   

        OVirtDataCentersService,
        OVirtVmsService,
        OVirtClustersService,
        OVirtDisksService,
        OVirtNetworksService,
        OVirtTemplatesService,
        OVirtAffinityGroupsService,
        OVirtJobRequestsService,
        OVirtJobsService,
        OVirtEventsService,

        OVirtVmsProcessing,
        OVirtTemplateProcessing,
        OVirtClusterProcessing,

    ],
})
export class OVirtCoreModule { }

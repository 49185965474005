import { Component } from '@angular/core';

import { FormBuilder, Validators, AbstractControl, FormGroup } from '@angular/forms';

import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { MsiAuthCurrentUserChangePassword, MsiAuthCurrentUserForgotPassword } from '@msi/ionic/core/auth/actions/auth.actions';
import { MustMatch } from '@msi/ionic/core/auth/services/validators/must-match';



@Component({
  selector: 'msi-auth-change-password-form',
  templateUrl: 'change-password.html',
  styleUrls: ['./change-password.scss']
})
export class ChangePasswordFormComponent {


  public passwordForm: FormGroup;
  public backgroundImage = '/assets/bg1.jpg';
  public imgLogo: any = '/assets/img/padlock.png';


  constructor(
    fb: FormBuilder,
  ) {

    this.passwordForm = fb.group({
      currentPassword:  ['', Validators.compose([Validators.minLength(6), Validators.required])],
      password: ['', Validators.compose([Validators.minLength(6), Validators.required])],
      verifyPassword: ['', Validators.compose([Validators.minLength(6), Validators.required])]
    },
    {
      validators: MustMatch('password', 'verifyPassword')
    });
  }

  get currentPassword(): AbstractControl { return this.passwordForm.get('currentPassword'); }
  get password(): AbstractControl { return this.passwordForm.get('password'); }
  get verifyPassword(): AbstractControl { return this.passwordForm.get('verifyPassword'); }

  @Dispatch()
  changePassword = () => new MsiAuthCurrentUserChangePassword(this.currentPassword.value, this.password.value)

  @Dispatch()
  forgotPassword = () => new MsiAuthCurrentUserForgotPassword()

}

import { QueryFn } from '@angular/fire/firestore';
import { Observable, of, BehaviorSubject } from 'rxjs';


import { switchMap } from 'rxjs/operators';
import { OrgBasedObject } from '@msi/core/dao/services/org-based-db.service';
import { FirestoreService } from '@msi/core/dao/firebase/firestore';
import { ObjectReference } from '@bfit/core/models/entities/ref';


import { DBService } from '@msi/core/dao/db';
import { merge } from 'lodash';
import { BfitUser } from '@bfit/core/models/bfit/entities/bfit-user';
import { BfitCloud } from '@bfit/core/models/bfit/bfit-clouds';
import { BfitOrganization } from '@bfit/core/models/bfit/bfit-organizations';

export interface DataCenterContextBasedObject extends OrgBasedObject {
    cloud_id: string;
    bfit_org_ref: ObjectReference<BfitOrganization> | null;
    bfit_user_ref: ObjectReference<BfitUser> | null;
}

export interface CurrentCloudContext {
    currentCloud: BfitCloud;
    currentOrg: BfitOrganization;
    currentUser: BfitUser;
}

export const CURRENT_CLOUD_CONTEXT$: BehaviorSubject<CurrentCloudContext> = new BehaviorSubject({
    currentCloud: null,
    currentOrg: null,
    currentUser: null
});

export class CloudContextService<T extends DataCenterContextBasedObject> extends DBService<T> {

    constructor(protected dbname: string, protected db: FirestoreService) {
        super(dbname, db);
        CURRENT_CLOUD_CONTEXT$.subscribe(_ctx => {
            this.patchDefaults({});
        });
    }

    get collectionPath(): string {
        const cloud = CURRENT_CLOUD_CONTEXT$.value.currentCloud;
        return cloud ? `ovirt/${cloud.id}/${this.dbname}` : '';
    }

    protected getAll(queryFn?: QueryFn): Observable<T[]> {
        // console.log ('[OrgBasedService.getAll]', this.entityName);
        return CURRENT_CLOUD_CONTEXT$.pipe(
            switchMap(ctx => {
                const cloud = ctx.currentCloud;
                // return (cloud ? super.getAll(this.buildQueryForDataCenter(cloud.dataCenter_id, queryFn)) : of([]));
                return cloud ? super.getAll(queryFn) : of([]);
            })
        );
    }

    // private buildQueryForDataCenter(dc: string, queryFn?: QueryFn): QueryFn {
    //     return (ref: CollectionReference) => (queryFn ? queryFn(ref) : ref).where('dataCenter_id', '==', dc);
    // }

    // findAll(queryFn?: QueryFn): Observable<T[]> {
    //     const ctx = CURRENT_DATA_CENTER_CONTEXT$.value;
    //     return this.getAll(queryFn).pipe(
    //         switchMap(list => {
    //             const result = list.filter(item => !ctx.currentOrg ||
    //                 (ctx.currentOrg && item.bfit_org_ref && item.bfit_org_ref.id === ctx.currentOrg.id)
    //             );
    //             return of(result);
    //         })
    //     );
    // }

    patchDefaults(data: Partial<T>): void {
        const ctx = CURRENT_CLOUD_CONTEXT$.value;
        super.patchDefaults(merge(data, {
            cloud_id: ctx.currentCloud && ctx.currentCloud.id || null,
            dataCenter_id: ctx.currentCloud && ctx.currentCloud.dataCenter_id || null,
            bfit_org_ref: ctx.currentOrg && {
                id: ctx.currentOrg.id,
                name: ctx.currentOrg.name,
                photoURL: ctx.currentOrg.photoURL,
            } || null,
            bfit_user_ref: ctx.currentUser && {
                id: ctx.currentUser.id,
                name: ctx.currentUser.displayName,
                photoURL: ctx.currentUser.photoURL,
            } || null,
        }));
    }

}

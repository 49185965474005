import { Component, Input } from '@angular/core';


@Component({
    selector: 'app-context-help',
    templateUrl: './context-help.component.html',
    styleUrls: [ './context-help.component.scss' ]
})
export class ContextHelpComponent {

    @Input() menuId: string;

    query: string;
    
}

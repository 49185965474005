import { QueryFn } from '@angular/fire/firestore';
import { Observable, of, BehaviorSubject } from 'rxjs';


import { switchMap } from 'rxjs/operators';
import { OrgBasedObject } from '@msi/core/dao/services/org-based-db.service';
import { FirestoreService } from '@msi/core/dao/firebase/firestore';
import { ObjectReference } from '../entities/ref';

import { BfitUser } from './entities/bfit-user';

import { DBService } from '@msi/core/dao/db';
import { BfitOrganization } from './bfit-organizations';


export interface BfitContextBasedObject extends OrgBasedObject {
    bfit_org_ref: ObjectReference<BfitOrganization> | null;
    bfit_shared_with_ref?: ObjectReference<BfitUser> | null;
    bfit_user_ref?: ObjectReference<BfitUser> | null;
}

export interface CurrentOrganizationContext {
    currentOrg: BfitOrganization;
    currentUser: BfitUser;
}

export const CURRENT_ORGANIZATION_CONTEXT$: BehaviorSubject<CurrentOrganizationContext> = new BehaviorSubject({
    currentOrg: null,
    currentUser: null
});

export abstract class BfitContextService<T extends BfitContextBasedObject> extends DBService<T> {

    constructor(protected dbname: string, protected db: FirestoreService) {
        super(dbname, db);
        CURRENT_ORGANIZATION_CONTEXT$.subscribe(ctx => {
            const data = {
                org_id: ctx.currentOrg && ctx.currentOrg.id || null,
                bfit_org_ref: ctx.currentOrg && {
                    id: ctx.currentOrg.id,
                    name: ctx.currentOrg.name,
                    photoURL: ctx.currentOrg.photoURL,
                } || null,
                bfit_user_ref: ctx.currentUser && {
                    id: ctx.currentUser.id,
                    name: ctx.currentUser.displayName,
                    photoURL: ctx.currentUser.photoURL,
                } || null,
            };

            this.patchDefaults(data as Partial<T>);
        });
    }

    protected get collectionPath(): string {
        const org = CURRENT_ORGANIZATION_CONTEXT$.value.currentOrg;
        return org ? `organizations/${org.id}/${this.dbname}` : '';
    }

    protected getAll(queryFn?: QueryFn): Observable<T[]> {
        // console.log ('[OrgBasedService.getAll]', this.entityName);
        return CURRENT_ORGANIZATION_CONTEXT$.pipe(
            switchMap(ctx => {
                return ctx.currentOrg ? super.getAll(queryFn) : of([]);
            })
        );
    }

    findAll(queryFn?: QueryFn): Observable<T[]> {
        return CURRENT_ORGANIZATION_CONTEXT$.pipe(
            switchMap (ctx => {
                if (ctx.currentOrg) {
                    return this.getAll(queryFn).pipe(
                        switchMap(list => {
                            const result = list.filter(item => !ctx.currentUser ||
                                (ctx.currentUser && item.bfit_user_ref && item.bfit_user_ref.id === ctx.currentUser.id)
                            );
                            return of(result);
                        })
                    );
                } else {
                    return of([]);
                }
            })
        );
    }

    findAllForOrganization(org: BfitOrganization): Observable<T[]> {
        return org ? super.getAll(ref => ref.where('bfit_org_ref.id', '==', org.id)) : of([]);
    }
}

import { FirestoreService } from '@msi/core/dao/firebase/firestore';


import { Injectable } from '@angular/core';
import { OVirtDataCenter } from './entities/ovirt-data-center';
import { CloudContextService } from './ovirt-context.service';

export * from './entities/ovirt-data-center';

@Injectable({ providedIn: 'root'})
export class OVirtDataCentersService extends CloudContextService<OVirtDataCenter> {

    constructor(db: FirestoreService) {
        super ('ovirt_datacenters', db);
    }

}

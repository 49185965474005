import { MsiAuthUpdateUserSettings } from '@msi/ionic/core/auth/actions/auth.actions';

import { MsiAuthCurrentUserChangePassword, MsiAuthCurrentUserForgotPassword, MsiAuthLogout, MsiAuthUpdateUserProfile, MsiAuthCurrentUserResetTemporaryPassword } from './../actions/auth.actions';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store } from '@ngxs/store';

import {
    MsiAuthRegisterWithEmail,
    MsiAuthOperationSuccessful,
    MsiAuthOperationFailure,
    MsiAuthRequestNewPassword,
    MsiAuthResetPassword
} from '../actions/auth-login.actions';

import { MsiAuthService } from '../services/auth.service';
import { MsiAuthState } from './auth.state';

// tslint:disable-next-line:no-empty-interface
interface StateModel {
}

@State<StateModel>({
    name: 'msiAuthAdminState',
})
@Injectable({ providedIn: 'root' })
export class MsiAuthAdminState {

    constructor(
        private authService: MsiAuthService,
        private store: Store,
    ) { }

    @Action(MsiAuthRegisterWithEmail)
    async registerWithEmail(ctx: StateContext<StateModel>, action: MsiAuthRegisterWithEmail): Promise<any> {

        ctx.patchState({ submitted: true });

        try {
            await this.authService.emailSignUp(action.email, action.password);
            ctx.dispatch(new MsiAuthOperationSuccessful('register', 'Registration successful'));
        } catch (err) {
            ctx.dispatch(new MsiAuthOperationFailure('register', `Error during registration: ${err.message}`));
        }

    }

    @Action(MsiAuthRequestNewPassword)
    async requestNewPassword(ctx: StateContext<StateModel>, action: MsiAuthRequestNewPassword): Promise<any> {

        ctx.patchState({ submitted: true });

        try {
            await this.authService.requestPassword(action.email);
            ctx.dispatch(new MsiAuthOperationSuccessful('request-password', 'Check your email'));
        } catch (e) {
            ctx.dispatch(new MsiAuthOperationFailure('request-password', 'Check your email'));
        }
    }

    @Action(MsiAuthResetPassword)
    async resetpassword(ctx: StateContext<StateModel>, action: MsiAuthResetPassword): Promise<any> {

        ctx.patchState({ submitted: true });

        try {
            await this.authService.resetPassword(action.email);
            ctx.dispatch(new MsiAuthOperationSuccessful('reset-password', 'Password reseted. Please login again'));
        } catch (err) {
            ctx.dispatch(new MsiAuthOperationFailure('request-password', `Fail to reset your password: ${err.message}`));
        }
    }

    @Action(MsiAuthUpdateUserProfile)
    async updateProfile(ctx: StateContext<StateModel>, action: MsiAuthUpdateUserProfile): Promise<void> {

        try {
            await this.authService.updateProfile(action.user, action.profile);
            ctx.dispatch(new MsiAuthOperationSuccessful('update-profile', 'Profile updated'));
            if (action.user.email !== action.profile.email || action.user.mobile !== action.profile.mobile) {
                ctx.dispatch(new MsiAuthLogout());
            }
        } catch (err) {
            ctx.dispatch(new MsiAuthOperationFailure('update-profile', `Fail to update user profile: ${err.message}`));
        }
    }

    @Action(MsiAuthUpdateUserSettings)
    async updateSettings(ctx: StateContext<StateModel>, action: MsiAuthUpdateUserSettings): Promise<void> {

        try {
            await this.authService.updateSettings(action.user, action.settings);
            ctx.dispatch(new MsiAuthOperationSuccessful('update-settings', 'Settings updated'));
            
        } catch (err) {
            ctx.dispatch(new MsiAuthOperationFailure('update-settings', `Fail to update user settings: ${err.message}`));
        }
    }

    @Action(MsiAuthCurrentUserChangePassword)
    async changePassword(ctx: StateContext<StateModel>, action: MsiAuthCurrentUserChangePassword): Promise<void> {

        try {
            const user = this.store.selectSnapshot(MsiAuthState.currentUser);
            await this.authService.changePassword(user, action.currentPassword, action.newPassword);
            ctx.dispatch([
                new MsiAuthOperationSuccessful('Change Password', 'Please log in again'),
                new MsiAuthLogout()
            ]);
        } catch (err) {
            ctx.dispatch(new MsiAuthOperationFailure('change-password', err.message));
        }
    }

    @Action(MsiAuthCurrentUserResetTemporaryPassword)
    async resetTemporaryPassword(ctx: StateContext<StateModel>, _action: MsiAuthCurrentUserResetTemporaryPassword): Promise<void> {

        try {
            const user = this.store.selectSnapshot(MsiAuthState.currentUser);
            await this.authService.resetTemporaryPassword(user);
            ctx.dispatch([
                new MsiAuthOperationSuccessful('Reset Password', 'An email has been sent with a new temporary password'),
                new MsiAuthLogout()
            ]);
        } catch (err) {
            ctx.dispatch(new MsiAuthOperationFailure('reset-password', err.message));
        }
        
    }

    @Action(MsiAuthCurrentUserForgotPassword)
    async forgotPassword(ctx: StateContext<StateModel>): Promise<void>{
        
        try {
            const user = this.store.selectSnapshot(MsiAuthState.currentUser);
            await this.authService.requestPassword(user.email);
            ctx.dispatch(new MsiAuthOperationSuccessful('Reset Password', `An email has been sent to ${user.email}`));
        } catch (e) {
            ctx.dispatch(new MsiAuthOperationFailure('Reset Password', e.message));
        }
    } 
} 


import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FirestoreService } from './firebase/firestore';
import { AvatarUploadService } from './storage/avatar-upload.service';
import { FirestoreDatePipe } from './pipes/date.pipe';

import { DocPipe } from './pipes/doc-ref-pipe';
import { DatabaseProvider } from './firebase/database';

import { UsersService } from './models/user';
import { OrganizationsService } from './models/organization';
import { NgxsModule } from '@ngxs/store';
import { MsiAppContext } from './states/app-context.state';
import { UploadsService } from './models/uploads';
import { FileUploadService } from './storage/file-upload.service';
import { MsiPatchedAngularFireFunctions } from './services/patched-cloud-function';
import { IdleTimeoutModule } from '@bfit/ionic/auth/modals/idle-timeout/idle-timeout.module';


export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
    if (parentModule) {
        throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
    }
}

export interface MsiDatabaseConfig {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
}

@NgModule({
    declarations: [
        FirestoreDatePipe,
        DocPipe,
    ],
    imports: [
        CommonModule,

        IdleTimeoutModule,
        NgxsModule.forFeature([MsiAppContext]),
    ],
    exports: [
        FirestoreDatePipe,
        DocPipe
    ],
    providers: [

    ],
})
export class MsiDatabaseModule {

    constructor(@Optional() @SkipSelf() parentModule: MsiDatabaseModule) {
        throwIfAlreadyLoaded(parentModule, 'MsiDatabaseModule');
    }

    static forRoot(_config: MsiDatabaseConfig): ModuleWithProviders<MsiDatabaseModule> {
        return {
            ngModule: MsiDatabaseModule,
            providers: [

                FirestoreService,
                DatabaseProvider,
                AvatarUploadService,
                UsersService,
                OrganizationsService,
                UploadsService,
               
                UploadsService,
                FileUploadService,
                MsiPatchedAngularFireFunctions,


            ],
        } as ModuleWithProviders<MsiDatabaseModule>;
    }
}

import { BfitDevice } from '@bfit/core/models/bfit/entities/bfit-device';

import { BfitContextBasedObject } from '../bfit-context.service';
import { ObjectReference } from '../../entities/ref';
import { BfitGoldImageModifyingData, BfitGoldImageRef } from './bfit-gold-image';
import { BfitDisk } from './bfit-disk';

import { BfitRole } from '@bfit/core/models/bfit/bfit-roles';
import { BfitUser } from './bfit-user';

/**
 * Virtual Desktops are created from a Gold Image published version. Their VM_ID points to the running ovirt virtual machine
 */

export interface AttachedDisk {
  id: string;
  name: string;
  uuid: string;
  status?: 'attaching' | 'attached' | 'detached';
}

export interface DiskAttachment {
  [name: string]: AttachedDisk;
}

export enum BfitComputerType {
  pcc = 'desktop',
  PromoPcc = 'promo-pcc',
  DataServer = 'data-server',
  goldImage = 'gold-image',
  internal = 'internal',
  VirtualFirewall = 'virtual-firewall',
  VpnProxy = 'vpn-proxy',
  Appliance = 'appliance',
  DemoPcc = 'demo',
}

export enum BfitComputerStatus {
  Init = 'initializing',
  Build = 'building',
  Up = 'up',
  Down = 'down',
  Paused = 'paused',
  Migate = 'migrating'
}

export enum BfitComputerMode {
  Stateless = 'managed',      // stateless
  Stateful = 'self managed',  // stateful
  Kiosk = 'kiosk',            // stateless             
}


export interface BfitComputerSettings {
  cpu: number;
  ram: number;
  monitors: number;
  bfit_gold_image_ref?: BfitGoldImageRef;
  goldImageOptions?: BfitGoldImageModifyingData;
  allowedRole_refs: ObjectReference<BfitRole>[];
  rerunWindowsRegistration: boolean;
}
export interface BfitComputerBuildStatus {
    status: 'completed' | 'started' | 'in-progress';
    message?: string;
    error?: string;
    jobRequest_id: string;

}
export interface BfitComputerOptions {
  force?: boolean;
  rebuildFromScratch?: boolean;
  apiVersion?: boolean;
  registerWindows?: boolean;
}
export interface BfitComputer extends BfitContextBasedObject {

  name: string;
  sequence: number;
  uuid: string;
  serial: string;
  description: string;

  cpu: number;
  ram: number;
  monitors: number;
  bfit_gold_image_ref?: BfitGoldImageRef;

  viewer: 'native' | 'web';

  user_disk: number;

  internal_name: string;

  photoURL: string;

  os: 'windows' | 'linux';
  type: BfitComputerType;
  mode: BfitComputerMode;

  icons: {
    large: string,
    small: string,
  };

  status: BfitComputerStatus;
  message?: string;
  lastConnect: Date;

  bfit_shared_user_ref?: ObjectReference<BfitUser>;

  bfit_vm_ref: {
    cloud_id: string;
    vm_uuid: string;
    org_uuid: string;
  };

  bfit_user_disk_ref?: ObjectReference<BfitDisk>;
 
  bfit_server_ref: ObjectReference<BfitDevice>;

  vm_id?: string;
  template_id: string;
  pool_id?: string;

  image?: string;
  
  
  // assigned_to?: string;

  applications: string[];

  stateless: boolean;

  attachedDisks: DiskAttachment;
  slanMacAddress?: string;
  lanIpAddress?: string;
  fqdn?: string;

  gateway_id?: string;  // device id of the gateway for this computer
  

  locked: boolean;

  build: BfitComputerBuildStatus;
  buildOptions: {
    attachUserDataDisk: boolean;
  }

  nextBoot?: Partial<BfitComputerSettings>;
}

import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { LoadingController, AlertController } from '@ionic/angular';
import { OVirtCluster } from '@ovirt/core/models/ovirt/entities/ovirt-cluster';


@Injectable({ providedIn: 'root' })
export class OVirtClusterProcessing {

    readonly backend = 'ovirtFn-clusters';

    constructor(
        private cloudService: AngularFireFunctions,
        private loadingCtrl: LoadingController,
        private alertCtrl: AlertController,
    ) { }


    async execute(action: string, cluster: OVirtCluster): Promise<any> {

        const loading = await this.loadingCtrl.create({ message: 'Please wait...' });
        await loading.present();

        try {

            const args = {
                cluster,
                action,
            };

            const result = await this.cloudService.httpsCallable(this.backend)(args).toPromise();

            loading.dismiss();

            // console.log('result ', args, result);
            return result;
        } catch (err) {
            await loading.dismiss();
            const alert = await this.alertCtrl.create({ message: `${err}` });
            return alert.present();
        }
    }

}

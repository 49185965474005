import { Component } from '@angular/core';

@Component({
    selector: 'app-reset-password-page',
    templateUrl: './reset-password.page.html',
    styleUrls: ['./reset-password.page.scss']
})
export class ResetPasswordPage  {
   
}



import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';
import { OVirtVirtualMachine } from './entities/ovirt-vm';

import { BfitUser } from '@bfit/core/models/bfit/entities/bfit-user';
import { of, Observable } from 'rxjs';
import { CloudContextService } from './ovirt-context.service';
import { OVirtDataCenter } from './ovirt-hosts';
import { OVirtTemplate } from './entities/ovirt-template';
import { switchMap } from 'rxjs/operators';

export * from './entities/ovirt-vm';


@Injectable({ providedIn: 'root' })
export class OVirtVmsService extends CloudContextService<OVirtVirtualMachine> {

  constructor(db: FirestoreService) {
    super('ovirt_vms', db);
  }

  getDesktopsForUser(user: BfitUser): Observable<OVirtVirtualMachine[]> {
    // FIXME: return user ? this.getAll(ref => ref.where('bfit_user_ref.id', '==', user.id)) : of([]);
    return user ? this.getAll(ref => ref.where('type', '==', 'desktop')) : of([]);
  }

  findAllForDataCenter(dc: OVirtDataCenter): Observable<OVirtVirtualMachine[]> {
    return dc ? this.getAll(ref => ref.where('dataCenter_id', '==', dc.id)) : of([]);
  }

  findAllForTemplate(template: OVirtTemplate): Observable<OVirtVirtualMachine[]> {
    return template ? this.getAll(ref => ref.where('template.id', '==', template.id)) : of([]);
  }

  findAllUnassigned(prefix: string): Observable<OVirtVirtualMachine[]> {
    return this.getAll(ref => ref.where('name', '>=', prefix + '-').where('name', '<', prefix + '-Z')).pipe(
      switchMap(list => {
        return of(list.filter(vm => !vm.bfit_org_ref));
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { LoadingController, AlertController } from '@ionic/angular';
import { OVirtTemplate, BfitTemplateType } from '@ovirt/core/models/ovirt/entities/ovirt-template';
import { ObjectReference } from '@bfit/core/models/entities/ref';
import { BfitOrganization } from '@bfit/core/models/bfit/bfit-organizations';

@Injectable({ providedIn: 'root' })
export class OVirtTemplateProcessing {

    readonly backend = 'ovirtFn-templates';

    constructor(
        private cloudService: AngularFireFunctions,
        private loadingCtrl: LoadingController,
        private alertCtrl: AlertController,
    ) { }

    async onAssignOrg(template: OVirtTemplate, orgRef: ObjectReference<BfitOrganization>): Promise<any> {

       /*  const result = await this.cloudService.httpsCallable(this.backend)({
            vm: template,
            action: 'assignOrg',
            orgRef
        }).toPromise();

        console.log('result assignOrg: ', result);
        return result; */

        return this.execute({
            template,
            action: 'assignOrg',
            orgRef
        });
    }

    async onChangeType(template: OVirtTemplate, type: BfitTemplateType): Promise<any> {

       /*  const loading = await this.loadingCtrl.create();
        await loading.present();

        try {
            const result = await this.cloudService.httpsCallable(this.backend)({
                vm: template,
                action: 'changeType',
                type
            }).toPromise();

            loading.dismiss();

            console.log('result changeType: ', result);
            return result;
        } catch (err) {
            await loading.dismiss();
            const alert = await this.alertCtrl.create({ message: `${err}` });
            return alert.present();
        } */

        return this.execute({
            template,
            action: 'changeType',
            type
        });
    }

    async execute(args: any): Promise<any> {

        const loading = await this.loadingCtrl.create({ message: 'Please wait...' });
        await loading.present();

        try {
            const result = await this.cloudService.httpsCallable(this.backend)(args).toPromise();

            loading.dismiss();

            // console.log('result ', args, result);
            return result;
        } catch (err) {
            await loading.dismiss();
            const alert = await this.alertCtrl.create({ message: `${err}` });
            return alert.present();
        }
    }

}

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Subscription, timer } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
    selector: 'app-idle-timeout-modal',
    templateUrl: './idle-timeout.modal.html',
    styleUrls: [ './idle-timeout.modal.scss' ]
})
export class IdleTimeoutModal implements OnInit, OnDestroy {

    secondsLeft: number = 45;
    timer$: Subscription = null;

    constructor(
        private modalCtrl: ModalController,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {

        timer(1000, 1000).pipe(
            tap ((sec: number) => {
                this.secondsLeft = 45 - sec;
                this.cdr.markForCheck();
                if (this.secondsLeft <= 0) {
                    this.modalCtrl.dismiss(false);
                }
            })
        ).subscribe();
     }

     async logout(): Promise<void> {
         await this.modalCtrl.dismiss(false);
     }

     async continue(): Promise<void> {
         await this.modalCtrl.dismiss(true);
     }

     ngOnDestroy(): void {
         if (this.timer$) {
             this.timer$.unsubscribe();
             this.timer$ = null;
         }
     }

}
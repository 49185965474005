

import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { BfitDisk } from './entities/bfit-disk';

import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { BfitUser } from './entities/bfit-user';
import { first } from 'lodash';
import { DBService } from '@msi/core/dao/db';
import { BfitOrganization } from './bfit-organizations';

export * from './entities/bfit-disk';

@Injectable({ providedIn: 'root' })
export class BfitDisksService extends DBService<BfitDisk> {

    constructor(db: FirestoreService) {
        super('bfit-disks', db);
        this.patchDefaults({

        });
    }

    findAllForOrganization(org: BfitOrganization): Observable<BfitDisk[]> {
        return org ? super.getAll(ref => ref.where('bfit_org_ref.id', '==', org.id)) : of([]);
    }

    getForUser(user: BfitUser): Observable<BfitDisk> {
        if (!user) {
            return of(null);
        }

        return this.getAll(ref => ref.where('bfit_user_ref.id', '==', user.id)).pipe(
            switchMap(list => of(first(list)))
        );
    }

    findAllAvailableToUser(org: BfitOrganization, user: BfitUser): Observable<BfitDisk[]> {
        return this.findAllForOrganization(org).pipe(
            // FIXME: We need to check with the user role
            map(disks => disks.filter(disk => disk.bfit_user_ref?.id === user.id && !disk.bfit_computer_ref))
        );
    }
}

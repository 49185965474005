import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPage } from './login.page';
import { IonicModule } from '@ionic/angular';
import { MsiAuthLoginFormModule } from '@msi/ionic/ui/auth/forms/login/login.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MsiImageSliderModule} from '@msi/ionic/ui/image-slider/image-slider.module';

@NgModule({
    declarations: [
        LoginPage
    ],
    imports: [
        CommonModule,
        IonicModule,
        FlexLayoutModule,

        MsiAuthLoginFormModule,
        MsiImageSliderModule
        // RouterModule.forChild([{
        //     path: '',
        //     component: LoginPage
        // }])
    ],
    exports: [
        LoginPage
    ],
    providers: [],
})
export class LoginPageModule { }

import { DBService } from '@msi/core/dao/db';
import { BfitAccount } from './entities/bfit-account';
import { FirestoreService } from '@msi/core/dao/firebase/firestore';
import { Injectable } from '@angular/core';

export * from './entities/bfit-account';

@Injectable({ providedIn: 'root' })
export class BfitAccountsService extends DBService<BfitAccount> {
    constructor(db: FirestoreService) {
        super('accounts', db);
    }
}

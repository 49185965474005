import { BfitComputerOptions } from '../models/bfit/entities/bfit-computer';
import { BfitComputer } from '../models/bfit/bfit-computers';
import { BfitGoldImage } from '../models/bfit/bfit-gold-images';


const topic = '[computer]';
export class BfitComputerStart {
    static readonly type: string = `${topic} start`;
    constructor(public computer: BfitComputer, public options: BfitComputerOptions) { }
}

export class BfitComputerRestart {
    static readonly type: string = `${topic} restart`;
    constructor(public computer: BfitComputer, public options: BfitComputerOptions) { }
}

export class BfitComputerRequestStart {
    static readonly type: string = `${topic} request start`;
    constructor(public computer: BfitComputer, public availableGoldImages: BfitGoldImage[]) { }
}

export class BfitComputerRequestRestart {
    static readonly type: string = `${topic} request restart`;
    constructor(public computer: BfitComputer) { }
}

export class BfitComputerRequestRestore {
    static readonly type: string = `${topic} request restore`;
    constructor(public computer: BfitComputer, public options: BfitComputerOptions) { }
}

export class BfitComputerRequestPowerOff {
    static readonly type: string = `${topic} request power off`;
    constructor(public computer: BfitComputer) { }   
}

export class BfitComputerShutdown {
    static readonly type: string = `${topic} shutdown`;
    constructor(public computer: BfitComputer, public options: BfitComputerOptions) { }
}

export class BfitComputerPause {
    static readonly type: string = `${topic} pause`;
    constructor(public computer: BfitComputer, public options: BfitComputerOptions) { }
}


export class BfitComputerOpen {
    static readonly type: string = `${topic} open`;
    constructor(public computer: BfitComputer, public options: BfitComputerOptions) { }
}


export class BfitComputerRestore {
    static readonly type: string = `${topic} restore`;
    constructor(public computer: BfitComputer, public options: BfitComputerOptions) { }
}

export class BfitComputerUpgrade {
    static readonly type: string = `${topic} upgrade`;
    constructor(public computer: BfitComputer, public options: BfitComputerOptions) { }
}

export class BfitComputerCheckUpgrade {
    static readonly type: string = `${topic} check upgrade`;
    constructor(public computer: BfitComputer, public action: any | any[]) { }
}
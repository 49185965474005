import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatTabsModule } from '@angular/material/tabs';
import { MsiFaqResponsePage } from './faq-response.page';
import { MsiFaqComponentsModule } from '@msi/faq/components/faq-components.module';
import { SafePipeModule } from 'safe-pipe';
import { AppFeedbackModule } from '@bfit/ionic/components/feedback/feedback.module';
import { MomentModule } from 'ngx-moment';
import { MsiPipesModule } from '@msi/core/pipes/pipes.module';
import { AppSocialShareModule } from '@bfit/ionic/components/social-share/social-share.module';

@NgModule({
    declarations: [
        MsiFaqResponsePage
    ],
    imports: [
        CommonModule,
        IonicModule,
        MatTabsModule,
        MsiFaqComponentsModule,
        SafePipeModule,
        AppFeedbackModule,
        AppSocialShareModule,
        MomentModule,
        MsiPipesModule
    ],
    exports: [
        MsiFaqResponsePage,
        MsiFaqComponentsModule,
    ],
})
export class MsiFaqResponsePageModule { }

<ion-header>
  <ion-toolbar>

    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>

    <ion-title>My Profile</ion-title>

  </ion-toolbar>
</ion-header>


<ion-content fullscreen class="ion-padding">

  <div class="page_container" fxLayout="row" fxLayoutAlign="center">

    <div class="msi-card edit-profile-card">
      <div class="msi-card-header">
        <h1>Edit Profile</h1>
        <div class="msi-card-close-icon" (click)="cancel()">
          <ion-icon name="close-outline"></ion-icon>
        </div>
      </div>
      <div class="msi-card-body">
        <form [formGroup]="profileForm" autocomplete="disabled" novalidate>
          <formly-form [model]="changes" [fields]="formConfig" [form]="profileForm" [options]="formOptions">

          </formly-form>
        </form>
      </div>

      <div class="msi-card-footer" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px">
        <ion-button color="light" (click)="cancel()">Cancel</ion-button>
        <ion-button class="ml10" color="primary" [disabled]="!profileForm || profileForm?.invalid" (click)="save(changes)">Save
          changes
        </ion-button>
      </div>

      <!-- <pre>
        {{ profileForm.value | json }}
    </pre> -->
    </div>
  </div>
</ion-content>
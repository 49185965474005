import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetPasswordPage } from './reset-password.page';
import { IonicModule } from '@ionic/angular';
import { MsiAuthResetPasswordFormModule } from '@msi/ionic/ui/auth/forms/reset-password/reset-password.module';

@NgModule({
    declarations: [
        ResetPasswordPage
    ],
    imports: [ 
        CommonModule,
        IonicModule,
        MsiAuthResetPasswordFormModule,
    ],
    exports: [
        ResetPasswordPage
    ],
    providers: [
        ResetPasswordPage
    ],
})
export class ResetPasswordPageModule {}

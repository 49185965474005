import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { EditSettingsPage } from "./edit-settings.page";
import { MsiFormlyModule } from '@msi/ionic/ui/formly/formly.module';

@NgModule({
    declarations: [
        EditSettingsPage
    ],
    imports: [
        CommonModule,
        IonicModule,
        MsiFormlyModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
    ],
    exports: [
        EditSettingsPage
    ]
})
export class EditSettingsModule {

}

import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-input-datetime',
    template: `
        <ion-item nolines>
            <ion-label position="stacked">{{ to.label }}</ion-label>
            <ion-datetime  [formControl]="formControl" [formlyAttributes]="field"
                [placeholder]="to.placeholder"
                [pickerOptions]="to.pickerOptions"
                [displayTimezone]="to.timezone" 
                [displayFormat]="to.displayFormat"
                [dayNames]="to.dayNames"
                [dayShortNames]="to.dayShortNames"
                [monthNames]="to.monthNames"
                [monthShortNames]="to.monthShortNames"
                [hourValues]="to.hourValues"
                [minuteValues]="to.minuteValues"
                [dayValues]="to.dayValues"
                [monthValues]="to.monthValues"
                [yearValues]="to.yearValues"
                [min]="to.min" [max]="to.max">
            </ion-datetime>
        </ion-item>
  `,
  styles: [
   ` ion-datetime {
        button {
          position: relative !important;
        }
      
        .datetime-text {
          overflow: visible;
        }
      
        flex: 1;
      }`
  ]
})
export class FormlyDateTimeTemplate extends FieldType {



}

import { Injectable } from "@angular/core";

import { Action, State, StateContext } from "@ngxs/store";
import { CurrentUserChangePassword , CurrentUserEditProfile, CurrentUserEditSettings, CurrentUserLogout } from "../actions/current-user.actions";
import { NavigateForward } from '@msi/core/ngxs/ionic-router/ionic-router.actions';
import { MsiAuthLogout } from "@msi/ionic/core/auth/actions/auth.actions";
interface StateModel {

}

@State<StateModel>({
    name: 'appCurrentUserState',
})
@Injectable({ providedIn: 'root' })
export class AppCurrentUserState {

    @Action(CurrentUserEditProfile)
    requestEditProfile(ctx: StateContext<StateModel>): void {
        
        ctx.dispatch(new NavigateForward('/auth/profile'));
    } 

    @Action(CurrentUserChangePassword)
    requestChangePassword(ctx: StateContext<StateModel>): void {
        ctx.dispatch(new NavigateForward('/auth/change-password'));
    } 

    @Action(CurrentUserEditSettings)
    requestEditSettings(ctx: StateContext<StateModel>): void {
        ctx.dispatch(new NavigateForward('/auth/edit-settings'));
    }

    @Action(CurrentUserLogout)
    requestLogout(ctx: StateContext<StateModel>): void {
        ctx.dispatch(new MsiAuthLogout());
    } 
}
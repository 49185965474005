import { Component, Input, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';

import { Validators, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { environment } from '@env';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'msi-account-input-credit-card',
    templateUrl: './credit-card.component.html',
    styleUrls: ['./credit-card.component.scss']
})
export class CreditCardInputComponent implements OnInit, AfterViewInit {

    @Input() editForm: FormGroup;
    
    messages: {
        validDate: string;
        monthYear: string;
    };

    placeholders: {
        number: string;
        name: string;
        expiry: string;
        cvc: string;
    };

    defaultCard = false;

    masks: {
        cardNumber: string;
    };

    debug: boolean = environment.debug.creditCardEntry;
    ready$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {

        if (!this.editForm.get('number')) {
            this.editForm.setControl('number', new FormControl('', [Validators.required]));
            this.editForm.setControl('nameOnCard', new FormControl('', [Validators.required]));
            this.editForm.setControl('expiry', new FormControl('', [Validators.required]));
            this.editForm.setControl('cvc', new FormControl('', [Validators.required]));
        }

        this.messages = {
            validDate: 'valid\ndate',
            monthYear: 'mm/yyyy',
        };

        this.placeholders = {
            number: '**** **** **** ****',
            name: 'YOUR NAME',
            expiry: '**/****',
            cvc: '***'
        };

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.ready$.next(true);
            this.cdr.detectChanges();
        }, 500);
        
       
        
    }

    get nameOnCard(): AbstractControl { return this.editForm.get('nameOnCard'); }
    get number(): AbstractControl { return this.editForm.get('number'); }
    get expiry(): AbstractControl { return this.editForm.get('expiry'); }
    get cvc(): AbstractControl { return this.editForm.get('cvc'); }

}

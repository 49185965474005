

import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { BfitConsumer } from './entities/bfit-consumer';
import { Observable, of } from 'rxjs';
import { BfitOrganizationsService, BfitOrganization, BfitOrganizationType } from './bfit-organizations';

export * from './entities/bfit-consumer';

@Injectable({ providedIn: 'root' })
export class BfitConsumersService extends BfitOrganizationsService {
   

    constructor(db: FirestoreService) {
        super(db);
        this.patchDefaults({ self_managed: false, type: BfitOrganizationType.Consumer });
    }

    findAllForOrganization(org: BfitOrganization): Observable<BfitConsumer[]> {
        return org ? super.getAll(ref => ref.where('bfit_provider_ref.id', '==', org.id)) : of([]);
    }

    newInstance(data: Partial<BfitConsumer>): BfitConsumer {
        const result = super.newInstance({  ...data });
        return result as BfitConsumer;
    }

}

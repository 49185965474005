import { Injectable } from '@angular/core';
import { FirestoreService } from '@msi/core/dao/firebase/firestore';
import { OrgBasedDbService } from '@msi/core/dao/services/org-based-db.service';
import { Observable } from 'rxjs';
import { BfitExternalRequest, BfitExternalRequestStatus } from './entities/bfit-external-request';

export * from './entities/bfit-external-request';

@Injectable({ providedIn: 'root' })
export class BfitExternalRequestsService extends OrgBasedDbService<BfitExternalRequest> {

    constructor(db: FirestoreService) {
    super('bfit-external-requests', db);

  }

  findAllCreated(): Observable<BfitExternalRequest[]> {
    return this.getAll(ref => ref.where('status', '==', BfitExternalRequestStatus.created));
  }

}
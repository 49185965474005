<div class="context-help" fxLayout="column" fxLayoutAlign="space-between">

  <!-- <ion-card-header fxLayout="row" fxLayoutAlign="space-between center">
    <h4>Context Help</h4>
    <ion-menu-toggle [autoHide]="false" [menu]="menuId">
      <ion-icon name="close"></ion-icon>
    </ion-menu-toggle>
    
  </ion-card-header> -->

    <ion-menu-toggle [autoHide]="false" [menu]="menuId">
      <ion-icon name="close"></ion-icon>
    </ion-menu-toggle>

    <msi-faq-mini-search-box></msi-faq-mini-search-box>

    <ion-note>
      <strong>Didn't find what you need?</strong><br/><br/>

      check our <a href="/faq">frequently ask questions</a> or
      open a support ticket at: <a href="mailto:helpdesk@itsupportmsp.com">helpdesk@itsupportmsp.com</a><br/>
      or leave a message at: <a href="tel:+19493961999">949-396-1999</a>
    </ion-note>

 
</div>
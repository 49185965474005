import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AvatarDisplayComponent } from './display/avatar.component';
import { AvatarInputComponent } from './input/avatar-input.component';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    declarations: [
        AvatarDisplayComponent,
        AvatarInputComponent,
    ],
    imports: [ 
        CommonModule,
        MatCardModule,
    ],
    exports: [
        AvatarDisplayComponent,
        AvatarInputComponent,
    ],
    providers: [],
})
export class MsiAvatarComponentModule {}

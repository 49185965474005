import { IdentityObject } from '@msi/core/dao/db';

export enum CatalogSearchTypes {
    category = 'Search Categories',
    product = 'Search Products',
    plan = 'Search Plans',
    catalog = 'Search Catalogs'
}

export enum CatalogDraggableAssets {
    category = 'Categories',
    product = 'Products',
    plan = 'Plans',
    media = 'Media'
}

export interface CatalogLayoutColumn {
    col: number;
    title?: string;
    subtitle?: string;
    description?: string;
    image?: string;
    type: 'category' | 'product' | 'plan' | 'catalog' | null;
    other?: any;
    ref_id?: string;
}

export interface CatalogLayoutRow {
    type: 'row';
    row: number;
    columns?: CatalogLayoutColumn[];
}

export interface CatalogLayoutHero {
    type: 'hero';
    row: number;
    photoURL?: string;

    title?: string;
    subtitle?: string;
    description?: string;

    columns?: CatalogLayoutColumn[];
}

export type CatalogLayout = CatalogLayoutHero | CatalogLayoutRow;

export interface ShoppingCatalog extends IdentityObject {
    id: string;
    name: string;
    photoURL: string;
    sections: CatalogLayout[];
}

import { Component, Input, SimpleChange, OnInit, OnChanges } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SidemenuHeaderOptions } from '../../typedefs/sidemenu-options';
import { MenuController } from '@ionic/angular';


@Component({
  selector: 'msi-sidemenu-header',
  templateUrl: 'menu-header.html',
  styleUrls: [ './menu-header.scss' ]

})
export class SidemenuHeaderComponent implements OnInit, OnChanges {

  @Input() public options: SidemenuHeaderOptions;

    constructor(public menuCtrl: MenuController) {

    }

  ngOnInit(): void {
  }

  ngOnChanges(_changes: { [propName: string]: SimpleChange }): void {
  }

  @Dispatch()
  onClick  = () => {
      this.menuCtrl.close();
      return this.options.dispatch;
  }
}



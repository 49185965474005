import { Routes } from '@angular/router';
import { LoginPage } from './pages/login/login.page';
import { ChangePasswordPage } from './pages/change-password/change-password.page';
import { ResetPasswordPage } from './pages/reset-password/reset-password.page';
import { ProfilePage } from './pages/profile/profile';
import { RegisterPage } from '@bfit/ionic/auth/pages/register/register';
import { TwoFactorAuthPage } from './pages/two-factor-auth/two-factor-auth.page';
import { EditSettingsPage } from './pages/edit-settings/edit-settings.page';

export const routes: Routes = [
    {
        path: 'login',
        component: LoginPage
    },
    {
        path: 'logout',
        component: LoginPage,
    },
    {
        path: 'register',
        component: RegisterPage
    },
    {
        path: 'forgot-password',
        component: ResetPasswordPage
    },
    {
        path: 'change-password',
        component: ChangePasswordPage
    },
    {
        path: 'reset-password',
        component: ResetPasswordPage
    },
    {
        path: 'profile',
        component: ProfilePage
    },
    {
        path: 'edit-settings',
        component: EditSettingsPage,
    },

    {
        path: '2fa',
        component: TwoFactorAuthPage,
    }


];

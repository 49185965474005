import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

import PlaceResult = google.maps.places.PlaceResult;
import { Location } from '@angular-material-extensions/google-maps-autocomplete';

@Component({
    selector: 'formly-input-address',
    template: `
        <mat-google-maps-autocomplete 
            [address]="address"
            [appearance]="to.appearance"
            [addressLabelText]="to.label"
            autocomplete="off"
            type="address"
            [country]="to.country || 'us'"
            [placeholderText]="to.placeholder"
            (onChange)="onChange($event)"
            (onAutocompleteSelected)="onAutocompleteSelected($event)"
            (onLocationSelected)="onLocationSelected($event)">
    </mat-google-maps-autocomplete>
    `,
        styles: [
            'input { border: none; width: 100%; }',

        ]
})
export class FormlyInputAddressTemplate extends FieldType implements OnInit   {

    latitude: number;
    longitude: number;
    address: string;

    /*     
    private setCurrentPosition() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
            });
        }
    } 
    */

    ngOnInit(): void {
        this.address = this.formControl.value;
    }

    onChange(result: string | PlaceResult): void {
        // console.log('onChange: ', result);
        this.formControl.setValue(typeof (result) === 'string' ? result : result.formatted_address);
    }

    onAutocompleteSelected(result: PlaceResult): void {
        // console.log('onAutocompleteSelected: ', result);
        this.formControl.setValue(result.formatted_address); 
    }

    onLocationSelected(location: Location): void {
        // console.log('onLocationSelected: ', location);
        this.latitude = location.latitude;
        this.longitude = location.longitude;
    }



}



import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';
import { BfitMasterImage } from './entities/bfit-master-image';

import { BfitContextService } from './bfit-context.service';

export * from './entities/bfit-master-image';


@Injectable({ providedIn: 'root'})
export class BfitMasterImagesService extends BfitContextService<BfitMasterImage> {

  constructor(db: FirestoreService) {
    super('bfit_master_images', db);
    this.patchDefaults({
      availability: {}
    });
  }

}

import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { first, map, switchMap, tap } from 'rxjs/operators';

import { database } from 'firebase/app';

import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PresenceService {

    constructor(private afAuth: AngularFireAuth, private db: AngularFireDatabase) {

        afAuth.auth.setPersistence('session');
        this.updateOnUser().subscribe();
        this.updateOnDisconnect().subscribe();
        this.updateOnAway();
    }


    getPresence(uid: string): Observable<any> {
        return this.db.object(`status/${uid}`).valueChanges();
    }

    getUser(): Promise<any> {
        return this.afAuth.authState.pipe(first()).toPromise();
    }


    async setPresence(status: string): Promise<void> {
        const user = await this.getUser();
        if (user) {
            return this.db.object(`status/${user.uid}`).update({ status, timestamp: this.timestamp });
        }
    }

    get timestamp(): any {
        return database.ServerValue.TIMESTAMP;
    }

    // Updates status when logged-in connection to Firebase starts
    updateOnUser(): Observable<string> {
        const connection = this.db.object('.info/connected').valueChanges().pipe(
            map(connected => connected ? 'online' : 'offline')
        );

        return this.afAuth.authState.pipe(
            switchMap(user => user ? connection : of('offline')),
            tap(status => this.setPresence(status))
        );
    }

    updateOnDisconnect(): Observable<any> {
        return this.afAuth.authState.pipe(
            tap(user => {
                if (user) {
                    this.db.object(`status/${user.uid}`).query.ref.onDisconnect()
                        .update({
                            status: 'offline',
                            timestamp: this.timestamp
                        });
                }
            })
        );
    }

    // User navigates to a new tab, case 3
    updateOnAway(): void {
        document.onvisibilitychange = (_e) => {

            if (document.visibilityState === 'hidden') {
                this.setPresence('away');
            } else {
                this.setPresence('online');
            }
        };
    }

    async signOut(): Promise<void> {
        await this.setPresence('offline');
        await this.afAuth.auth.signOut();
    }
}

<div class="modal_wrapper">
    <div class="modal_header" fxLayout="row" fxLayoutAlign="space-between">
        <h3>You have been idle for too long! </h3>
        <ion-icon (click)="logout()" name="close-outline"></ion-icon>
    </div>
    <div class="modal_body">
       <h3>Your session will expire in {{ secondsLeft| number }} seconds. Do you want to extend your session?</h3>
    </div>
    <div class="modal_footer">
      
        <ion-buttons>
            <ion-button color="danger" (click)="logout()">LOGOUT</ion-button>
            <ion-button color="primary" (click)="continue()">CONTINUE SESSION</ion-button>
        </ion-buttons>
    </div>
</div>
import { MsiPatchedAngularFireFunctions } from './../../../../core/dao/services/patched-cloud-function';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth, User } from 'firebase/app';


@Injectable({ providedIn: 'root' })
export class MsiAuthLoginService {

  private authToken: string;

  constructor(
    private afAuth: AngularFireAuth,
    private cloudFunction: MsiPatchedAngularFireFunctions,
  ) { }

  async anonymousLogin(): Promise<User> {
    return this.afAuth.auth.signInAnonymously().then(credential => {
      return credential.user;
    });
  }

  async signInWithEmailAndPassword(email: string, password: string): Promise<User> {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password).then (credential => {
      // console.log ('sign in with email and password: ', credential);
      return credential.user;
    });
  }

  async signInWithPhoneNumber(phoneNumber: string, appVerifier: auth.ApplicationVerifier): Promise<auth.ConfirmationResult | any> {

    return this.afAuth.auth.signInWithPhoneNumber(phoneNumber, appVerifier);

  }

  async googleLogin(): Promise<User> {
    const provider = new auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
  }

  async facebookLogin(): Promise<User> {
    // TODO: Add facebook
    const provider = new auth.FacebookAuthProvider();
    return this.oAuthLogin(provider);
  }

  async twitterLogin(): Promise<User> {
    // TODO: Add twitter login
    const provider = new auth.TwitterAuthProvider();
    return this.oAuthLogin(provider);
  }

  async phoneLogin(): Promise<User> {
    // TODO: Add Phone login
    const provider = new auth.PhoneAuthProvider();
    return this.oAuthLogin(provider);
  }

  async logout(): Promise<any> {
    return this.afAuth.auth.signOut();
  }

  private async oAuthLogin(provider: auth.AuthProvider): Promise<User> {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((credential) => {
        return credential.user;
      });
  }

  async request2FA(org_id: string, useSMS: boolean): Promise<void> {

    this.authToken = await this.cloudFunction.httpsCallable('authFn-request2FA')({ org_id, useSMS }).toPromise();
  }

  async verify2FA(org_id: string, pin: string): Promise<{ verified: boolean, msg?: string }> {
    const result = await this.cloudFunction.httpsCallable('authFn-verify2FA')({ org_id, pin, token: this.authToken }).toPromise();
    return result as any;
  }
}

import { CloudContextService } from './ovirt-context.service';
import { OVirtEvent } from './entities/ovirt-event';
import { FirestoreService } from '@msi/core/dao/firebase/firestore';
import { Injectable } from '@angular/core';


export * from './entities/ovirt-event';


@Injectable({ providedIn: 'root' })
export class OVirtEventsService extends CloudContextService<OVirtEvent> {

    constructor(db: FirestoreService) {
        super('ovirt_events', db);
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MsiImageSliderComponent } from './image-slider.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
export interface ImageSliderConfigModel {
    slidesToShow: number;
    slidesToScroll: number;
    arrows?: boolean;
    dots?: boolean;
    autoplay: boolean;
    autoplaySpeed: number;
    infinite: boolean;
    centerMode?: boolean;
    variableWidth?: boolean;
}
@NgModule({
    declarations: [
        MsiImageSliderComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        SlickCarouselModule
    ],
    exports: [
        MsiImageSliderComponent
    ]
})
export class MsiImageSliderModule { }

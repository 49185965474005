<!-- <ion-content fullscreen class="auth_main_wrapper">
  <div class="flex auth_inner_wrapper">
    <div class="left_column_auth column">
      <msi-auth-register-form></msi-auth-register-form>
    </div>
    <div class="right_column_auth column">
      <img alt="logo" src="/assets/img/app-logo/butterflyIT_Logo.svg"/>
    </div>
  </div>
</ion-content> -->

<div class="register_header_wrapper">
	<h1>Sign Up</h1>
</div>

<ion-content class="ion-no-padding">
	<div class="page_container">
		<div class="register_page_wrapper">
			<div class="register_section">
				<section class="register_form">
					<div class="contact_form">
            <msi-auth-register-form></msi-auth-register-form>
					</div>
				</section>
			</div>
		</div>
	</div>
</ion-content>

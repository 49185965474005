import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { DesktopSettingsComponent } from './desktop-settings.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
    declarations: [
        DesktopSettingsComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        FontAwesomeModule,
    ],
    exports: [
        DesktopSettingsComponent,
    ],
    providers: [],
})
export class DesktopSettingsComponentModule { }

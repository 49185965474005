import { NgModule } from '@angular/core';
import { ResetPasswordFormComponent } from './reset-password';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [
      ResetPasswordFormComponent,
  ],
  imports: [

    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    FlexLayoutModule,
  ],
  exports: [
      ResetPasswordFormComponent
  ]
})
export class MsiAuthResetPasswordFormModule {}

import { CloudContextService } from './ovirt-context.service';
import { OVirtAffinityGroup } from './entities/ovirt-affinity-group';
import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { OVirtCluster } from './entities/ovirt-cluster';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class OVirtAffinityGroupsService extends CloudContextService<OVirtAffinityGroup> {

    constructor(db: FirestoreService) {
        super('ovirt_affinity_groups', db);
    }

    findAllForCluster(cluster: OVirtCluster): Observable<OVirtAffinityGroup[]> {
        return cluster ? this.getAll(ref => ref.where('cluster.id', '==', cluster.id)) : of([]);
    }

}
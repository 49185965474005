import { ObjectReference } from '@bfit/core/models/entities/ref';
import { BfitOrganization } from '@bfit/core/models/bfit/entities/bfit-organization';
import { BfitUser } from '@bfit/core/models/bfit/entities/bfit-user';
import { DataCenterContextBasedObject } from '../ovirt-context.service';

export enum BfitTemplateType {
    internal = 'internal',
    goldImage = 'gold-image',
    serverImage = 'server-image',
    masterSeed = 'master-seed',
}

export interface OVirtTemplate extends DataCenterContextBasedObject {

    href: string;
    name: string;
    internal_name: string;

    origin: string;

    bios: {
        boot_menu: {
            enabled: boolean
        };
        type: string;
    };
    cluster: {
        href: string;
        id: string;
    };

    cpu: {
        architecture: string;
        topology: {
            cores: number;
            sockets: number;
            threads: number;
        }
    };

    cpu_shares: number;
    creation_time: number;
    delete_protected: boolean;

    description: string;

    display: {
        allow_override: boolean;
        copy_paste_enabled: boolean;
        disconnect_action: string;
        file_transfer_enabled: boolean;
        single_qxl_pci: boolean;
        smartcard_enabled: boolean;
        type: string;
    };

    io: {
        threads: number;
    };

    memory: number;
    memory_policy: {
        auto_converge: string;
        max: number;
    };

    time_zone: {
        name: string;
    };

    type: string;

    usb: {
        enabled: boolean;
        type: string;
    };

    start_paused: boolean;
    stateless: boolean;
    status: string;
    storage_error_resume_behaviour: string;

    version: {
        base_template: {
            href: string;
            id: string;
        };
        version_name: string;
        version_number: number;
    };

    comment: string;

    dataCenter_id: string;
    bfit_type: BfitTemplateType;
    bfit_org_ref: ObjectReference<BfitOrganization>;
    bfit_user_ref: ObjectReference<BfitUser>;
}

import { State, NgxsOnInit, StateContext, Selector } from '@ngxs/store';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { Injectable } from '@angular/core';

export interface Breadcrumb {
    label: string;
    url: string;
}

interface StateModel {
    paths: Breadcrumb[];
}

const DEFAULTS: StateModel = {
    paths: []
};

@State<StateModel>({
    name: 'msiBreadcrumbState',
    defaults: DEFAULTS
})
@Injectable({ providedIn: 'root' })
export class MsiBreadcrumbState implements NgxsOnInit {

    @Selector()
    static getPaths(_state: StateModel): Breadcrumb[] {
        // FIXME: return state.paths;
        return [];
    }

    constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

    ngxsOnInit(ctx: StateContext<StateModel>): void {

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .pipe(map(() => this.activatedRoute))
            .subscribe(() => {
                ctx.patchState({ paths: this.createBreadcrumbs(this.activatedRoute.root) });
            });
    }

    private createBreadcrumbs(route: ActivatedRoute, url: string = '#', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
        const children: ActivatedRoute[] = route.children;

        if (children.length === 0) {
            return breadcrumbs;
        }

        for (const child of children) {
            const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
            if (routeURL !== '') {
                url += `/${routeURL}`;
            }

            const label = child.snapshot.data && child.snapshot.data.breadcrumb;
            if (!isNullOrUndefined(label)) {
                breadcrumbs.push({ label, url });
            }

            return this.createBreadcrumbs(child, url, breadcrumbs);
        }
    }

}

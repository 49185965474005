import { Component, OnInit } from '@angular/core';

import { Select } from '@ngxs/store';
import { BfitAppState } from '@bfit/core/states/app.state';
import { Observable } from 'rxjs';

import { AppConfig } from '@bfit/core/interfaces/app-config';
import { AppMenu } from '@msi/ionic/ui/side-menu/typedefs/sidemenu-options';

import { MsiAuthState } from '@msi/ionic/core/auth/states/auth.state';
import { ContextHelpState } from '@bfit/ionic/context-help/store/context-help.state';

// import { DOCUMENT } from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
// tslint:disable-next-line: component-class-suffix
export class BFitClientApp implements OnInit {

  @Select(BfitAppState.getConfig) config$: Observable<AppConfig>;
  @Select(BfitAppState.getMenu) menu$: Observable<AppMenu>;
  @Select(MsiAuthState.authenticated) authenticated$: Observable<boolean>;
  @Select(ContextHelpState.sidebarOpen) contextHelpOpen$: Observable<boolean>;

  constructor(
  // @Inject(DOCUMENT) private _document
  ) { }

  ngOnInit() {


  }

  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   if (document.body.scrollTop > 20 ||
  //     document.documentElement.scrollTop > 20) {
  //     document.getElementById('subTitle').classList.add('scrolled');
  //   }
  //   const offset = window.pageYOffset || this._document.documentElement.scrollTop || this._document.body.scrollTop || 0;
  //   this._document.getElementById('body').classList.toggle('scrolled', offset > 0);
  // }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';


import { CardModule } from 'ngx-card';
import { CreditCardDisplayComponent } from './display/credit-card.component';
import { CreditCardInputComponent } from './input/credit-card.component';
import { IonicModule } from '@ionic/angular';
@NgModule({
    declarations: [
        CreditCardInputComponent,
        CreditCardDisplayComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,

        CardModule,
        FlexLayoutModule,
    ],
    exports: [
        CreditCardInputComponent,
        CreditCardDisplayComponent,
    ],
    providers: [],
})
export class MsiCreditCardComponentModule { }

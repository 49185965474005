import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChangePasswordFormComponent } from './change-password';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        ChangePasswordFormComponent
    ],
    imports: [ 
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
    ],
    exports: [
        ChangePasswordFormComponent,
    ],
    providers: [],
})
export class MsiAuthChangePasswordFormModule {}

<div class="page_container">
    <ion-list class="card-grid">

        <ion-virtual-scroll
            [items]="list | filterBy: [ 'name', 'code', 'displayName', 'phone', 'email', 'contact' ]: filterText | chunk: cols"
            [trackBy]="trackById"
            approxItemHeight="170px">

            <ion-item class="row ion-no-padding" *virtualItem="let row" lines="none">
                <ion-item-sliding *ngFor="let card of row">

                    <!-- <ion-item-options side="start">
                        <ion-item-option color="danger" expandable (click)="delete.emit(card)" lines="none">
                            Delete
                        </ion-item-option>
                    </ion-item-options> -->

                    <ion-item lines="none" class="ion-no-padding">
                        <msi-contact-card [card]="card" (click)="select.emit(card)" [style.max-width]="style | safe: 'style'">
                        </msi-contact-card>
                    </ion-item>

                   <!--  <ion-item-options side="end">
                        <ion-item-option color="warning" expandable>
                            Disable
                        </ion-item-option>
                    </ion-item-options> -->

                </ion-item-sliding>
            </ion-item>

        </ion-virtual-scroll>

    </ion-list>
</div>
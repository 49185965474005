<div class="page_container">
    <ion-list class="card-grid">

        <ion-virtual-scroll *ngIf="list.length > 0"
                [trackBy]="trackById"
                [items]="list | chunk: cols"
                [approxItemHeight]="rowHeight + 'px'">

            <ion-item class="row" *virtualItem="let row" lines="none">
                <ion-item-sliding *ngFor="let card of makeGenericCards(row)">

                    <!-- <ion-item-options side="start">
                        <ion-item-option color="danger" expandable (click)="delete.emit(card)" lines="none">
                            Delete
                        </ion-item-option>
                    </ion-item-options> -->

                    <ion-item lines="none" class="ion-no-padding" (click)="onSelectCard(card, $event)" >
                        <msi-generic-card [card]="card" [style.max-width]="style | safe: 'style'">
                        </msi-generic-card>
                    </ion-item>

                   <!--  <ion-item-options side="end">
                        <ion-item-option color="warning" expandable>
                            Disable
                        </ion-item-option>
                    </ion-item-options> -->

                </ion-item-sliding>
            </ion-item>

        </ion-virtual-scroll>

    </ion-list>
</div>

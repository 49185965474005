import { Pipe, PipeTransform } from '@angular/core';
import { Name } from '@msi/core/dao/models/name';

@Pipe({name: 'displayName'})
export class DisplayNamePipe implements PipeTransform {

    transform(name: string | Name, _args: any[] = []): string {
        return name ?
            (typeof name === 'string') ? name :
                [ 
                    name.firstName,
                    name.middleName || '',
                    name.lastName
                ].join(' ') :
            '';
    }
}

import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { AgGridModule } from 'ag-grid-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MsiTextToggleModule } from './../msi-text-toggle/msi-text-toggle.module';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { AbstractControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';

import { IonicModule } from '@ionic/angular';
import { NgxMaskModule } from 'ngx-mask';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { HotTableModule } from 'ng2-handsontable';
import { NgSelectModule } from '@ng-select/ng-select';

import { FormlyFieldInnerHtmlTemplate } from './templates/formly-html.template';
import { FormlyInputPhoneTemplate } from './templates/formly-phone.template';
import { FormlyInputEmailTemplate } from './templates/formly-email.template';
import { FormlyInputAddressTemplate } from './templates/formly-address.template';
import { FormlyTextToggleTemplate } from './templates/formly-text-toggle.template';
import { FlexLayoutType } from './layouts/flex.layout';
import { FormlyInputPhoneWithExtensionTemplate } from './templates/formly-phone-with-ext.template';
import { MsiCreditCardComponentModule } from '@msi/account/ionic/components/credit-card/credit-card.module';
import { FormlyInputCreditCardTemplate } from './templates/formly-credit-card.template';
import { FormlyInputSubnetTemplate } from './templates/formly-subnet.template';
import { FormlyInputIpAddressTemplate } from './templates/formly-ipaddr.template';
import { FormlyAvatarTemplate } from './templates/formly-avatar.template';
import { FormlyRangeTemplate } from './templates/formly-range.template';
import { FormlyBlockSelectTemplate } from './templates/formly-block-select.template';
import { FormlyDateTimeTemplate } from './templates/formly-datetime.template';
import { FormlyInputTimeTemplate } from './templates/formly-time.template';
import { FormlyAGGridTemplate } from './templates/ag-grid/formly-aggrid.template';
import { GridFormlyCellComponent } from './templates/ag-grid/grid-formly-cell.component';
import { FormlyEditorTemplate } from './templates/formly-editor.template';
import { FormlySheetTemplate } from './templates/sheet/formly-sheet.template';
// import { FormlyDynamicFieldTemplate } from './templates/dynamic/dynamic.template';
// import { DynamicFormlyFieldBuilder } from './templates/dynamic/dynamic-field-builder';
import { FormlyClockTemplate } from './templates/formly-clock.template';
import { MsiTimePickerModule } from '../time-picker/time-picker.module';
import { MsiAvatarComponentModule } from '../avatar/avatar.component.module';
import { MatTabsModule } from '@angular/material/tabs';
import { FormlyPasswordTemplate } from './templates/formly-password.template';
import { FormlyButtonToggleTemplate } from './templates/formly-toggle.template';
import { FormlySelectTemplate } from './templates/formly-select.template';
import { AgGridCheckboxRenderer } from './templates/ag-grid/checkbox-renderer.component';
import { FormlyInputTagsTemplate } from './templates/tag/formly-tags.template';
import { FormlyDebugTemplate } from './templates/debug/formly-debug.template';

export interface MsiWizardStepConfig {
    id: string;
    label: string;
    optional?: boolean;
    formConfig: FormlyFieldConfig[];
}

export interface MsiWizardConfig {
    steps: MsiWizardStepConfig[];
}

export function emailPatternErrorMessage(_e: Error, field: FormlyFieldConfig) {
    return `"${field.formControl.value}" is not a valid email address`;
}

export function minLengthErrorMessage(_e: Error, field: FormlyFieldConfig): string {
    return `Should have at least ${field.templateOptions.minLength} characters`;
}
export function maxLengthErrorMessage(_e: Error, field: FormlyFieldConfig): string {
    return `This value should be less than ${field.templateOptions.maxLength} characters`;
}

export function minErrorMessage(_e: Error, field: FormlyFieldConfig): string {
    return `This value should be more than ${field.templateOptions.min}`;
}

export function maxErrorMessage(_e: Error, field: FormlyFieldConfig): string {
    return `This value should be less than ${field.templateOptions.max}`;
}

export function passwordMatchingValidator(control: AbstractControl, _field: FormlyFieldConfig): ValidationErrors {

    if (control.pristine && !control.touched) {
        return null;
    }

    const password = control.parent.controls['password'];
    const confirmPassword = control.parent.controls['confirmPassword'];

    // avoid displaying the message error when values are empty
    if (!confirmPassword || !confirmPassword.value) {
        return null;
    }

    if (!password || !password.value) {
        return null;
    }

    if (confirmPassword.value === password.value) {
        return null;
    }

    // debugger;
    return { passwordMatch: 'Passwords not matching' };
}
@NgModule({

    declarations: [
        FormlyFieldInnerHtmlTemplate,
        FormlyInputPhoneTemplate,
        FormlyInputPhoneWithExtensionTemplate,
        FormlyInputEmailTemplate,
        FormlyInputAddressTemplate,
        FormlyTextToggleTemplate,
        FormlyInputCreditCardTemplate,
        FormlyInputSubnetTemplate,
        FormlyInputIpAddressTemplate,
        FormlyAvatarTemplate,
        FlexLayoutType,
        FormlyRangeTemplate,
        FormlyBlockSelectTemplate,
        FormlyDateTimeTemplate,
        FormlyInputTimeTemplate,
        FormlyAGGridTemplate,
        AgGridCheckboxRenderer,
        
        GridFormlyCellComponent,
        FormlyEditorTemplate,
        FormlySheetTemplate,
        // FormlyDynamicFieldTemplate,
        FormlyClockTemplate,
        FormlyPasswordTemplate,
        FormlyButtonToggleTemplate,
        FormlySelectTemplate,
        FormlyInputTagsTemplate,
        FormlyDebugTemplate,
    ],
    imports: [
        CommonModule,
        IonicModule,

        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        MsiTextToggleModule,
        MsiCreditCardComponentModule,

        MatStepperModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatChipsModule,

        FontAwesomeModule,
        FlexLayoutModule,

        MatGoogleMapsAutocompleteModule,
        NgxMaterialTimepickerModule,
        MsiTimePickerModule,
        MsiAvatarComponentModule,
        NgSelectModule,

        AgGridModule.withComponents([ AgGridCheckboxRenderer ]),
        AngularEditorModule,
        HotTableModule,

        FormlyModule.forChild({
            types: [
                {
                    name: 'html',
                    component: FormlyFieldInnerHtmlTemplate
                },
                {
                    name: 'phone',
                    component: FormlyInputPhoneTemplate,
                    wrappers: ['form-field']
                },
                {
                    name: 'phone-with-ext',
                    component: FormlyInputPhoneWithExtensionTemplate,
                    wrappers: ['form-field']
                },

                {
                    name: 'email',
                    component: FormlyInputEmailTemplate,
                    wrappers: ['form-field'],
                    defaultOptions: {
                        validation: {
                            messages: {
                                pattern: emailPatternErrorMessage
                            }
                        }
                    }
                },
                {
                    name: 'address',
                    component: FormlyInputAddressTemplate,
                },
                {
                    name: 'text-toggle',
                    component: FormlyTextToggleTemplate,
                },
                {
                    name: 'button-toggle',
                    component: FormlyButtonToggleTemplate
                },
                {
                    name: 'flex-layout',
                    component: FlexLayoutType,
                },
                {
                    name: 'credit-card',
                    component: FormlyInputCreditCardTemplate
                },
                {
                    name: 'subnet',
                    component: FormlyInputSubnetTemplate
                },
                {
                    name: 'ipaddr',
                    component: FormlyInputIpAddressTemplate
                },
                {
                    name: 'avatar',
                    component: FormlyAvatarTemplate,
                },
                {
                    name: 'range',
                    component: FormlyRangeTemplate,
                },
                {
                    name: 'block-select',
                    component: FormlyBlockSelectTemplate,
                },
                {
                    name: 'datetime',
                    component: FormlyDateTimeTemplate,
                },
                {
                    name: 'date',
                    component: FormlyDateTimeTemplate,
                },

                {
                    name: 'password',
                    component: FormlyPasswordTemplate,
                },
                {
                    name: 'time',
                    component: FormlyInputTimeTemplate,
                    wrappers: ['form-field'],
                },
                {
                    name: 'clock',
                    component: FormlyClockTemplate,
                    wrappers: ['form-field'],
                },
                {
                    name: 'ag-grid',
                    component: FormlyAGGridTemplate,
                    wrappers: ['form-field'],
                    defaultOptions: {
                        templateOptions: {
                            width: '100%',
                            height: '400px',
                        },
                    },
                },
                {
                    name: 'sheet',
                    component: FormlySheetTemplate,
                    defaultOptions: {
                        templateOptions: {

                        }

                    }
                },
                {
                    name: 'editor',
                    component: FormlyEditorTemplate,
                },
                {
                    name: 'autocomplete',
                    component: FormlySelectTemplate,
                    
                },
                {
                    name: 'tags',
                    component: FormlyInputTagsTemplate,
                    wrappers: ['form-field'],
                    
                },
                {   
                    name: 'debug',
                    component: FormlyDebugTemplate,
                },
                // {
                //     name: 'template',
                //     component: FormlyDynamicFieldTemplate,
                // }
            ],
            validators: [
                { name: 'passwordMatch', validation: passwordMatchingValidator  },
            ],
            validationMessages: [
                { name: 'required', message: 'This field is required' },
                { name: 'minlength', message: minLengthErrorMessage },
                { name: 'maxlength', message: maxLengthErrorMessage },
                { name: 'min', message: minErrorMessage },
                { name: 'max', message: maxErrorMessage },
            ],
        }),

    ],
    exports: [
        FormlyModule,
    ],

    // providers: [DynamicFormlyFieldBuilder],

})
export class MsiFormlyModule { }

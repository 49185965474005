


import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-input-range',
    template: `
        <ion-item nolines>
            <ion-label position="stacked">{{ to.label }}</ion-label>
            <ion-range  [formControl]="formControl" [formlyAttributes]="field" [min]="to.min" [max]="to.max"
                [step]="to.step" [snaps]="to.snaps" [ticks]="to.ticks">
                <fa-icon [icon]="to.icon" slot="start"></fa-icon>
                <ion-badge slot="end">{{ formControl.value }} {{ to.unit }}</ion-badge>
            </ion-range>
        </ion-item>
  `,
})
export class FormlyRangeTemplate extends FieldType {



}
import { AppMenuConfig } from './app-menu.state';
import { AppMenu } from '../typedefs/sidemenu-options';

const topic = '[msi-appmenu]';

export class AppMenuSetBadge {
    static readonly type: string = `${topic} set badge`;
    constructor(public id: string, public badge: string, public badgeStatus?: string) { }
}

export class AppMenuSelected {
    static readonly type: string = `${topic} selected`;
    constructor(public id: string, public x: number, public y: number) { }
}

export class AppMenuRegister {
    static readonly type: string = `${topic} register app menu`;
    constructor(public config: AppMenuConfig) { }
}

export class AppMenuRequestProfile {
    static readonly type: string = `${topic} request profile`;
}

export class AppMenuRequestLogout {
    static readonly type: string = `${topic} request logout`;
}

export class AppMenuChange {
    static readonly type: string = `${topic} change menu`;
    constructor(public menu: AppMenu) {}
}
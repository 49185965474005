import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext, NgxsAfterBootstrap, Store } from '@ngxs/store';
import { Faq, FaqService, FaqSection } from '@msi/faq/models/faqs';

import {
    FaqAskQuestion,
    FaqInitialize,
    FaqSelectTopic,
    FaqSelect,
    FaqSelectSuggestion, 
    FaqSelectQuickLink, 
    FaqSelectSection, 
    FaqLoadForCurrentTopic 
} from './faq.actions';

import { produce } from '@ngxs-labs/immer-adapter';
import { sample, times, Dictionary, chain, sortBy, first, get } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BfitAppState } from '@bfit/core/states/app.state';
import { FaqSectionsService } from '../models/faq-sections';
import { NavigateForward } from '@msi/core/ngxs/ionic-router/ionic-router.actions';

export interface StateModel {
    question: string;
    faqs: Faq[];
    topAnswers: Faq[];
    quickLinks: [];
    topics: FaqSection[];
    currentFaqSectionId: string;
    currentFaqTopicId: string;
    currentFaqId: string;
    currentTopAnswerId: string;
    suggestions: Faq[];
    basePath: string;
}

const DEFAULTS: StateModel = {
    question: null,
    faqs: [],
    topAnswers: [],
    quickLinks: [],
    topics: [],
    currentFaqSectionId: null,
    currentFaqTopicId: null,
    currentFaqId: null,
    currentTopAnswerId: null,
    suggestions: [],
    basePath: '/faq',
};

@State<StateModel>({
    name: 'msiFaqState',
    defaults: DEFAULTS
})
@Injectable({ providedIn: 'root' })
export class MsiFaqState implements NgxsAfterBootstrap {

    @Selector()
    static getAllFaqs(state: StateModel): Faq[] {
        return state.faqs;
    }

    @Selector()
    static getSectionFaqs(state: StateModel): Faq[] {
        if (state.currentFaqTopicId) {
            return state.faqs.filter(faq => faq.subsection_ids.includes(state.currentFaqTopicId));
        }
        return state.faqs.filter(faq => faq.section_id.toLowerCase() === state.currentFaqSectionId.toLowerCase());
    }

    @Selector()
    static getSections(state: StateModel): FaqSection[] {
        return state.topics.filter(it => !it.parent_id && !it.disabled);
    }

    @Selector([MsiFaqState.getSections])
    static getCurrentSection(state: StateModel, sections: FaqSection[]): FaqSection {
        return sections.find(it => state.currentFaqSectionId === it.id);
    }

    @Selector()
    static getSuggestions(state: StateModel): Faq[] {

        return state.suggestions;
    }

    @Selector()
    static getTopAnswers(state: StateModel): Faq[] {
        return state.topAnswers;
    }

    @Selector()
    static getQuickLinks(state: StateModel): Faq[] {
        return state.quickLinks;
    }

    @Selector()
    static getTopicsDict(state: StateModel): Dictionary<FaqSection[]> {
        return chain(state.topics)
            .groupBy(it => it.parent_id)
            .value();
    }

    @Selector()
    static getCurrentTopicsDict(state: StateModel): Dictionary<FaqSection[]> {
        return chain(state.topics)
            .filter(it => it.parent_id === state.currentFaqSectionId)
            .groupBy(it => it.parent_id)
            .value();
    }

    @Selector()
    static getCurrentTopic(state: StateModel): FaqSection {
        return state.topics.find(section => section.id === state.currentFaqTopicId);
    }

    @Selector()
    static getCurrentFaqSection(state: StateModel): FaqSection {
        let currentFaq;
        if (state.currentFaqId) {
            currentFaq =  state.faqs.find(faq => faq.id === state.currentFaqId);
        } else {
            currentFaq = state.topAnswers.find(faq => faq.id === state.currentTopAnswerId);
        }
        return state.topics.find(section => section.id === currentFaq.section_id);
    }

    @Selector()
    static getCurrentFaq(state: StateModel): Faq {
        return state.faqs.find(faq => faq.id === state.currentFaqId);
    }

    @Selector()
    static getCurrentTopAnswer(state: StateModel): Faq {
        if (state.currentFaqId) {
            return state.faqs.find(faq => faq.id === state.currentFaqId);
        }
        return state.topAnswers.find(faq => faq.id === state.currentTopAnswerId);
    }

    constructor(
        private store: Store,
        private faqService: FaqService,
        private faqSectionService: FaqSectionsService,
    ) { }

    ngxsAfterBootstrap(ctx: StateContext<StateModel>): void {
        this.store.select(BfitAppState.readyAndAuthenticated).subscribe(ready => {
            if (ready) {
                ctx.dispatch(new FaqInitialize());
            } else {
                ctx.setState(DEFAULTS);
            }
        });
    }

    @Action(FaqInitialize)
    initialize(ctx: StateContext<StateModel>): Observable<any> {
        const faqSections$ = this.faqSectionService.findAll();
        const quickLinks$ = this.faqService.findAllQuicklinks();
        const topAnswers$ = this.faqService.findAllTopAnswers();

        return combineLatest([faqSections$, quickLinks$, topAnswers$ ]).pipe(
            tap(([faqSections, quickLinks, topAnswers ]) => {
                produce(ctx, draft => {
                    draft.topics = sortBy(faqSections, f => f.order);
                    draft.topAnswers = topAnswers;
                    draft.quickLinks = quickLinks;

                    draft.currentFaqSectionId = get(first(draft.topics.filter(t => !t.parent_id)), 'id');
                    draft.currentFaqTopicId = null;
                    draft.faqs = [];
                });
            })
        );
    }

    @Action(FaqAskQuestion, { cancelUncompleted: true })
    askQuestion(ctx: StateContext<StateModel>, action: FaqAskQuestion): Observable<Faq[]> {
        return this.faqService.findAll().pipe(
            tap(list => {
                produce(ctx, draft => {
                    draft.question = action.question;
                    draft.faqs = list;
                    // FIXME: Tghis should be base on what the user ask
                    draft.suggestions = times(5, () => sample(list));

                });
            })
        );
    }

    @Action(FaqSelectTopic)
    selectTopic(ctx: StateContext<StateModel>, action: FaqSelectTopic): void {
        ctx.patchState({ currentFaqTopicId: action.faqTopic && action.faqTopic.id });
        ctx.dispatch([
            new FaqLoadForCurrentTopic(action.faqTopic),
            new NavigateForward(`${ctx.getState().basePath}/topic`)
        ]);
    }

    @Action(FaqSelect)
    selectFaq(ctx: StateContext<StateModel>, action: FaqSelect): void {
        if (action.faq.isTopAnswer) {
            ctx.patchState({ currentTopAnswerId: action.faq && action.faq.id, currentFaqId: null });
        } else {
            ctx.patchState({ currentFaqId: action.faq && action.faq.id, currentTopAnswerId: null });
        }
        ctx.dispatch(new NavigateForward(`${ctx.getState().basePath}/response/${action.faq.id}`));
    }

    @Action(FaqSelectSection)
    selectSection(ctx: StateContext<StateModel>, action: FaqSelectSection): void {
        ctx.patchState({
            currentFaqSectionId: action.section && action.section.id,
            currentFaqTopicId: null
        });
        ctx.dispatch(new NavigateForward(`${ctx.getState().basePath}`));
    }

    @Action(FaqSelectSuggestion)
    selectSuggestion(ctx: StateContext<StateModel>, action: FaqSelectSuggestion): void {
        if (action.faq.isTopAnswer) {
            ctx.patchState({ currentTopAnswerId: action.faq && action.faq.id, currentFaqId: null });
        } else {
            ctx.patchState({ currentFaqId: action.faq && action.faq.id, currentTopAnswerId: null });
        }
        ctx.dispatch(new NavigateForward(`${ctx.getState().basePath}/respons/${action.faq.id}`));
    }

    @Action(FaqSelectQuickLink)
    selectQuickLink(ctx: StateContext<StateModel>, action: FaqSelectQuickLink): void {
        if (action.faq.isTopAnswer) {
            ctx.patchState({ currentTopAnswerId: action.faq && action.faq.id, currentFaqId: null });
        } else {
            ctx.patchState({ currentFaqId: action.faq && action.faq.id, currentTopAnswerId: null });
        }
        ctx.dispatch(new NavigateForward(`${ctx.getState().basePath}/response/${action.faq.id}`));
    }

    @Action(FaqLoadForCurrentTopic, { cancelUncompleted: true })
    loadCurrentTopicFaqs(ctx: StateContext<StateModel>, action: FaqLoadForCurrentTopic ): Observable<Faq[]> {
        return this.faqService.findAllForTopic(action.faqTopic).pipe (
            tap (faqs => ctx.patchState({ faqs }))
        );
    }
}


import {Pipe, PipeTransform} from '@angular/core';
import {firestore} from 'firebase/app';
import Timestamp = firestore.Timestamp;

import * as moment from 'moment-timezone';
import { get } from 'lodash';

@Pipe({
    name: 'asDate'
})
export class AsDatePipe implements PipeTransform {

    // constructor(@Inject(LOCALE_ID) private _locale: string) {
    // }

    transform(timestamp: Timestamp | string | Date | { _seconds: number, _nanoseconds: number }, timezone?: string): Date {
        let date: Date;

        if (typeof (timestamp) === 'string') {
            date = moment(timestamp).toDate();
        } else if (timestamp && get(timestamp, 'nanoseconds', null) !== null) {
          date = ( timestamp as Timestamp).toDate();
        } else if (timestamp && '_seconds' in timestamp) {
            date = new Date(timestamp._seconds as number * 1000);
        } else {
            date = timestamp as Date;
        }
        return timezone ? moment.tz(date, timezone).toDate() : date;
    }
}

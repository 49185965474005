import { NgModule } from '@angular/core';
import { RegisterFormComponent } from './register';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    RegisterFormComponent,
  ],
  imports: [

    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
  ],
  exports: [
    RegisterFormComponent
  ]
})
export class MsiAuthRegisterFormModule {}

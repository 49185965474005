import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { IdleTimeoutModal } from "./idle-timeout.modal";

@NgModule({
    entryComponents: [
        IdleTimeoutModal,
    ],
    declarations: [
        IdleTimeoutModal,
    ],
    imports: [
        CommonModule,
        IonicModule,
        FlexLayoutModule,
    ],
    exports: [
        IdleTimeoutModal,
    ]
})
export class IdleTimeoutModule { }
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { ImageSliderConfigModel } from '@msi/ionic/ui/image-slider/image-slider.module';

@Component({
  selector: 'msi-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
})
export class MsiImageSliderComponent implements OnInit {
  
    @Input() config: ImageSliderConfigModel;
    @Input() slides: any[];

    @ViewChild('slickModal', { static: true })
    slickModal: SlickCarouselComponent;

    constructor() { }
    ngOnInit(): void {}
}

import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, take, tap, combineAll, switchMap } from 'rxjs/operators';

import {
  QueryFn,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  AngularFirestore,

} from '@angular/fire/firestore';

import { OrderByDirection, WhereFilterOp } from 'firebase/firebase-firestore';
import * as firebase from 'firebase/app';
import { each, chunk, concat, get } from 'lodash';
import { TraceOptions } from '@angular/fire/performance';
import { environment } from 'environments/environment';

import * as moment from 'moment';

// import linq from 'linq2fire';

// FIXME: Because of a bug in angularfire2 #1837 redefine those classes
export class Timestamp extends firebase.firestore.Timestamp {
  constructor(seconds: number, nanoseconds: number) {
    super(seconds, nanoseconds);
  }
}

export class GeoPoint extends  firebase.firestore.GeoPoint {
  constructor(latitude: number, longitude: number) {
    super(latitude, longitude);
  }
}

/* export interface GeoPoint {
  latitude: number;
  longitude: number;
}
 */
export function newGeoPoint(lat: number, lng: number): GeoPoint {
  const point: any = new firebase.firestore.GeoPoint(lat, lng);
  return point as GeoPoint;
}



const MAX_BATCHSIZE = 250;

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

export interface DbAdapter {

  createUUID(): string;

  set(ref: string, data: any): Promise<void>;
  add(ref: string, data: undefined): Promise<any>;
  addAll(ref: string, list: any[]): Promise<void[]>;

  update(ref: string, data: any): {};
  updateAll(ref: string, list: any[]): Promise<void[]>;

  upsert(ref: string, data: any): Promise<void>;
  upsertAll(ref: string, data: any[]): Promise<void[]>;

  delete(ref: string): {};
  deleteAll(ref: string, data: any[]): Promise<void[]>;
}

export function timestampAsDate(timestamp: Timestamp | Date | string): Date | null {

  let date: Date;

  if (!timestamp) {
    return null;
  } else if (typeof (timestamp) === 'string') {
    date = moment(timestamp).toDate();
  } else if (timestamp && get(timestamp, 'nanoseconds', -1) !== -1) {
    date = (<Timestamp>timestamp).toDate();
  } else {
    date = timestamp as Date;
  }
  return date;
}

export function timestampAsString(timestamp: Timestamp | Date): string {
  const result = timestampAsDate(timestamp);
  return result.toISOString();
}

export class QueryLogger {

  static id: number = 0;
  static count: number = 0;
  static ids: number[] = [];

  id: number;
  text: string[];
  startTime: Date;
  endTime: Date;

  constructor(msg: string, queryFn?: Function) {

    QueryLogger.id += 1;

    this.id = QueryLogger.id;
    QueryLogger.ids[this.id] = 0;

    this.startTime = new Date();
    this.text = [];
    this.text.push(msg);
    if (queryFn) {
      queryFn(this);

    }
    if (environment.debugDbCalls) {
      console.log(`${this} started`);
    }
  }

  where(field: string, op: WhereFilterOp, ...fieldValues: any[]): QueryLogger {
    this.text.push(`${field} ${op} ${JSON.stringify(fieldValues)}`);
    return this;
  }

  limit(n: number): QueryLogger {
    this.text.push(`limit: ${n}`);
    return this;
  }

  startAt(...fieldValues: any[]): QueryLogger {
    this.text.push(`startAt:  ${JSON.stringify(fieldValues)}`);
    return this;
  }

  startBefore(...fieldValues: any[]): QueryLogger {
    this.text.push(`startBefore:  ${JSON.stringify(fieldValues)}`);
    return this;
  }

  startAfter(...fieldValues: any[]): QueryLogger {
    this.text.push(`startAfter:  ${JSON.stringify(fieldValues)}`);
    return this;
  }

  endAt(...fieldValues: any[]): QueryLogger {
    this.text.push(`endAt: ${JSON.stringify(fieldValues)}`);
    return this;
  }

  endBefore(...fieldValues: any[]): QueryLogger {
    this.text.push(`endBefore: ${JSON.stringify(fieldValues)}`);
    return this;
  }

  endAfter(...fieldValues: any[]): QueryLogger {
    this.text.push(`endAfter: ${JSON.stringify(fieldValues)}`);
    return this;
  }

  orderBy(fieldPath: string, direction?: OrderByDirection): QueryLogger {
    this.text.push(`orderBy: ${fieldPath} ${direction}`);
    return this;
  }

  toString(): string {
    return `${this.id}: ${this.text.join(' ')}`;
  }

  log(result?: any): void {

    QueryLogger.count += result ? result instanceof Array ? result.length : 1 : 0;
    QueryLogger.ids[this.id] += 1;
    const timeRefreshed = QueryLogger.ids[this.id];

    if (environment.debugDbCalls) {
      console.log(`${this} log ${QueryLogger.ids[this.id]} times ` +
        ` result: ${result ? result instanceof Array ? 'len=' + result.length : JSON.stringify(result) : result} ` +
        ` total: ${timeRefreshed} ` +
        ` sub: ${QueryLogger.ids.map((v, n) => v > 0 ? n : '').filter(v => !!v)}` +
        ` left: ${QueryLogger.ids.map((v, n) => v === 0 ? n : '').filter(v => !!v)}`
      );
    }
  }

  finalize(): void {
    const timeRefreshed = QueryLogger.ids[this.id];
    delete QueryLogger.ids[this.id];
    this.endTime = new Date();
    const timeDiff = Math.round((this.endTime.getTime() - this.startTime.getTime()) / 1000);
    if (environment.debugDbCalls) {
      console.log(`${this} finalize in ${timeDiff} sec ` +
        ` total: ${timeRefreshed} ` +
        ` sub: ${QueryLogger.ids.map((v, n) => v > 0 ? n : '').filter(v => !!v)}` +
        ` left: ${QueryLogger.ids.map((v, n) => v === 0 ? n : '').filter(v => !!v)}`
      );
    }

  }
}

@Injectable()
export class FirestoreService implements DbAdapter {
  constructor(private afs: AngularFirestore) {

  }

  get timestamp(): firebase.firestore.FieldValue {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn, _trace?: TraceOptions): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.afs.collection<T>(ref, queryFn) : ref;
  }

  doc<T>(ref: DocPredicate<T>, _trace?: TraceOptions): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
  }

  doc$<T>(ref: DocPredicate<T>, _trace?: TraceOptions): Observable<T> {

    // const logger = new QueryLogger (`doc$: ${ref}`);

    return this.doc(ref).snapshotChanges().pipe(
      take(1),
      map(doc => {
        return doc.payload.data();
      }),
      // finalize (() => logger.stop())
    );
  }

 /*  private dirname(path: string): string {
    return path.split('/').slice(0, -1).join('/');
  }

  private basename(path: string): string {
    return path.split('/').slice(-1).join('/');
  } */

  docWithId$<T>(ref: DocPredicate<T>, _trace?: TraceOptions): Observable<T> {

    // const logger = new QueryLogger (`doc$: ${ref}`);

    return this.doc(ref).snapshotChanges().pipe(
      take(1),
      map(doc => {
        const id: string = doc.payload.id;
        const data: any = doc.payload.data();
        if (data) {
          const dateCreated: Date = timestampAsDate(data.createdAt);
          const dateUpdated: Date = timestampAsDate(data.updatedAt);
          return { ...data, id: id, createdAt: dateCreated || dateUpdated, updatedAt: dateUpdated } as T;
        } else {
          return { ...data, id: id };
        }
      }),

      // finalize (() => logger.stop())
    );
  }

  exists$<T>(ref: DocPredicate<T>): Observable<boolean> {
    return this.doc(ref).snapshotChanges().pipe(
      take(1),
      map(doc => {
        return doc.payload.exists;
      })
    );
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn, _trace?: TraceOptions): Observable<T[]> {

    // const logger = new QueryLogger (`doc$: ${ref}`, queryFn);

    return this.col(ref, queryFn).snapshotChanges().pipe(
      map(docs => {
        return docs.map(a => a.payload.doc.data()) as T[];
      }),
      // finalize (() => logger.stop())
    );
  }

  getDocIdFieldName(): any {
    return firebase.firestore.FieldPath.documentId();
  }

  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn, _trace?: TraceOptions): Observable<T[]> {

    // const logger = new QueryLogger (`doc$: ${ref}`, queryFn);

    return this.col(ref, queryFn).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const id: string = a.payload.doc.id;
          const data: any = a.payload.doc.data();
          if (data) {
            const dateCreated: Date = timestampAsDate(data.createdAt);
            const dateUpdated: Date = timestampAsDate(data.updatedAt);
            return { ...data, id: id, createdAt: dateCreated || dateUpdated, updatedAt: dateUpdated } as T;
          } else {
            return { ...data, id: id };
          }
        });
      }),
      // finalize (() => logger.stop())
    );
  }


  // Crud Operation

  update<T>(ref: DocPredicate<T>, data: any, _trace?: TraceOptions): Promise<void> {
    return this.doc(ref).update({
      ...data,
      updatedAt: this.timestamp
    });
  }

  set<T>(ref: DocPredicate<T>, data: any, _trace?: TraceOptions): Promise<void> {
    const timestamp = this.timestamp;
    return this.doc(ref).set({
      ...data,
      updatedAt: timestamp,
    });
  }

  add<T>(ref: CollectionPredicate<T>, data: any, _trace?: TraceOptions): Promise<firebase.firestore.DocumentReference> {
    const timestamp = this.timestamp;
    return this.col(ref).add({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    });
  }

  private _addAll<T>(ref: CollectionPredicate<T>, list: T[]): Promise<void> {
    const batch = this.afs.firestore.batch();
    const path: string = this.col(ref).ref.path;

    each(list, (data: any) => {

      if (!data.id) {
        data.id = this.createUUID();
      }
      const sref = this.doc(`${path}/${data.id}`).ref;
      batch.set(sref, {
        ...data,
        updatedAt: this.timestamp
      });
    });
    return batch.commit();
  }

  async addAll<T>(ref: CollectionPredicate<T>, list: T[], _trace?: TraceOptions): Promise<void[]> {
    const batches = chunk(list, MAX_BATCHSIZE);
    const all = batches.map((batch: T[]) => this._addAll(ref, batch));
    return Promise.all(all).then(results => concat(results));
  }

  private async _updateAll<T>(ref: CollectionPredicate<T>, list: T[]): Promise<void> {
    const batch = this.afs.firestore.batch();
    each(list, (data: any) => {
      const path: string = this.col(ref).ref.path;
      const sref = this.doc(`${path}/${data.id}`).ref;
      batch.update(sref, {
        ...data,
        updatedAt: this.timestamp
      });
    });
    return batch.commit();
  }

  async updateAll<T>(ref: CollectionPredicate<T>, list: T[], _trace?: TraceOptions): Promise<void[]> {
    const batches = chunk(list, MAX_BATCHSIZE);
    const all = batches.map((batch: T[]) => this._updateAll(ref, batch));
    const results = await Promise.all(all);
    return concat(results);
  }

  async upsert<T>(ref: DocPredicate<T>, data: any, _trace?: TraceOptions): Promise<void> {
    const doc = await this.doc(ref).snapshotChanges().pipe(take(1)).toPromise();
    return doc.payload.exists ? this.update(ref, data) : this.set(ref, data);
  }

  private async _upsertAll<T>(ref: CollectionPredicate<T>, data: T[], _trace?: TraceOptions): Promise<void> {
    const batch = this.afs.firestore.batch();
    const path: string = this.col(ref).ref.path;

    await of(data).pipe(
      switchMap((list, _n) => {
        return list.map((item: any) => {
          if (!item.id) {
            item.id = this.createUUID();
          }
          const sref = `${path}/${item.id}`;
          return this.doc(sref).snapshotChanges().pipe(
            take(1),
            map(snap => {
              return snap.payload.exists ? batch.update(snap.payload.ref, item) : batch.set(snap.payload.ref, item);
            })
          );
        });

      }),
      combineAll(),
      tap(_batches => {
        // console.log ('upsertAll content: ', batches);
      })
    ).toPromise();

    return batch.commit();
  }

  async upsertAll<T>(ref: CollectionPredicate<T>, list: T[], _trace?: TraceOptions): Promise<void[]> {
    const batches = chunk(list, MAX_BATCHSIZE);
    const all = batches.map((batch: T[]) => this._upsertAll(ref, batch));
    return Promise.all(all).then(results => concat(results));
  }

  delete<T>(ref: DocPredicate<T>): Promise<void> {
    return this.doc(ref).delete();
  }

  private async _deleteAll<T>(ref: CollectionPredicate<T>, ids: string[], _trace?: TraceOptions): Promise<void> {
    const batch = this.afs.firestore.batch();
    const path: string = this.col(ref).ref.path;

    const promises = ids.map(id => {
      const doc = this.doc(`${path}/${id}`);
      return batch.delete(doc.ref);
    });

    await Promise.all(promises);
    return batch.commit();
  }


  async deleteAll<T>(ref: CollectionPredicate<T>, ids: string[], _trace?: TraceOptions): Promise<void[]> {
    const batches = chunk(ids, MAX_BATCHSIZE);
    const all = batches.map((batch: string[]) => this._deleteAll(ref, batch));
    return Promise.all(all).then(results => concat(results));
  }

  runTransaction<T>(updateFunction: (transaction: firebase.firestore.Transaction) => Promise<T>): Promise<T> {
    return this.afs.firestore.runTransaction<T>(updateFunction);
  }

  // Debug

  inspectDoc(ref: DocPredicate<any>): void {
    const tick = new Date().getTime();
    this.doc(ref).snapshotChanges().pipe(
      take(1),
      tap(d => {
        const tock = new Date().getTime() - tick;
        console.log(`Loaded Document in ${tock}ms`, d);
      })
    ).subscribe();
  }

  inspectCol(ref: CollectionPredicate<any>): void {
    const tick = new Date().getTime();
    this.col(ref).snapshotChanges().pipe(
      take(1),
      tap(c => {
        const tock = new Date().getTime() - tick;
        console.log(`Loaded Collection in ${tock}ms`, c);
      })
    ).subscribe();
  }

  /** Geopoint: Usage
  *  const geopoint = this.db.geopoint(38, -119)
  *  return this.db.add('items', { location: geopoint })
  **/

  geopoint(lat: number, lng: number): GeoPoint {
    return  newGeoPoint(lat, lng);
  }

  /// **************
  /// Create and read doc references
  /// **************

  /// create a reference between two documents
  connect(host: DocPredicate<any>, key: string, doc: DocPredicate<any>): Promise<void> {
    return this.doc(host).update({ [key]: this.doc(doc).ref });
  }

  /// returns a documents references mapped to AngularFirestoreDocument
  docWithRefs$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc$(ref).pipe(
      map(doc => {
        for (const k of Object.keys(doc)) {
          if (doc[k] instanceof firebase.firestore.DocumentReference) {
            doc[k] = this.doc(doc[k].path);
          }
        }
        return doc;
      })
    );
  }

  linq(_collection: string): void {
    // return linq(firebase.firestore()).from (collection) ;
  }

  createUUID(): string {
    return this.afs.createId();
  }
}



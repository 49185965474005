import { Pipe, PipeTransform } from '@angular/core';
import { PostalAddress } from '@msi/core/dao/models/address';


@Pipe({name: 'address'})
export class AddressPipe implements PipeTransform {

    transform(addr: string | PostalAddress, _args: any[] = []): string {
        return addr ?
            (typeof addr === 'string') ? addr :
                [ 
                    addr.street,
                    addr.unit || '',
                    [ addr.city, ',', addr.state, addr.zip ].join(' ')
                ].join(' ') :
            '';
    }
}

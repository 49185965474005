import { NgModule } from '@angular/core';
import { MsiBannerComponent } from './modal-banner.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
    declarations: [
        MsiBannerComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
    ],
    exports: [
        MsiBannerComponent
    ]
})
export class MsiModalBannerModule { }

import { NgModule } from '@angular/core';
import { MsiBreadcrumbsComponent } from './breadcrumbs.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MsiBreadcrumbState } from './breadcrumbs.state';
import { NgxsModule } from '@ngxs/store';

@NgModule({
    declarations: [
        MsiBreadcrumbsComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        FlexLayoutModule,
        NgxsModule.forFeature([ MsiBreadcrumbState ]),
        RouterModule,
    ],
    exports: [
        MsiBreadcrumbsComponent
    ]
})
export class MsiBreadcrumbsModule { }

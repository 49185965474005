
import { MsiAccount } from '@msi/account/core/models/accounts';
import { PostalAddress } from '@msi/core/dao/models/address';
import { ObjectReference } from '../../entities/ref';
import { BfitContextBasedObject } from '../bfit-context.service';

export enum BfitUserType {
    master = 'master',
    partner = 'partner',
    manager = 'manager',
    user = 'user',
    singleUser = 'single-user',
    demoUser = 'demo-user',
}

export interface BfitUser extends BfitContextBasedObject {

    displayName: string;
    firstName?: string;
    lastName?: string;

    middleName?: string;
    address: PostalAddress;
    formattedAddress?: string;
    email?: string;
    emailVerified?: boolean;
    changePassword?: boolean;
    phone?: string;
    mobile: string;
    photoURL?: string;
    providerId?: string;
    requires2fa: boolean;

    firstTimeLogin: boolean;

    disabled: boolean;

    settings?: any; // FIXME: We need to define a settings interface

    username: string;

    type: BfitUserType;

    orgs: { [org: string]: boolean };

    bfit_account_ref?: ObjectReference<MsiAccount>;


}

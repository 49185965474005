const topic = '[msi-auth-login]';

export class MsiAuthCheckLoginWithGoogle {
    static readonly type: string = `${topic} check login with google`;
}

export class MsiAuthLoginWithFacebook {
    static readonly type: string = `${topic} check login with facebook`;
}

export class MsiAuthCheckLoginWithTwitter {
    static readonly type: string = `${topic} check login with twitter`;
}

export class MsiAuthCheckLoginWithEmail {
    static readonly type: string = `${topic} check login with email and password`;
    constructor(public email: string, public password: string) {}
}

export class MsiAuthCheckLoginWithMobile {
    static readonly type: string = `${topic} check login with phone number`;
    constructor(public mobile: string) {}
}

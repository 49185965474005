import { ObjectReference } from '../../entities/ref';
import { BfitContextBasedObject } from '../bfit-context.service';
import { BfitDevice } from './bfit-device';

export enum BfitVpnType {
    user = 'user-vpn',
    server = 'server-vpn',
    appliance = 'appliance-vpn'
}

export interface BfitVpnOs {
    id: string;
    type: BfitVpnType;
    img: string;
    title: string;
    subtitle: string;
    url: string;
}

export interface BfitVpn extends BfitContextBasedObject {

    name: string;
    internal_name: string;
    description: string;
    status: string;

    type: BfitVpnType;
    os: BfitVpnOs;

    digi_id: string;
    dataCenter_id: string;
    cidr: string;

    bfit_endpoint_ref: ObjectReference<BfitDevice>;
    bfit_proxy_ref: ObjectReference<BfitDevice>;
    bfit_dataServer_ref: ObjectReference<BfitDevice>;
}


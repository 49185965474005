
import { COMMA, SPACE, TAB } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { FieldType } from '@ngx-formly/core';
import { isArray } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'formly-input-tags',
    templateUrl: './formly-tags.template.html',
    styleUrls: ['./formly-tags.template.scss']
})
export class FormlyInputTagsTemplate extends FieldType implements OnInit {

   
    segments: string[];
    filteredSegments$: Observable<string[]>;
    separatorKeysCodes: number[] = [ SPACE, COMMA, TAB ];

    @ViewChild('segmentsInput', { static: false }) segmentsInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;


    constructor() {
        super();
        this.segments = [];
    }

    add(event: MatChipInputEvent): void {
        // Add segments only when MatAutocomplete is not open
        // To make sure this does not conflict with OptionSelected Event
        if (!this.matAutocomplete.isOpen) {
           
            const value = event.value;

            // Add our segment
            if ((value || '').trim()) {
                this.segments.push(value.trim());
            }

            // Reset the input value
            this.segmentsInput.nativeElement.value = '';
            this.formControl.setValue(this.segments);
        }
    }

    remove(word: string): void {
        const idx = this.segments.indexOf(word);
        if (idx >= 0) {
            this.segments.splice(idx, 1);
            this.formControl.setValue(this.segments);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.segments.push(event.option.viewValue);
        this.segmentsInput.nativeElement.value = '';
        this.formControl.setValue(this.segments);
    }

    private _filter(text: string): string[] {
        const filterValue = text ? `${text}`.toLowerCase() : '';
        return this.segments.filter(tag => tag && tag.toLowerCase().indexOf(filterValue) === 0);
    }

    ngOnInit(): void {

        this.segments = isArray(this.to.options) && this.to.options.map(opt => opt.label) || [];

        this.filteredSegments$ = this.formControl.valueChanges.pipe(
            map((tag: string | null) => tag ? this._filter(tag) : [])
        );
    }

}



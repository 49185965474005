import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { User } from '@msi/core/dao/models/user';
import { MsiAuthResendTwoFactorCode } from '@msi/ionic/core/auth/actions/auth-login.actions';

import { take, takeRight, times } from 'lodash';
import { MsiAuthLoginService } from '@msi/ionic/core/auth/services/login.service';
import { BehaviorSubject } from 'rxjs';


@Component({
    selector: 'msi-auth-two-factor-form',
    templateUrl: './two-factor-auth.component.html',
    styleUrls: ['./two-factor-auth.component.scss'],
})
export class TwoFactorFormComponent implements AfterViewInit {

    @Input() user: User;
    @Input() token: string;
    @Input() canPostpone: boolean = false;

    @Output() pass: EventEmitter<string> = new EventEmitter();
    @Output() abort: EventEmitter<null> = new EventEmitter();

    pin = '';

    pin1 = '';
    pin2 = '';
    pin3 = '';
    pin4 = '';
    pin5 = '';
    pin6 = '';

    message = '';
    useSMS = true;
    busy$: BehaviorSubject<boolean>;

    pause = 5;
    dontAskMeAgain = false;

    constructor(private authService: MsiAuthLoginService) { 
        this.clearPin();
        this.message = '';
        this.token = '';
        this.busy$ = new BehaviorSubject(false);
    }

    clearPin(): void {
        this.pin = this.pin1 = this.pin2 = this.pin3 = this.pin4 = this.pin5 = this.pin6 = '';
    }

    goBack = () => {
        this.clearPin();
        this.abort.emit();
    }

    ngAfterViewInit(): void {
        this.resendCode(this.user, this.useSMS);
        
    }

    async checkPin(pin: string): Promise<void> {
        this.busy$.next(true);
        const result = await this.authService.verify2FA(this.user.org_id, pin);
        if (result && result.verified) {
            return this.pass.emit(this.token);
        } else {
            this.message = result.msg;
            this.clearPin();
            this.busy$.next(false);
        }
    }

    @Dispatch()
    resendCode = (user: User, sms: boolean) => new MsiAuthResendTwoFactorCode(user, sms);

    onKey(event: KeyboardEvent, nextInput: HTMLInputElement, prevInput: HTMLInputElement): Promise<void> {

        const reg = new RegExp('^[0-9]+$');
        const input = (event.target as HTMLInputElement).value;
        const digit = reg.test(input);

        const key = event.code;

        if (key === 'Enter') {
            if (this.pin.length === 6) {
                return this.checkPin(this.pin);
            }
        } else if (key === 'Backspace' || key === 'ArrowLeft') {
            prevInput.focus();
        } else if (key === 'ArrowRight') {
            nextInput.focus();
        } else if (key === 'Delete') {
            (event.target as HTMLInputElement).value = '';
        } else {
            if (digit) {
                nextInput.focus();
            } else {
                (event.target as HTMLInputElement).value = '';
            }
        }

        this.pin = this.pin1 + this.pin2 + this.pin3 + this.pin4 + this.pin5 + this.pin6;
        // this.message = `pin currently entered: ${this.pin}`;

    }

    toggleSms(value: boolean): void {
        this.useSMS = value;
    }

    getMaskPhoneNo(user: User): string {
        return (user && user.mobile) ?
            `(***) ***-${takeRight(user.mobile, 4).join('')}` :
            '(***) ***-****';
    }

    getMaskEmail(user: User): string {
        if (user && user.email) {
            const seg = user.email.split('@');
            return `${take(seg[0], 1)}${times(seg[0].length - 2).map(() => '*').join('')}${takeRight(seg[0], 1)}@${seg[1]}`;
        } else {
            return 'no email on file';
        }
    }
}

import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { BFitClientApp } from './app.component';


import { BfitIonicModule } from '@bfit/ionic/components/bfit-ionic.module';
import { AppRoutingModule } from './app-routing.module';
import { MsiDatabaseModule } from '@msi/core/dao/db.module';
import { environment } from '@env';
import { MsiAuthIonicModule } from '@msi/ionic/core/auth/auth.module';
import { MsiNgxsModule } from '@msi/core/ngxs/ngxs.module';
import { BfitCoreModule } from '@bfit/core/bfit-core.module';
import { BfitAuthModule } from '@bfit/ionic/auth/bfit-auth.module';
import { NgxsModule } from '@ngxs/store';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireModule, FirebaseApp } from '@angular/fire';
import { AngularFirestoreModule, FirestoreSettingsToken, EnablePersistenceToken } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MsiUiNotifyModule } from '@msi/ionic/ui/notify/notify.module';
import { OVirtCoreModule } from '@ovirt/core/ovirt-core.module';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyModule } from '@ngx-formly/core';


import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';

import { ToastrModule } from 'ngx-toastr';

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { BfitContextHelpModule } from '@bfit/ionic/context-help/context-help.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';


const maskConfig: Partial<IConfig> = {
  validation: false,
};

Sentry.init({
  dsn: "https://9c8d9fb82e614aefa08be70b948eb017@o258111.ingest.sentry.io/1547278",

  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: `bfit-admin@${environment.version}`,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.production ? 0.75 : 1.0,
  ignoreErrors: [
    /Uncaught DOMException/,
    /InvalidStateError: CanvasRenderingContext2D.drawImage/,
    /InvalidStateError: Failed to execute 'drawImage' on 'CanvasRenderingContext2D'/
  ],

});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error: any): void {
    if (!error) {
      const eventId = Sentry.captureException(error && error.originalError || error);
      Sentry.showReportDialog({ eventId });
    }
  }
}

@NgModule({
  declarations: [
    BFitClientApp
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({

    }),
    FormlyModule.forRoot({
      validationMessages: [
        { name: 'required', message: 'This field is required.'},
      ]
    }),
    FormlyMaterialModule,

    ToastrModule.forRoot({ 
      newestOnTop: false,
      preventDuplicates: true,
      maxOpened: 10
    }),

    AgmCoreModule.forRoot({
      apiKey: environment.firebaseConfig.apiKey,
      libraries: ['places']
    }),
    MatGoogleMapsAutocompleteModule,

    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
    }),

    NgIdleKeepaliveModule.forRoot(),

    NgxMaskModule.forRoot(maskConfig),

    // FIXME: Use the config passed as an argument of forRoot

    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    // AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,

    AppRoutingModule,
    MsiDatabaseModule.forRoot(environment.firebaseConfig),
    MsiNgxsModule,

    
    MsiUiNotifyModule,
    MsiAuthIonicModule,

    
    BfitCoreModule,
    OVirtCoreModule,
    BfitAuthModule,
    BfitIonicModule,
    BfitContextHelpModule,
    
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

  ],
  providers: [
    StatusBar,
    SplashScreen,

    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    FirebaseApp,
    {
      provide: FirestoreSettingsToken, useValue: {
        timestampsInSnapshots: undefined,
        cacheSizeBytes: 5 * 1024 * 1024, // 5MB
      }
    },
    { provide: EnablePersistenceToken, useValue: true },
    { provide: ErrorHandler, useClass: SentryErrorHandler },

  ],
  bootstrap: [
    BFitClientApp
  ]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { DBService } from '@msi/core/dao/db';
import { FirestoreService } from '@msi/core/dao/firebase/firestore';
import { FaqSection } from './entities/faq-section';

@Injectable({ providedIn: 'root'})
export class FaqSectionsService extends DBService<FaqSection> {
    constructor(db: FirestoreService) {
        super ('faq-sections', db);
        this.patchDefaults({ parent_id: null });
    }

   
}

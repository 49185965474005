import { BfitInvite } from "@bfit/core/models/bfit/bfit-invites";

const topic = 'invitations';

export class InvitationSetCurrent {
    static readonly type: string = `${topic} set current`;
    constructor(public invitation: BfitInvite) {}
}

export class InvitationAccept {
    static readonly type: string = `${topic} accept inviation`;
    constructor(public invitation: BfitInvite, public info: any) { }
}



import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { BfitVpn } from './entities/bfit-vpn';
import { OVirtDataCenter } from '@bfit/core/interfaces/ovirt';
import { of, Observable } from 'rxjs';
import { BfitContextService } from './bfit-context.service';

export * from './entities/bfit-vpn';


@Injectable({ providedIn: 'root' })
export class BfitVpnsService extends BfitContextService<BfitVpn> {

    constructor(db: FirestoreService) {
        super('bfit_vpns', db);
    }

    findAllForDataCenter(dc: OVirtDataCenter): Observable<BfitVpn[]> {
        return dc ? this.getAll(ref => ref.where('dataCenter_id', '==', dc.id)) : of([]);
    }
}

<div class="msi-grid-item">
  <div class="msi-grid-item-body">
      <div class="msi-grid-image">
          <img [src]="card.photoURL || '/assets/images/global-icons/image-placeholder.svg'" alt="">
      </div>
      <div class="msi-description">
          <div class="msi-item_name">
              <h3>{{ card.displayName || card.name }}</h3>
          </div>
          <ul class="msi-item-list">
              <li>{{ card.address | address }}</li>
              <li>{{ card.phone | usPhone }}</li>
              <li>{{ card.email }}</li>
          </ul>
      </div>
  </div>
  <div class="msi-grid-footer">{{ card.comment }}
  </div>
  <div class="msi-grid-sub-menu">
      <ion-icon name="more"></ion-icon>
  </div>
</div>
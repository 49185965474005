import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgxsModule } from '@ngxs/store';
import { MsiToasterState } from './store/toaster.state';

import { ToastrModule } from 'ngx-toastr';
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        IonicModule,
        ToastrModule,
        NgxsModule.forFeature([ MsiToasterState ]),
    ],
    exports: [],
})
export class MsiUiNotifyModule { }

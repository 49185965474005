import { DBService } from '@msi/core/dao/db';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';
import { Injectable } from '@angular/core';
import { CloudCommand } from '../interfaces/ovirt';

@Injectable({ providedIn: 'root' })
export class CloudCommandsService extends DBService<CloudCommand> {
    constructor(db: FirestoreService) {
        super('cloud_commands', db);
    }
}

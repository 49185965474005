import { NgPipesModule } from 'ngx-pipes';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatTabsModule } from '@angular/material/tabs';

import { MsiFaqHomePage } from './faq.page';
import { MsiFaqComponentsModule } from '@msi/faq/components/faq-components.module';




@NgModule({
    declarations: [
        MsiFaqHomePage
    ],
    imports: [
        CommonModule,
        IonicModule,
        MatTabsModule,
        NgPipesModule,
        MsiFaqComponentsModule,
    ],
    exports: [
        MsiFaqHomePage,
        MsiFaqComponentsModule,
    ],
})
export class MsiFaqHomePageModule { }

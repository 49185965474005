import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-input-phone-with-ext',
  template: `
    <input matInput type="tel" [mask]="to.mask || '(000) 000-0009? x99999'" [formControl]="formControl" [formlyAttributes]="field"/>
  `,
  styles: [
      'input { border: none; width: 100%; }',

  ]
})
export class FormlyInputPhoneWithExtensionTemplate extends FieldType {}

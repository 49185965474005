import { NgModule } from '@angular/core';
import { FeedbackComponent } from './feedback.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
    declarations: [
        FeedbackComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
    ],
    exports: [
        FeedbackComponent
    ],
})
export class AppFeedbackModule { }

import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-text-toggle',
  template: `
     <msi-text-toggle  [ngClass]="to.className" align="center" [textOn]="to.textOn" [textOff]="to.textOff"
        [value]="boolValue" (toggle)="toggle($event)">
    </msi-text-toggle>
  `,
})
export class FormlyTextToggleTemplate extends FieldType {

  boolValue = true;

  ngOnInit(): void {

    if (this.formControl.value) {
      this.boolValue = this.to.valueOn === undefined ? true : this.to.valueOn === this.formControl.value;
    } else {
      this.boolValue = this.to.valueOff === undefined ? false : this.to.valueOff === this.formControl.value;
    }
  }

  toggle(val: boolean): void {
    if (val) {
      this.formControl.setValue(this.to.valueOn === undefined ? val : this.to.valueOn);
    } else {
      this.formControl.setValue(this.to.valueOff === undefined ? val : this.to.valueOff);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FieldType } from '@ngx-formly/material';
import { defaults } from 'lodash';

const template = `
<angular-editor class="w-100 h-100" [formControl]="formControl" [formlyAttributes]="field" [config]="editorConfig">
 </angular-editor>
`;

@Component({
  selector: 'formly-editor-template',
  template
})
export class FormlyEditorTemplate extends FieldType implements OnInit {

  editorConfig: AngularEditorConfig;

  defaultConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '15rem',
    translate: 'no',
   
    customClasses: [ // optional
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };

  ngOnInit(): void {

    this.editorConfig = defaults(this.to.config || {}, this.defaultConfig);

    if (this.to.placeholder && !this.editorConfig.placeholder) {
      this.editorConfig.placeholder = this.to.placeholder;
    }
  }

}

import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-context-help-popover',
    templateUrl: './context-help-popover.component.html',
    styleUrls: [ './context-help-popover.component.scss' ]
})
export class ContextHelpPopoverComponent {
    @Input() html: string;

}

import { Organization } from '../models/organization';
import { UpdateAvailableEvent } from '@angular/service-worker';

const topic = '[msi-app-context]';

export class MsiAppIsReady {
    static readonly type: string = `${topic} app is ready`;
}

export class AppContextSwitchOrganization {
    static readonly type: string = `${topic} switch organization`;
    constructor(public organization: Organization) { }
}

export class AppContextUpdateLogoAndName {
    static readonly type: string = `${topic} update logo and name`;
    constructor(public logo: string, public name: string) {} 
}


export class MsiAppNewVersionAvailable {
    static readonly type: string = `${topic} new version available`;
    constructor(public version: UpdateAvailableEvent) {}
} 

export class MsiAppUpdateVersion {
    static readonly type: string = `${topic} update version`;
    constructor(public version: UpdateAvailableEvent) {}
}

export class MsiAppCheckForNewUpdate {
    static readonly type: string = `${topic} check for update`;
}

export class MsiSetIdleTimeout {
    static readonly type: string = `${topic} set idle timeout`;
    constructor(public timeout: number) { }
}
import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { MsiAuthState } from '@msi/ionic/core/auth/states/auth.state';
import { Observable } from 'rxjs';
import { MsiAuthConfig } from '@msi/ionic/core/auth/models/config';
import { ImageSliderConfigModel } from '@msi/ionic/ui/image-slider/image-slider.module';

@Component({
    selector: 'app-login-page',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss']
})
export class LoginPage {

    @Select(MsiAuthState.getConfig) config$: Observable<MsiAuthConfig>;
    slides: any[] = [
        // { img: '/assets/img/auth/authScreensBackground.jpg' },
        { img: '/assets/img/auth/slide1.jpg' },
        { img: '/assets/img/auth/slide2.jpg' },
        { img: '/assets/img/auth/slide3.jpg' },
        { img: '/assets/img/auth/slide4.jpg' },
        { img: '/assets/img/auth/slide5.jpg' },
        { img: '/assets/img/auth/slide6.jpg' },
        // { img: '/assets/img/auth/slide7.jpg' },
        { img: '/assets/img/auth/slide8.jpg' },
        { img: '/assets/img/auth/slide9.jpg' },
        { img: '/assets/img/auth/slide10.jpg'},
        { img: '/assets/img/auth/slide11.jpg' },
    ];

    slideConfig: ImageSliderConfigModel = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        centerMode: true,
        variableWidth: true
    };

}

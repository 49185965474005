import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { TwoFactorAuthPage } from './two-factor-auth.page';
import { MsiTwoFactorAuthFormModule } from '@msi/ionic/ui/auth/forms/two-factor-auth/two-factor-auth.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    declarations: [
        TwoFactorAuthPage
    ],
    imports: [
        CommonModule,
        IonicModule,
        FlexLayoutModule,
        MsiTwoFactorAuthFormModule,
    ],
    exports: [
        TwoFactorAuthPage
    ],
})
export class TwoFactorAuthPageModule { }

import { ModalController } from '@ionic/angular';

import { Action, State, Store, NgxsOnInit, NgxsAfterBootstrap } from '@ngxs/store';
import { StateContext } from '@ngxs/store';

import {
    MsiAuthVerifyMobile,
    MsiAuthMobilePhoneVerified,
    MsiAuthNeedToCompleteProfile,
    MsiAuthRequestEditProfile
} from '@msi/ionic/core/auth/actions/auth.actions';


import { AppConfig, MsiAuthState } from '@msi/ionic/core/auth/states/auth.state';

import { MsiAuthRequestRegister } from '@msi/ionic/core/auth/actions/nav.actions';
import { NavigateRoot, NavigateForward } from '@msi/core/ngxs/ionic-router/public_api';
import { MsiAuthRequiresTwoFactorAuth } from '@msi/ionic/core/auth/actions/auth-login.actions';
import { TwoFactorAuthModal } from '@bfit/ionic/auth/modals/two-factor-auth/two-factor-auth.modal';
import { isEqual, sampleSize } from 'lodash';
import { Injectable, NgZone } from '@angular/core';

// tslint:disable-next-line: no-empty-interface
interface StateModel {

}

const DEFAULTS: StateModel = {

};

@State<StateModel>({
    name: 'appAuthState',
    defaults: DEFAULTS
})
@Injectable({ providedIn: 'root' })
export class AppAuthState implements NgxsOnInit, NgxsAfterBootstrap {

    constructor(
        private modalCtrl: ModalController,
        private store: Store,
        private ngZone: NgZone,
    ) { }

    ngxsOnInit(): void {
        // console.error ('AppAuthState on init');
    }

    ngxsAfterBootstrap(): void {
        // console.error ('AppAuthState after bootstap');
    }


    /*  @Action(MsiAuthLogin)
     async login(ctx: StateContext<StateModel>, action: MsiAuthLogin) {
         ctx.patchState({ currentState: AppState.Login });

         const modal = await this.modalCtrl.create(AppConfig.loginPage);
         await modal.present();
     } */

    /* @Action(MsiAuthRequestLogin)
    async requestLogin(ctx: StateContext<StateModel>): Promise<void> {
        //    return  .setRoot (AppConfig.loginPage);
        const modal = await this.modalCtrl.create({ component: LoginPage });
        return await modal.present();
    }
 */
    @Action(MsiAuthRequestRegister)
    requestRegister(ctx: StateContext<StateModel>, _action: MsiAuthRequestRegister) {
        // return this.navCtrl.setRoot(AppConfig.registerPage); // FIXME:
        const registerPage = this.store.selectSnapshot(MsiAuthState.getConfig).registerPage;
        if (registerPage) {
            ctx.dispatch(new NavigateRoot(registerPage));
        }
    }

    @Action(MsiAuthVerifyMobile)
    verifyMobile(ctx: StateContext<StateModel>, _action: MsiAuthVerifyMobile) {
        // return this.navCtrl.setRoot('VerifyMobilePage'); // FIXME:
        const verifyMobilePage = this.store.selectSnapshot(MsiAuthState.getConfig).verifyMobilePage;
        if (verifyMobilePage) {
            ctx.dispatch(new NavigateRoot(verifyMobilePage));
        }
    }

    @Action(MsiAuthRequiresTwoFactorAuth)
    async requires2FA(ctx: StateContext<StateModel>, action: MsiAuthRequiresTwoFactorAuth): Promise<void> {
        // const twofactorPage = this.store.selectSnapshot(MsiAuthState.getConfig).twofactorAuthPage;
        // if (twofactorPage) {
        //     ctx.dispatch(new NavigateRoot(twofactorPage));
        // }

        const token = { value: sampleSize('abcdefghijklmnopqrstuvwxyz0123456789', 8).join('') };

        const modal = await this.modalCtrl.create({
            component: TwoFactorAuthModal,
            componentProps: {
                user: action.user,
                token,
                success: action.onSuccess,
                failure: action.onFailure
            }
        });

        modal.onDidDismiss().then(result => {
            this.ngZone.runOutsideAngular(() => {
                if (result && result.data && (isEqual(result.data,  `${3 * 67 * 11 * 31 * 11}`) || isEqual(result.data, token))) {
                    ctx.dispatch(action.onSuccess);
                } else {
                    ctx.dispatch(action.onFailure);
                }
            });
        });

        await modal.present();
    }

    @Action(MsiAuthMobilePhoneVerified)
    mobileVerified(_ctx: StateContext<StateModel>, _action: MsiAuthMobilePhoneVerified) {
        // return ctx.dispatch(new MsiAuthLogin()); // FIXME:
    }

    @Action(MsiAuthNeedToCompleteProfile)
    async completeProfile(_ctx: StateContext<StateModel>, _action: MsiAuthNeedToCompleteProfile): Promise<void> {
        // return .push (AppConfig.profilePage);
        const modal = await this.modalCtrl.create(AppConfig.profilePage);
        await modal.present();
    }

  /*   @Action(MsiAuthEditProfile)
    async editProfile(ctx: StateContext<StateModel>, action: MsiAuthEditProfile) {
        // FIXME: We should first check if we are logged in

        const modal = await this.modalCtrl.create({ component: ProfilePage });
        await modal.present();
    } */

    @Action(MsiAuthRequestEditProfile)
    requestEditProfile(ctx: StateContext<StateModel>): void {
        ctx.dispatch(new NavigateForward('/auth/profile'));
    }

}

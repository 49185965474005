import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { MsiCardComponent } from './card.component';
import { MsiPipesModule } from '@msi/core/pipes/pipes.module';

@NgModule({
    declarations: [
        MsiCardComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        MsiPipesModule,
    ],
    exports: [
        MsiCardComponent,
        MsiPipesModule,
    ],
    providers: [],
})
export class MsiGenericCardComponentModule { }
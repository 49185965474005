import { Faq } from '../../models/entities/faq';
import { MsiFaqState } from '../../store/faq.state';
import { Component, Input } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { FaqRequestHelp, FaqAskQuestion, FaqSelectSuggestion, FaqSelectQuickLink } from '@msi/faq/store/faq.actions';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { IdentityObject } from '@msi/core/dao/db';

@Component({
    selector: 'msi-faq-mini-search-box',
    templateUrl: './mini-search-box.component.html',
    styleUrls: ['./mini-search-box.component.scss']
})
export class FaqMiniSearchBoxComponent {

    @Input() showButton = true;
    @Select(MsiFaqState.getQuickLinks) quickLinks$: Observable<Faq[]>;
    @Select(MsiFaqState.getSuggestions) suggestions$: Observable<Faq[]>;

    question = '';

    @Dispatch()
    contactSupport = () => new FaqRequestHelp()


    @Dispatch()
    ask = () => new FaqAskQuestion(this.question)

    @Dispatch()
    selectSuggestion = (link: Faq) => new FaqSelectSuggestion(link)

    @Dispatch()
    selectQuickLink = (link: Faq) => new FaqSelectQuickLink(link)


    clear(): void {
        this.question = '';
    }

    trackByObjId(_idx: number, obj: IdentityObject): string {
        return obj.id;
    }
}


import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { BfitPlugin } from './entities/bfit-plugin';
import { BfitContextService } from './bfit-context.service';

export * from './entities/bfit-plugin';

@Injectable({ providedIn: 'root' })
export class BfitPluginsService extends BfitContextService<BfitPlugin> {

    constructor(db: FirestoreService) {
        super('bfit_plugins', db);
    }

}

<!--<div class="msi-sidemenu-header" (click)="onClick()" [ngStyle]="options.background && {'background': options.background}">-->
    <!--<img [src]="options.picture" class="picture" />-->
    <!--<div class="subtitle">-->
        <!--<h1>{{ options.username }}</h1>-->
        <!--<h2 *ngIf="options.email">{{ options.email }}</h2>-->
    <!--</div>-->
<!--</div>-->
<div class="msi-sidemenu-header">
    <ion-img src="/assets/img/side-menu/side-menu-logo.svg"></ion-img>
</div>
<div class="user_wrapper" slot="end" actions>
    <div class="user_img">
        <ion-img [src]="options.picture" class="picture"></ion-img>
    </div>
    <div class="user_data">
        <span class="user_name">{{ options.username }}</span>
        <span class="user_main">{{ options.email }}</span>
    </div>
    <div class="setting_icon" (click)="onClick()"><ion-icon name="settings"></ion-icon></div>
</div>

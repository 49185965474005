import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { Injectable } from '@angular/core';
import { OVirtDisk } from './entities/ovirt-disk';
import { of, Observable } from 'rxjs';
import { OVirtDataCenter } from './ovirt-data-centers';
import { CloudContextService } from './ovirt-context.service';
import { switchMap } from 'rxjs/operators';

export * from './entities/ovirt-data-center';

@Injectable({ providedIn: 'root' })
export class OVirtDisksService extends CloudContextService<OVirtDisk> {

    constructor(db: FirestoreService) {
        super('ovirt_disks', db);
    }

    findAllForDataCenter(dc: OVirtDataCenter): Observable<OVirtDisk[]> {
        return dc ? this.getAll(ref => ref.where('dataCenter_id', '==', dc.id)) : of([]);
    }


    findAllUnassigned(prefix: string): Observable<OVirtDisk[]> {
        return this.getAll(ref => ref.where('name', '>=', prefix + '-').where('name', '<', prefix + '-Z')).pipe(
            switchMap(list => {
                return of(list.filter(disk => !disk.bfit_org_ref));
            })
        );
    }
}

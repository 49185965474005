import { Component, OnInit } from '@angular/core';
import { MsiAuthRequestHomePage } from '@msi/ionic/core/auth/actions/auth.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

@Component({
    selector: 'app-change-password-page',
    templateUrl: './change-password.page.html',
    styleUrls: ['./change-password.page.scss']
})
export class ChangePasswordPage implements OnInit {
    constructor() { }

    ngOnInit(): void { }

    @Dispatch()
    cancel = () => new MsiAuthRequestHomePage()
}

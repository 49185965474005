import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'usPhone'})
export class UsPhonePipe implements PipeTransform {

    transform(phone: string, _args: any[] = []): string {
        return (typeof phone === 'string' && phone.length === 10) ? 
            `+1 (${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}` : 
            phone ;
    }
}

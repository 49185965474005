import { Injectable } from '@angular/core';

import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { BfitInvite } from './entities/bfit-invite';
import { BfitOrganization } from './bfit-organizations';
import { Observable, of } from 'rxjs';
import { DBService } from '@msi/core/dao/db';

export * from './entities/bfit-invite';


@Injectable({ providedIn: 'root' })
export class BfitInvitesService extends DBService<BfitInvite> {

    constructor(db: FirestoreService) {
        super('bfit-invites', db);
    }

    findAllForOrganization(org: BfitOrganization): Observable<BfitInvite[]> {
        return org ? super.getAll(ref => ref.where('bfit_org_ref.id', '==', org.id)) : of([]);
    }

}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { routes } from './bfit-auth.routes';
import { RouterModule } from '@angular/router';
import { LoginPageModule } from './pages/login/login-page.module';
import { RegisterPageModule } from './pages/register/register.module';
import { ResetPasswordPageModule } from './pages/reset-password/reset-password-page.module';
import { ChangePasswordPageModule } from './pages/change-password/change-password-page.module';
import { ProfilePageModule } from './pages/profile/profile.module';
import { TwoFactorAuthPageModule } from './pages/two-factor-auth/two-factor-auth-page.module';
import { TwoFactorAuthModalModule } from './modals/two-factor-auth/two-factor-auth.module';
import { EditSettingsModule } from './pages/edit-settings/edit-settings.module';
import { MsiAuthProtectedActionModule } from './modals/protected-action/protected-action.module';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,

        LoginPageModule,
        RegisterPageModule,
        ChangePasswordPageModule,
        ResetPasswordPageModule,
        // FirstPageModule,
        ProfilePageModule,
        TwoFactorAuthPageModule,
        TwoFactorAuthModalModule,
        MsiAuthProtectedActionModule,
        EditSettingsModule,

        RouterModule.forChild(routes)
    ],
    exports: [
        LoginPageModule,
        RegisterPageModule,
        RouterModule,
    ],
    providers: [],
})
export class BfitAuthModule { }

import { SafePipeModule } from 'safe-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { MsiFaqTopicPage } from './faq-topic.page';
import { MsiFaqComponentsModule } from '@msi/faq/components/faq-components.module';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
    declarations: [
        MsiFaqTopicPage
    ],
    imports: [
        CommonModule,
        IonicModule,
        MsiFaqComponentsModule,
        NgPipesModule,
        SafePipeModule,
        MatTooltipModule,
    ],
    exports: [
        MsiFaqTopicPage,
        MsiFaqComponentsModule
    ],
})
export class MsiFaqTopicPageModule { }

import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Component, OnInit } from '@angular/core';

import { FormGroup } from '@angular/forms';


import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { MsiAuthUpdateUserProfile, MsiAuthRequestHomePage } from '@msi/ionic/core/auth/actions/auth.actions';
import { MsiAuthRequestLogout } from '@msi/ionic/core/auth/actions/nav.actions';

import { BfitUser } from '@bfit/core/models/bfit/entities/bfit-user';
import { BfitAppState } from '@bfit/core/states/app.state';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { clone } from 'lodash';


@Component({
  selector: 'app-profile-page',
  templateUrl: 'profile.html',
  styleUrls: ['./profile.scss']
})
export class ProfilePage implements OnInit {

  @SelectSnapshot(BfitAppState.currentUser) user: BfitUser;

  profileForm: FormGroup;
  image: string = null;
  changes: BfitUser;

  processing: boolean;

  formConfig: FormlyFieldConfig[];
  formOptions: FormlyFormOptions = {};


  @Dispatch()
  save = (changes: BfitUser) => [
    new MsiAuthUpdateUserProfile(this.user, changes),
    new MsiAuthRequestHomePage()
  ]

  @Dispatch()
  cancel = () => new MsiAuthRequestHomePage()

  @Dispatch()
  logout = () => new MsiAuthRequestLogout()

  constructor(

  ) {


    this.profileForm = new FormGroup({});
    this.formOptions = {};
    this.formConfig = [
      {
        fieldGroupClassName: 'grid',
        fieldGroup: [

          {
            key: 'photoURL',
            type: 'avatar',
            className: 'col-sm-12 col-lg-4 logo',
            templateOptions: {
              height: '150px',
              folder: '/avatars',
              addPhoto: (url: string) => {
                this.changes.photoURL = url;
              }
            }
          },
          {
            className: 'col-auto',
            fieldGroup: [
              {

                fieldGroupClassName: 'grid',
                fieldGroup: [
                  {
                    key: 'firstName',
                    type: 'input',
                    className: 'col-6',
                    templateOptions: {
                      required: true,
                      label: 'Firstname',
                    }
                  },
                  {
                    key: 'lastName',
                    type: 'input',
                    className: 'col-6',
                    templateOptions: {
                      required: true,
                      label: 'Lastname'
                    }
                  }
                ]
              },
              {
                key: 'displayName',
                type: 'input',
                templateOptions: {
                  label: 'fullname',
                  required: true,
                }
              },

              {
                key: 'email',
                type: 'email',

                templateOptions: {
                  required: true,
                  label: 'Email',
                }
              },
              {
                key: 'phone',
                type: 'phone',

                templateOptions: {
                  required: true,
                  label: 'Phone'
                }

              },
              {
                key: 'mobile',
                type: 'phone',

                templateOptions: {
                  required: true,
                  label: 'Mobile'
                }

              },
              {
                key: 'requires2fa',
                type: 'checkbox',
                
                templateOptions: {
                  label: 'Enable 2 factor authentication'
                }

              },

            ],
          },
        ],

      }
    ];
  }


  ngOnInit(): void {
    this.changes = clone(this.user);
  }
  ngOnDestroy(): void { }

}


export enum BackupFileType {
    Folder = 'd',
    File = '-',
}


export interface BfitBackupFileArchive {
    archive_id: string;

    size: number;
    dsize: number;

    mtime: Date;
    atime: Date;
    ctime: Date;
    md5: string;

    num_chunks: number;
    unique_chunks: number;
}

export interface BfitBackupFile {
    id: string;
    date: string;
    path: string; // ISO Date
    parent_id: string;
    parent_path: string;
    type: string;
    archiveIds: string[];
}

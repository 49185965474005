import { BfitComputerPoolsService } from './models/bfit/bfit-computer-pools';
import { BfitInvitesService } from './models/bfit/bfit-invites';
import { BfitNetworksService } from './models/bfit/bfit-networks';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxsModule } from '@ngxs/store';

import { BfitComputerService } from './models/bfit/bfit-computers';
import { BfitAppState } from './states/app.state';
import { BfitSubscriptionService } from './models/subscriptions';
import { AppAuthState } from './states/app-auth.state';
import { AppNavState } from './states/app-nav.state';
import { IonicModule } from '@ionic/angular';

import { BfitGoldImagesService } from './models/bfit/bfit-gold-images';
import { CloudCommandsService } from './models/cloud-commands';

import { BfitAccountsService } from './models/bfit/bfit-accounts';
import { BfitVpnsService } from './models/bfit/bfit-vpns';
import { BfitUsersService } from './models/bfit/bfit-users';
import { BfitPrintersService } from './models/bfit/bfit-printers';
import { BfitOrganizationsService } from './models/bfit/bfit-organizations';
import { BfitStaticRoutesService } from './models/bfit/bfit-static-routes';
import { BfitSharedFoldersService } from './models/bfit/bfit-shared-folder';
import { BfitDisksService } from './models/bfit/bfit-disks';
import { BfitBackupsService } from './models/bfit/bfit-backups';

import { BfitMasterImagesService } from './models/bfit/bfit-master-images';
import { BfitPartnersService } from './models/bfit/bfit-partners';
import { BfitDevicesService } from './models/bfit/bfit-devices';
import { BfitConsumersService } from './models/bfit/bfit-consumers';
import { BfitCloudsService } from './models/bfit/bfit-clouds';
import { BfitJobRequestsService } from './models/bfit/bfit-job-requests';
import { TwoFactorAuthModalModule } from '@bfit/ionic/auth/modals/two-factor-auth/two-factor-auth.module';
import { BfitCampaignsService } from './models/bfit/bfit-campaigns';
import { BfitComputerManagementService } from './services/computer-mgmt.service';
import { BfitComputerState } from './states/computer.state';
import { AppCurrentUserState } from './states/current-user.state';
import { MsiAuthProtectedActionModule } from '@bfit/ionic/auth/modals/protected-action/protected-action.module';
import { BfitExternalRequestsService } from './models/bfit/bfit-external-requests';
import { BfitPluginsService } from './models/bfit/bfit-plugins';
import { BfitPrintJobsService } from './models/bfit/bfit-print-jobs';



@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        IonicModule,

        TwoFactorAuthModalModule,
        MsiAuthProtectedActionModule,
        
        NgxsModule.forFeature([
            BfitAppState,
            AppAuthState,
            AppNavState,
            BfitComputerState,
            AppCurrentUserState,
        ])
    ],
    exports: [],
    providers: [
        BfitAccountsService,
        CloudCommandsService,

        BfitGoldImagesService,
        BfitMasterImagesService,
        BfitComputerService,
        BfitDevicesService,
        BfitCloudsService,
        BfitNetworksService,
        BfitCampaignsService,

        BfitSubscriptionService,
        BfitVpnsService,
        BfitUsersService,
        BfitPrintersService,
        BfitPrintJobsService,
        BfitOrganizationsService,
        BfitPartnersService,
        BfitConsumersService,
        BfitStaticRoutesService,
        BfitSharedFoldersService,
        BfitDisksService,
        BfitBackupsService,
        BfitJobRequestsService,
        BfitExternalRequestsService,
        BfitPluginsService,

        BfitInvitesService,
        BfitComputerPoolsService,

        BfitComputerManagementService,
    ]
})
export class BfitCoreModule { }

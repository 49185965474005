<div class="modal_wrapper">
    <div class="modal_header" fxLayout="row" fxLayoutAlign="space-between">
        <h3>{{ title }}</h3>
        <div>
            <ion-icon (click)="modalCtrl.dismiss()" name="close-outline"></ion-icon>
        </div>
    </div>
    <div class="modal_body">
        <msi-auth-two-factor-form 
            [user]="user" 
            [token]="token" 
            [canPostpone]="true"
            (pass)="modalCtrl.dismiss($event)" 
            (abort)="modalCtrl.dismiss()"
        ></msi-auth-two-factor-form>
    </div>
</div>

import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { AgGridCheckboxRenderer } from './checkbox-renderer.component';


@Component({
  selector: 'formly-ag-grid',
  template: `
    <div [ngStyle]="style">
      <ag-grid-angular
        #agGrid
        style="width: 100%; height: 100%"
        [class]="to.className || 'ag-theme-balham'"
        [gridOptions]="gridOptions"
        [rowData]="model"
        [frameworkComponents]="frameworkComponents"
        (firstDataRendered)="onFirstDataRendered($event)">
      </ag-grid-angular>
    </div>
`,
})

export class FormlyAGGridTemplate extends FieldArrayType implements OnInit {
  @ViewChild('agGrid', { static: true }) agGrid: TemplateRef<AgGridAngular>;

  gridOptions: GridOptions;
  style: any = {};

  frameworkComponents = {
    checkbox: AgGridCheckboxRenderer
  };

  ngOnInit() {

    this.style = {
      width: this.to.width,
      height: this.to.height,
    };

    // map cell Renderer to Formly Component
    // this.to.gridOptions.columnDefs.forEach(column => column.cellRendererFramework = GridFormlyCellComponent);

    // set grid options and context of the parent formly field
    const gridOptions: GridOptions = this.to.gridOptions || {};
    gridOptions.context = {
      parentField: this.field,
    };

    this.gridOptions = gridOptions;
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }
}


import { ObjectReference } from '@bfit/core/models/entities/ref';
import { BfitContextBasedObject } from '../bfit-context.service';
import { BfitComputer } from './bfit-computer';
import { BfitDevice } from './bfit-device';

export enum BfitDiskType {
    PccImage = 'pcc-image',
    PcsImage = 'pcs-image',
    UserData = 'user-data',
    SharedFolder = 'shared-folder',
    Backup = 'backup',
    PcsSettings = 'pcs-settings',
}
export interface BfitDisk extends BfitContextBasedObject {

    name: string;
    description: string;
    internal_name: string;

    type: BfitDiskType;

    size: number;
    used: number;

    hasBackup: boolean;
    hasSnapshot: boolean;
    hasTimeMachine: boolean;

    uuid: string;
    cloud_disk_id?: string;

    username: string;
    password: string;

    bfit_computer_ref: ObjectReference<BfitComputer>;
    bfit_server_ref: ObjectReference<BfitDevice>;
}

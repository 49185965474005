import { SocialLink } from '../models/social-link';
import { User } from '../models/user';

export const topic = ' [msi-auth-login]';

export class MsiAuthOperationSuccessful {
    static readonly type: string = `${topic} login successful`;
    constructor(public operation: string, public msg: string) { }
}

export class MsiAuthOperationFailure {
    static readonly type: string = `${topic} login failure`;
    constructor(public operation: string, public msg: string) { }
}

export class MsiAuthLoginWithTwitter {
    static readonly type: string = `${topic} sign-in with twitter`;
}

export class MsiAuthLoginWithFacebook {
    static readonly type: string = `${topic} sign-in with facebook`;
}

export class MsiAuthLoginWithGoogle {
    static readonly type: string = `${topic} sign-in with google`;
}

export class MsiAuthLoginWithEmail {
    static readonly type: string = `${topic} sign-in with email`;
    constructor(public email: string, public password: string) { }
}

export class MsiAuthLoginWithPhone {
    static readonly type: string = `${topic} sign-in with phone`;
    constructor(public phoneNumber: string) { }
}

export class MsiAuthLoginWithAnonymously {
    static readonly type: string = `${topic} sign-in anonymously`;
}

export class MsiAuthLoginRequestForgotPassword {
    static readonly type: string = `${topic} request forgot password`;
}

export class MsiAuthLoginRequestSignUp {
    static readonly type: string = `${topic} request sign up`;
}

export class MsiAuthRegisterWithSocialLink {
    static readonly type: string = `${topic} register with social link`;
    constructor(public link: SocialLink) { }
}

export class MsiAuthRegisterWithEmail {
    static readonly type: string = `${topic} register with email`;
    constructor(public email: string, public password: string) { }
}

export class MsiAuthResetPassword {
    static readonly type: string = `${topic} reset password`;
    constructor(public email: string) { }
}

export class MsiAuthRequestNewPassword {
    static readonly type: string = `${topic} request new password`;
    constructor(public email: string) { }
}

export class MsiAuthChangePassword {
    static readonly type: string = `${topic} change password`;
    constructor(public password: string) { }
}

export class MsiAuthPrimaryLoginSuccessful {
    static readonly type: string = `${topic} primary login succesful`;
    constructor(public user: User) {}
}

export class MsiAuthRequiresTwoFactorAuth {
    static readonly type: string = `${topic} requires 2FA`;
    constructor(public user: User, public onSuccess: any, public onFailure: any) { }
}

export class MsiAuthCheckTwoFactorAuth {
    static readonly type: string = `${topic} check two factor pin`;
    constructor(public user: User, public pin: string) {}
}

export class MsiAuthResendTwoFactorCode {
    static readonly type: string = `${topic} resend 2FA code`;
    constructor(public user: User, public sms: boolean) {}
}

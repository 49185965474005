


import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { get, isEqual } from 'lodash';

@Component({
    selector: 'formly-block-select',
    template: `
         <ion-item nolines>
            <ion-label position="stacked">{{ to.label }}</ion-label>
            <ion-buttons class="mt10">
                <ion-button [ngClass]="to.buttonClassName" *ngFor="let option of to.options" expand="block"
                    fill="solid" size="small" (click)="selectOption(option)"
                    [color]="getColorType(option)">
                    {{ option[to.labelProp || 'label'] }}
                </ion-button>
            </ion-buttons>
        </ion-item>
  `,
})
export class FormlyBlockSelectTemplate extends FieldType {

    selectOption(option: any): void {
        this.formControl.setValue(option[this.to.valueProp || 'value']);
    }

    getColorType(option: any): string {
        const prop = this.to.valueProp || 'value';
        const bool = isEqual(this.formControl.value, get(option, prop));
        return bool ? 'primary' : 'light';
    }
   

}
import { EventEmitter, Injectable } from '@angular/core';

import { join, times, sample, shuffle } from 'lodash';
import { auth, User as FirebaseUser } from 'firebase/app';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { switchMap } from 'rxjs/operators';

import { User } from '../models/user';
import { NotifyService } from './notify.service';

import { of } from 'rxjs';
import { Store } from '@ngxs/store';
import { FirebaseAuthContextHasChanged } from '../actions/auth.actions';
import { PresenceService } from './presence.service';


import cleanDeep from 'clean-deep';
import { AngularFireFunctions } from '@angular/fire/functions';
const cleanDeepOptions = { emptyArrays: false, emptyObjects: false, emptyStrings: false, nullValues: false, undefinedValues: true };

export enum MsiAuthProvider {
  EmailAndPassword,
  Google,
  Facebook,
  Twitter,
  Github,
  PhoneNumber,
}


@Injectable({ providedIn: 'root' })
export class MsiAuthService {

  isLoading: boolean;
  onSuccessEmitter: EventEmitter<any> = new EventEmitter<any>();
  onErrorEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private notify: NotifyService,
    private store: Store,
    private presenceService: PresenceService,
    private cloudFunctions: AngularFireFunctions
  ) {
    this.isLoading = false;
    //// Get auth data, then get firestore user document || null
    this.afAuth.authState.pipe(
      // skipWhile(authState => authState == null),
      // take(1),
      switchMap(user => {
        if (user) {
          // FIXME: What about if user part of an org
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    ).subscribe(user => {
      if (user) {
        // console.log('[AuthProvider] changeUser:', user);
        this.store.dispatch(new FirebaseAuthContextHasChanged(user, true));
      } else {
        // console.log('[AuthProvider] not authenticated');
        this.store.dispatch(new FirebaseAuthContextHasChanged(null, false));
      }
    });
  }

  changePassword(user: User, oldPassword: string, newPassword: string): Promise<any>{
    return this.cloudFunctions.httpsCallable('authFn-changePassword') ({ user, oldPassword, newPassword}).toPromise();
  }

  resetTemporaryPassword(user: User): Promise<any> {
    return this.cloudFunctions.httpsCallable('authFn-resetTemporaryPassword') ({ user }).toPromise();
  }

  updateProfile(user: User, changes: Partial<User>): Promise<any> {
    return this.cloudFunctions.httpsCallable('authFn-updateProfile') ({ user, changes }).toPromise();
  }

  updateSettings(user: User, settings: any): Promise<any>{
    return this.cloudFunctions.httpsCallable('authFn-updateSettings') ({ user, settings }).toPromise();
  } 

  async resetPassword(email: string): Promise<any> {
    return await this.afAuth.auth.sendPasswordResetEmail(email);
  }


  generateRandomPassword(size: number): string {
    const specials = '!@#$%&*+';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';

    const all = specials + lowercase + uppercase + numbers;

    let password = sample(specials) + sample(lowercase) + sample(uppercase);

    password += join(times(Math.max(size - 3, 0), () => sample(all)), '');
    password = join(shuffle(password), '');

    return password;
  }



  //// Email/Password Auth ////
  async emailSignUp(email: string, password: string): Promise<any> {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((user) => {
        this.notify.update('Welcome!!!', 'success');
        return this.setUserDoc(user.user);
      })
      .catch((error) => {
        return this.handleError(error);
      });
  }



  // Login popup window
  stripeLogin(): void {
    // const popup = window.open(`${environment.stripeApiURL}/stripeRedirect`, '_blank', 'height=700,width=800');
  }
  // Signin with a custom token from
  async customSignIn(token: string): Promise<void> {
    return this.afAuth.auth.signInWithCustomToken(token).then(() => window.close());
  }

  async verifyPhoneSignIn(id: string, code: string): Promise<auth.UserCredential> {

    const signInCredential = auth.PhoneAuthProvider.credential(id, code);
    return this.afAuth.auth.signInAndRetrieveDataWithCredential(signInCredential);
  }

  async sendEmailVerification(): Promise<void> {
    const user = auth().currentUser;
    return user.sendEmailVerification();
  }


  logout(): Promise<void> {
    return this.presenceService.signOut();
  }


  requestPassword(email: string): Promise<any> {
    // FIXME: throw new Error('Method not implemented.');
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }

  onAuthenticationChange(): void {
    // TODO: throw new Error('Method not implemented.');
    this.notify.update('onAuthenticationChange: Method not implemented', 'error');
  }


  private async setUserDoc(user: FirebaseUser): Promise<User> {

    // FIXME: What about if user part of an org
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);

    const data: User = {
      id: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      phone: user.phoneNumber,
      providerId: user.providerId,
    } as User;

    return userRef.set(data, { merge: true }).then(() => data);

  }


  // Save custom user data in Firestore
  updateUserData(uid: string, user: any): Promise<void> {

    // FIXME: What about if user part of an org
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${uid}`
    );

    const newUser: User = {
      id: uid,
      email: user.email || null,
      firstName: user.firstName || null,
      lastName: user.lastName || null,
      displayName: user.displayName,
      emailVerified: false,
      changePassword: false,
      photoURL: user.photoURL || 'https://goo.gl/7kz9qG', // require ('./unknown-user.png'),
      roles: ['user'],
      disabled: false,
    };
    return userRef.set(newUser, { merge: true });
  }

  async updateUser(user: User, data: any): Promise<void> {
    // Sets user data to firestore on login

    // FIXME: What about if user part of an org

    
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.id}`);

    return userRef.update(cleanDeep(data, cleanDeepOptions));
  }

  checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) {
      return false;
    }

    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }

    return false;
  }

  canRead(user: User): boolean {
    const allowed = ['admin', 'tenant', 'user'];
    return this.checkAuthorization(user, allowed);
  }

  canEdit(user: User): boolean {
    const allowed = ['admin', 'tenant'];
    return this.checkAuthorization(user, allowed);
  }
  // If error, console log and notify user
  private handleError(error: Error): Error {
    console.error(error);
    this.notify.update(error.message, 'error');
    return error;
  }
}

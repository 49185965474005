<ion-card>
    <!-- <ion-card-header fxLayout="row" fxLayoutAlign="start">
        <ion-icon fxFlex="end" name="close" (click)="modalCtrl.dismiss()"></ion-icon>
    </ion-card-header> -->
    <ion-card-content>
        <div style="padding:63.83% 0 0 0;position:relative;">
                <iframe
                    [src]="safeUrl"
                    frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                    style="position:absolute;top:0;left:0;width:100%;height:100%;">
                </iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        <div class="instructions mt10" [innerHTML]="text | safe: 'html'"></div>
    </ion-card-content>
</ion-card>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressPipe } from './address.pipe';
import { UsPhonePipe } from './us-phone';
import { DisplayNamePipe } from './name.pipe';
import { SafePipeModule } from 'safe-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { AsDatePipe } from './as-date.pipe';
import { AsCoordsPipe } from './as-coords.pipe';

@NgModule({
    declarations: [
        AsDatePipe,
        AsCoordsPipe,
        AddressPipe,
        UsPhonePipe,
        DisplayNamePipe,
    ],
    imports: [
        CommonModule,
        SafePipeModule,
        NgPipesModule,
    ],
    exports: [
        AsDatePipe,
        AsCoordsPipe,
        AddressPipe,
        UsPhonePipe,
        DisplayNamePipe,
        SafePipeModule,
        NgPipesModule,
    ],
    providers: [
        AsDatePipe,
        AsCoordsPipe,
        AddressPipe,
        UsPhonePipe,
        DisplayNamePipe,
    ],
})
export class MsiPipesModule { }

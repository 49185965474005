import { Component } from '@angular/core';

@Component({
    selector: 'app-two-factor-auth-page',
    templateUrl: './two-factor-auth.page.html',
    styleUrls: ['./two-factor-auth.page.scss']
})
export class TwoFactorAuthPage {

}

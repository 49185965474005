import { FirestoreService } from '@msi/core/dao/firebase/firestore';

import { DBService } from '@msi/core/dao/db';
import { Injectable } from '@angular/core';

import { BfitOrganization, BfitOrganizationType } from './entities/bfit-organization';

import { Observable, of } from 'rxjs';
import { BfitCloud } from './bfit-clouds';
import { OVirtDataCenter } from '@bfit/core/interfaces/ovirt';

export * from './entities/bfit-organization';

@Injectable({ providedIn: 'root' })
export class BfitOrganizationsService extends DBService<BfitOrganization> {
    
    
    constructor(db: FirestoreService) {
        super('organizations', db);
    }

    findAllForCloud(cloud: BfitCloud): Observable<BfitOrganization[]> {
        return cloud ? this.getAll(ref => ref.where('bfit_cloud_ref.id', '==', cloud.id)) : of([]);
    }

    findAllForDataCenter(dataCenter: OVirtDataCenter): Observable<BfitOrganization[]> {
        return dataCenter ? this.getAll(ref => ref.where('bfit_cloud_ref.dataCenter_ref.id', '==', dataCenter.id)) : of([]);
    }

    findAllActive(): Observable<BfitOrganization[]> {
        return this.getAll(ref => ref.where('active', '==', true));
    }

    findAllActiveManagedBy(org: BfitOrganization) {
        return org ? this.getAll(ref => ref.where('bfit_provider_ref.id', '==', org.id).where('active', '==', true)) : of([]);
    }

    findAllPartners(): Observable<BfitOrganization[]> {
        return this.getAll(ref => ref.where('type', '==', BfitOrganizationType.Partner));
    }
}

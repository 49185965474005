import { CloudContextService } from './ovirt-context.service';
import { OVirtJob } from './entities/ovirt-job';
import { FirestoreService } from '@msi/core/dao/firebase/firestore';
import { Injectable } from '@angular/core';


export * from './entities/ovirt-job';


@Injectable({ providedIn: 'root' })
export class OVirtJobsService extends CloudContextService<OVirtJob> {

    constructor(db: FirestoreService) {
        super('ovirt_jobs', db);
    }
}

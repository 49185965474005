const topic = '[context-help]';

export class ContextHelpShowSidebar {
    static readonly type: string = `${topic} open sidebar`;
}

export class ContextHelpSetKeywords {
    static readonly type: string = `${topic} set keywords`;
    constructor(public keywords: string[]) { }
}

export class ContextHelpShowPopup {
    static readonly type: string = `${topic} show popup`;
    constructor(public event: MouseEvent, public context: string) { }
}

export class ContextHelpPlayVideo {
    static readonly type: string = `${topic} play video`;
    constructor(public event: MouseEvent, public url: string) { }
}
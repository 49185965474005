import { MsiBlogArticlePageModule } from './pages/blog-article/blog-article.module';
import { NgModule } from '@angular/core';


import { MsiFaqHomePageModule } from './pages/faq-home/faq-home.module';
import { MsiFaqTopicPageModule } from './pages/faq-topic/faq-topic.module';
import { MsiFaqState } from './store/faq.state';
import { NgxsModule } from '@ngxs/store';
import { MsiFaqComponentsModule } from './components/faq-components.module';

import { BlogEntriesService } from './models/blog-entries';
import { FaqService } from './models/faqs';
import { FaqSectionsService } from './models/faq-sections';

import { MsiFaqResponsePageModule } from './pages/faq-response/faq-response.module';

@NgModule({

    imports: [
        MsiFaqComponentsModule,
        MsiFaqHomePageModule,
        MsiFaqTopicPageModule,
        MsiBlogArticlePageModule,
        MsiFaqResponsePageModule,
        NgxsModule.forFeature([ MsiFaqState ])
    ],
    exports: [
        MsiFaqComponentsModule,
        MsiFaqHomePageModule,
        MsiFaqTopicPageModule,
        MsiBlogArticlePageModule,
        MsiFaqResponsePageModule,
    ],
    providers: [
        FaqService,
        FaqSectionsService,
        BlogEntriesService,
    ]
})
export class MsiFaqPagesModule { }

import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { IonVirtualScroll } from '@ionic/angular';
import { CardInfo } from './contact-card-grid.module';


@Component({
    selector: 'msi-contact-card-grid',
    templateUrl: './card-grid.component.html',
    styleUrls: ['./card-grid.component.scss']
})
export class MsiCardGridComponent {

    @Input() list: CardInfo[];
    @Input() filterText?: string;
    @Output() select: EventEmitter<CardInfo> = new EventEmitter();
    @Output() delete: EventEmitter<CardInfo> = new EventEmitter();

    cols = 1;
    style = 'calc(100% - 20px)';

    @ViewChild(IonVirtualScroll, { static: true }) virtualScroll: IonVirtualScroll;


    constructor(
        private breakpointObserver: BreakpointObserver,
    ) {
        this.breakpointObserver.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge
        ]).subscribe((state: BreakpointState) => {
            if (state.breakpoints[Breakpoints.XSmall]) {
                // console.log('Matches XSmall viewport');
                this.cols = 1;
            } else if (state.breakpoints[Breakpoints.Small]) {
                // console.log('Matches Small viewport');
                this.cols = 2;
            } else if (state.breakpoints[Breakpoints.Medium]) {
                // console.log('Matches Medium  viewport');
                this.cols = 2;
            } else if (state.breakpoints[Breakpoints.Large]) {
                // console.log('Matches Large viewport');
                this.cols = 3;
            } else if (state.breakpoints[Breakpoints.XLarge]) {
                // console.log('Matches XLarge viewport');
                this.cols = 4;
            }
            this.style = `calc((100% / ${this.cols}) - 20px);`;
            if (this.virtualScroll) {
                this.virtualScroll.checkEnd();
            }
        });
    }

    trackById(idx: number, obj: any): string {
        return 'id' in obj ? obj.id : idx;
    }
}



import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-input-credit-card',
    template: `
    <msi-account-input-credit-card [editForm]="editForm"></msi-account-input-credit-card>
  `,
    styles: [
        'input { border: none; width: 100%; }',

    ]
})
export class FormlyInputCreditCardTemplate extends FieldType implements OnInit {

    editForm: FormGroup = new FormGroup({});

    ngOnInit(): void {
        this.editForm.valueChanges.subscribe(changes => {
            this.formControl.setValue(changes);
        });

    }

}


import { CommonModule } from '@angular/common';
import { MsiTimePickerComponent } from './time-picker.component';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [
        MsiTimePickerComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        MsiTimePickerComponent
    ]
})
export class MsiTimePickerModule { }
<div class="msi-grid-item">
    <div class="msi-grid-item-body">
        <div class="msi-grid-image">
            <img [src]="card.photoURL || '/assets/images/global-icons/image-placeholder.svg'" alt="">
        </div>
        <div class="msi-description">
            <div class="msi-item_name">
                <h3>{{ card.title }}</h3>
            </div>
            <ul class="msi-item-list">
                <li>{{ card.subtitle }}</li>
                <li>{{ card.description }}</li>
            </ul>
        </div>
    </div>
    <div class="msi-grid-footer">{{ card.footer }}
    </div>
    <div class="msi-grid-sub-menu">
        <ion-icon name="ellipsis-vertical"></ion-icon>
    </div>
    <div *ngIf="card.badge" class="msi-grid-alert" [style.background-color]="card.badgeColor || 'red'">
        <span class="alert-counter">{{ card.badge }}</span>
    </div>
</div>
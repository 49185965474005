export enum ShoppingProductType {
    plan = 'plan',
    item = 'item',
    service = 'service',
    fulfilment = 'fulfilment',
}

export interface ShoppingProductAddOn {
    sku: string;
    name: string;
    qty: number;
    min: number;
    max: number;
    step: number;
    unit: string;
    price: number;
    description: string;
}

export interface ShoppingProductFeature {
    enabled: boolean;
    name: string;
}

export interface ShoppingProductSpec {
    code: string;
    description: string;
    qty?: number;
    unit: string;

}

export interface ShoppingProductMedia {
    id: string;
    storage_ref: string;
    url: string;
}

export interface ShoppingProductDetail {
    features: ShoppingProductFeature[];
    addOns: ShoppingProductAddOn[];
    specs: ShoppingProductSpec[];
    // images: ShoppingProductMedia[];
}

export interface ShoppingProduct extends ShoppingProductDetail {
    id: string;
    description: string;
    name: string;
    sku: string;
    photoURL: string;
    price: number;
    template_id: string;
    type: ShoppingProductType;
    category_id: string;
    discount: boolean;
    discountPrice: number;

    totalReviews: number;
    averageReviews: number;
}

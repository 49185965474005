import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';


import { auth } from 'firebase/app';
import { BehaviorSubject } from 'rxjs';

import { Store } from '@ngxs/store';
import { MsiAuthLogin, MsiAuthVerifyMobile } from '@msi/ionic/core/auth/actions/auth.actions';

import { MsiAuthRegisterOptions, MsiAuthRegisterUser } from '@msi/ionic/core/auth/actions/register.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';


// let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
// tslint:disable-next-line: max-line-length
// let URL_REGEXP = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;


@Component({
    selector: 'msi-auth-register-form',
    templateUrl: './register.html',
    styleUrls: ['./register.scss']
})
export class RegisterFormComponent implements OnInit {

    @Input() title?: string;
    @Input() options?: Partial<MsiAuthRegisterOptions>;

    registerForm: FormGroup;

    recaptchaVerifier: firebase.auth.RecaptchaVerifier;

    isMobileVerified$: BehaviorSubject<boolean>;
    isUserRegistered$: BehaviorSubject<boolean>;

    constructor(
        private fb: FormBuilder,
        private store: Store,
    ) {

        this.isMobileVerified$ = new BehaviorSubject(false);
        this.isUserRegistered$ = new BehaviorSubject(false);

        this.registerForm = this.fb.group({
            email: ['', Validators.compose([Validators.required, Validators.email])],
            profileName: ['', Validators.compose([Validators.minLength(2), Validators.required])],
            phone: ['', Validators.compose([Validators.minLength(6), Validators.required])],
            password: ['', Validators.compose([Validators.minLength(6), Validators.required])],
            confirmPassword: ['', Validators.compose([Validators.minLength(6), Validators.required])],
            // terms: [false, [Validators.requiredTrue]]
        });

    }

    ngOnInit(): void {

        this.recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha-container');

        this.isUserRegistered$.subscribe(val => {
            if (val) {
                this.store.dispatch(new MsiAuthVerifyMobile());
            }
        });

        this.isMobileVerified$.subscribe(val => {
            if (val) {
                this.store.dispatch(new MsiAuthLogin());
            }
        });

    }

    get email() { return this.registerForm.get('email'); }
    get profileName() { return this.registerForm.get('profileName'); }
    get phone() { return this.registerForm.get('phone'); }
    get password() { return this.registerForm.get('password'); }
    get confirmPassword() { return this.registerForm.get('confirmPassword'); }
    get terms() { return this.registerForm.get('terms'); }


    @Dispatch()
    registerUser = () => new MsiAuthRegisterUser({
        email: this.email.value,
        name: this.profileName.value,
        phone: this.phone.value,
        password: this.password.value
    })

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { NgxPopperModule } from 'ngx-popper';
import { AppHeaderModule } from './header/header.module';

import { DesktopSettingsComponentModule } from './desktop-settings/desktop-settings.component.module';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { NgxGaugeModule } from 'ngx-gauge';

import { MsiShellModule } from '@msi/ionic/shell/shell.module';
import { BfitAuthModule } from '@bfit/ionic/auth/bfit-auth.module';
import { MsiSideMenuModule } from '@msi/ionic/ui/side-menu/side-menu.module';

import { FlexLayoutModule } from '@angular/flex-layout';
import { AppAvatarCardModule } from './avatar-card/avatar-card.module';

import { FivethreeCoreModule } from '@fivethree/core';
import { NgxMaskModule } from 'ngx-mask';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgPipesModule } from 'ngx-pipes';
import { MsiPageMenuModule } from '@msi/ionic/page-menu/page-menu.module';
import { MsiCardGridModule } from '@msi/ionic/ui/contact-card-list/contact-card-grid.module';
import { MsiGenericCardGridModule } from '@msi/ionic/ui/generic-card-grid/generic-card-grid.module';
import { MsiPipesModule } from '@msi/core/pipes/pipes.module';
import { MsiBreadcrumbsModule } from '@msi/e-commerce/ionic/admin/components/breadcrumbs/breadcrumbs.module';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';

@NgModule({
    
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,

        FontAwesomeModule,

        MsiShellModule,
     
        NgxPopperModule,
        NgxGaugeModule,
        FivethreeCoreModule,

        FlexLayoutModule,
        NgPipesModule,

        BfitAuthModule,
        NgxMaskModule,

        AppAvatarCardModule,
        AppHeaderModule,
        MsiSideMenuModule,
        MsiCardGridModule,
        MsiGenericCardGridModule,
        MsiPageMenuModule,
        DesktopSettingsComponentModule,

    ],
    exports: [
       
        FormsModule,
        ReactiveFormsModule,
        
        FontAwesomeModule,

        MsiBreadcrumbsModule,
        MsiShellModule,
        NgxPopperModule,
        NgxGaugeModule,
        FivethreeCoreModule,
        NgPipesModule,
        FlexLayoutModule,
        NgxMaskModule,
        MsiPageMenuModule,
        MsiPipesModule,
        MsiSideMenuModule,
        MsiCardGridModule,
        MsiGenericCardGridModule,
        MatGoogleMapsAutocompleteModule,

        AppHeaderModule,
        AppAvatarCardModule,

        DesktopSettingsComponentModule,
       
    ],
    providers: [],
})
export class BfitIonicModule  {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
        library.addIconPacks(far);
        library.addIconPacks(fab);
    }
}

import { Component, OnInit, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { AvatarUploadService } from '@msi/core/dao/storage/avatar-upload.service';


@Component({
    selector: 'msi-input-avatar',
    templateUrl: './avatar-input.component.html',
    styleUrls: ['./avatar-input.component.scss']
})
export class AvatarInputComponent implements OnInit, OnDestroy {

    @Input() path: string;
    @Input() photoURL: string;
    @Input() placeholder?: string;

    @Output() urlChange: EventEmitter<string> = new EventEmitter();

    url: string;

    constructor(
        private uploadService: AvatarUploadService,
    ) {
    }

    ngOnInit(): void {

        this.url = this.photoURL || this.placeholder || 'https://goo.gl/7kz9qG';
    }

    onFileChange(evt: any): void {

        const target: DataTransfer = <DataTransfer>(evt.target);
        if (target.files.length !== 1) {
            throw new Error('Cannot use multiple files');
        }

        this.uploadFile(target.files.item(0));

    }

    uploadFile(file: File): void {

        if (file.type.split('/')[0] !== 'image') {
            console.error('unsupported file type :( ');
            return;
        }

        this.uploadService.uploadAvatarImage(this.path, file).then(url => {
            this.url = url;
            this.urlChange.emit(url);
        });
    }

    ngOnDestroy(): void { }
}

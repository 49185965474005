import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { IonicModule } from "@ionic/angular";
import { MsiTwoFactorAuthFormModule } from "@msi/ionic/ui/auth/forms/two-factor-auth/two-factor-auth.module";
import { MsiModalBannerModule } from "@msi/ionic/ui/modal-banner/modal-banner.module";
import { MsiAuthProtectedActionModal } from "./protected-action.modal";

@NgModule({
    declarations: [
        MsiAuthProtectedActionModal
    ],
    imports: [
        CommonModule,
        IonicModule,
        FlexLayoutModule,
        MsiTwoFactorAuthFormModule,
        MsiModalBannerModule,
    ],
    exports: [
        MsiAuthProtectedActionModal
    ]
})
export class MsiAuthProtectedActionModule { }
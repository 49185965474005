import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { clone } from 'lodash';

import { BfitUser } from "@bfit/core/models/bfit/bfit-users";
import { UserSettings } from "@msi/core/dao/models/user";
import { MsiAuthRequestHomePage, MsiAuthUpdateUserSettings } from "@msi/ionic/core/auth/actions/auth.actions";
import { Dispatch } from "@ngxs-labs/dispatch-decorator";

import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { BfitAppState } from '@bfit/core/states/app.state';

@Component({
    selector: 'app-edit-settings-page',
    templateUrl: './edit-settings.page.html',
    styleUrls: ['./edit-settings.page.scss']
})
export class EditSettingsPage implements OnInit {

    @SelectSnapshot(BfitAppState.currentUser) user: BfitUser;

    editForm: FormGroup;
    changes: UserSettings;
    formOptions: FormlyFormOptions;
    formConfig: FormlyFieldConfig[];

    @Dispatch()
    save = (user: BfitUser, changes: UserSettings) => [
        new MsiAuthUpdateUserSettings(user, changes),
        new MsiAuthRequestHomePage()
    ]

    @Dispatch()
    cancel = () => new MsiAuthRequestHomePage()

    constructor() {

        this.editForm = new FormGroup({});
        this.formOptions = {};

        this.formConfig = [
            {
                type: 'flex-layout',
                fieldGroup: [
                    {
                        
                        key: 'idleTimeoutAction',
                        type: 'select',
                        className: 'flex-1',
                        templateOptions: {
                            label: 'Idle timeout action',
                            options: [
                                { value: 'ask', label: 'Ask me' },
                                { value: 'logout', label: 'Log me out' },
                                { value: 'lock', label: 'Lock the screen' },
                                { value: 'nothing', label: 'Do nothing' },
                            ],
                            description: 'Select what do do when session is idle'
                        }
                    },
                    {
                        key: 'idleTimeoutMinutes',
                        type: 'input',
                        className: 'flex-1',
                        templateOptions: {
                            type: 'number',
                            label: 'Idle timeout (minutes)',
                            description: 'Enter # of minutes idle before timing out',
                        }
                    },
                ]
            },
            {
                key: 'protectPccs',
                type: 'button-toggle',
                defaultValue: false,
                templateOptions: {
                    label: 'Protect your PCC with 2FA',
                    color: 'primary',
                    description: 'By default protect all your PCCs with 2 factor authentication'
                }
            }

        ];
    }




    ngOnInit(): void {

        this.changes = this.user && this.user.settings ? clone(this.user.settings || {}) : {};
    }


}
<ng-container class="msi-sidemenu-container">

    <msi-sidemenu-header *ngIf="options.header" [options]="options?.header"></msi-sidemenu-header>

    <ion-list class="scroll-content menu_list" scrollY="true">
        <div class="menu_list_header">Main</div>
        <ul>
            <li *ngFor="let entry of options?.entries || []" class="list_item">

                <div class="list-separator" *ngIf="entry?.isDivider">{{ entry?.title }}</div>

                <div *ngIf="!entry?.isDivider"

                     [class]="entry.classes"
                     [ngClass]="{ 'menu-item': true, 'activated': entry.isSelected, disabled: entry.disabled }"
                     (click)="onClick(entry)">

                    <ion-icon [name]="entry.leftIcon" slot="start" *ngIf="entry.leftIcon"></ion-icon>
                    <span *ngIf="!entry.children">{{ entry.title }}</span>
                    <span *ngIf="entry.children" detail>{{ entry.title }}</span>
                    <ion-icon [name]="entry.rightIcon" slot="end" *ngIf="entry.rightIcon && !entry.badge"></ion-icon>

                    <ion-badge [color]="entry?.badge?.color" slot="end" *ngIf="entry?.badge?.text"> {{ entry?.badge?.text }} </ion-badge>

                </div>

            </li>
        </ul>

    </ion-list>

    <div class="ion-footer side_menu_footer" align="center">
        <ion-button class="btn_secondary w-100 btn_small" fill="clear" (click)="goBack()">Back</ion-button>
    </div>

</ng-container>
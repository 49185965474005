import { NgModule } from '@angular/core';
import { RegisterPage } from './register';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MsiAuthRegisterFormModule } from '@msi/ionic/ui/auth/forms/register/register.module';
import { MsiAuthSocialLinksComponentModule } from '../../components/social-links/social-links.module';



@NgModule({
  declarations: [
    RegisterPage,
  ],
  imports: [

   CommonModule,
   IonicModule,
   MsiAuthRegisterFormModule,
   MsiAuthSocialLinksComponentModule,
  ],
  exports: [
    RegisterPage
  ]
})
export class RegisterPageModule {}

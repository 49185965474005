import { ContextHelpVideoPlayerComponent } from '../components/popover-video/context-help-video.component';
import { ContextHelpPopoverComponent } from '../components/popover/context-help-popover.component';
import { Injectable } from "@angular/core";
import { ModalController, PopoverController } from "@ionic/angular";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ContextHelpPlayVideo, ContextHelpSetKeywords, ContextHelpShowPopup, ContextHelpShowSidebar } from "./context-help.actions";

interface StateModel {
    sidebarOpened: boolean;
    keywords: string[];
}

const DEFAULTS: StateModel = {
    sidebarOpened: false,
    keywords: [],
};

@State({
    name: 'appContextHelpState',
    defaults: DEFAULTS
})
@Injectable()
export class ContextHelpState {

    @Selector()
    static sidebarOpen(state: StateModel): boolean {
        return state.sidebarOpened;
    }

    constructor(
        private popoverCtrl: PopoverController,
        private modalCtrl: ModalController,

    ) { }


    @Action(ContextHelpShowSidebar)
    showSidebar(ctx: StateContext<StateModel>): void {
        ctx.patchState({ sidebarOpened: true });
    }

    @Action(ContextHelpSetKeywords, { cancelUncompleted: true })
    setKeywords(ctx: StateContext<StateModel>, action: ContextHelpSetKeywords): void {
        ctx.patchState({ keywords: action.keywords });
    }

    @Action(ContextHelpShowPopup)
    async showPopup(_ctx: StateContext<StateModel>, action: ContextHelpShowPopup): Promise<void> {

        const popover = await this.popoverCtrl.create({
            component: ContextHelpPopoverComponent,
            componentProps: {
                html: ''
            },
            cssClass: 'context-help-popup',
            event: action.event,
            translucent: true
        });


        popover.onDidDismiss().then(result => {
            if (result.data) {

            }
        });

        return popover.present();
    }

    @Action(ContextHelpPlayVideo)
    async playVideo(_ctx: StateContext<StateModel>, action: ContextHelpPlayVideo): Promise<void> {

        const modal = await this.modalCtrl.create({
            component: ContextHelpVideoPlayerComponent,
            componentProps: {
                url: action.url,
                text: `You can connect to your Personal Cloud Computer (PCC) with just the browser only (<b>WEB VIEWER</b>) OR from a small downloaded and installed viewer application (<b>NATIVE APP</b>) on your connecting device.
               The "<b>NATIVE APP</b>" will provide more options and is generally a superior way to access your PCC. It can optionally enable dual monitors as well as a full screen experience. It also enables most of the accessories (printers, scanners, drives, cameras, audio, etc.) plugged into your connecting device to be accessible to your PCC if your PCC has applicable drivers loaded to enable use of that accessory.

               The "<b>NATIVE APP</b>" downloads with installation instructions can be located by clicking <a href="https://www.butterflyit.com/#/download" target="_blank">here</a> or from the navigation drop-down menu ( = ) click the "<b>Download Viewer</b>" link.`
            },
            cssClass: 'context-help-video-player',
            swipeToClose: true,
            presentingElement: await this.modalCtrl.getTop()
        });
       
        modal.onDidDismiss().then(result => {
            if (result && result.data) {
                // FIXME:
            }
        });

        await modal.present();


    }
}

import { Injectable, NgZone } from "@angular/core";

import { MsiNotifyInfo, MsiNotifyClose, MsiNotifyAlert } from "@msi/ionic/ui/notify/store/notify.actions";
import { Action, State, StateContext } from "@ngxs/store";
import { BfitComputerOpen, BfitComputerShutdown, BfitComputerPause, BfitComputerRestart, BfitComputerRestore, BfitComputerUpgrade } from "../actions/computer.actions";
import { BfitComputerManagementService } from "../services/computer-mgmt.service";

interface StateModel { };

@State<StateModel>({
    name: 'computerState'
})
@Injectable({ providedIn: 'root' })
export class BfitComputerState {

    constructor(
        private desktopManagement: BfitComputerManagementService,
        private ngZone: NgZone,
    ) {}

    @Action(BfitComputerOpen)
    async openComputer(ctx: StateContext<StateModel>, action: BfitComputerOpen): Promise<void> {

        ctx.dispatch(new MsiNotifyInfo(action.computer.id, 'Please wait ...', `Starting ${action.computer.name}`));

        try {
            return this.ngZone.runOutsideAngular(async () => {
                await this.desktopManagement.open(action.computer);
                ctx.dispatch(new MsiNotifyClose(action.computer.id));
            });
            
        } catch (err) {
            ctx.dispatch(new MsiNotifyAlert(action.computer.id, err.message, `Fail to start ${action.computer.name}` ));
        }
    }

    @Action(BfitComputerShutdown)
    shutdownComputer(_ctx: StateContext<StateModel>, action: BfitComputerShutdown): Promise<void> {

        return action.options && action.options.force ?
                this.desktopManagement.poweroff(action.computer, action.options) :
                this.desktopManagement.shutdown(action.computer, action.options);
    }

    @Action(BfitComputerRestart)
    restartComputer(_ctx: StateContext<StateModel>, action: BfitComputerRestart): Promise<void> {
        return this.desktopManagement.reboot(action.computer, action.options);
    }

    @Action(BfitComputerPause)
    pauseComputer(_ctx: StateContext<StateModel>, action: BfitComputerPause): Promise<void> {
        return this.desktopManagement.pause(action.computer, action.options);
    }

    @Action(BfitComputerRestore)
    restoreComputer(_ctx: StateContext<StateModel>, action: BfitComputerRestore): Promise<void> {
        return this.desktopManagement.restore(action.computer, action.options);
    }

    @Action(BfitComputerUpgrade)
    upgradeComputer(_ctx: StateContext<StateModel>, action: BfitComputerUpgrade): Promise<void> {
        return this.desktopManagement.upgrade(action.computer, action.options);
        
    }

  

}
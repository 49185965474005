
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'asCoords'
})
export class AsCoordsPipe implements PipeTransform {

    transform(c: any): string {
        return c ? 
            typeof(c) === 'object' ? 
                c.latitude ? 
                    `${c.latitude},${c.longitude}` :
                    c._latitude ? `${c._latitude},${c._longitude}` :
                    JSON.stringify(c) : `${c}` : '';
    }
}

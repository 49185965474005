const topic = '[bfit-app]';

export interface IntroPageSlide {
    title: string;
    description: string;
    image: string;
    color: string;
}

export class CloseTermsAndConditionsPage {
    static readonly type: string = `${topic} close terms and conditions`;
}

export class ClosePrivacyNoticePage {
    static readonly type: string = `${topic} privacy notices`;
}

export class AppRefreshList {
    static readonly type: string = `${topic} refresh list`;
}


import { Injectable } from '@angular/core';

import { AngularFireStorage } from '@angular/fire/storage';



@Injectable({ providedIn: 'root'})
export class FileUploadService {

    constructor(
       private storage: AngularFireStorage) {
    }

    async uploadFile(orgId: string, path: string, file: File): Promise<string> {

        const fullPath = `${orgId}/${path}`;
        const storageRef = this.storage.ref(fullPath);

        return storageRef.put(file, { contentType: file.type }).then(async (_snapshot) => {
            const url = await storageRef.getDownloadURL();
            return url;
        });
    }

    async uploadDataUrl(orgId: string, path: string, dataUrl: string): Promise<string> {

        const fullPath = `${orgId}/${path}`;
        const storageRef = this.storage.ref(fullPath);


        return storageRef.putString(dataUrl, 'data_url').then(async (_snapshot) => {
            const url = await storageRef.getDownloadURL().toPromise();
            return url;
        });
    }
}

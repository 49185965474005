import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipeModule } from 'safe-pipe';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';

import { ContextHelpComponent } from './components/sidebar/context-help.component';
import { ContextHelpState } from './store/context-help.state';
import { ContextHelpIconComponent } from './components/icon/context-help-icon.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ContextHelpPopoverComponent } from './components/popover/context-help-popover.component';
import { ContextHelpVideoPlayerComponent } from './components/popover-video/context-help-video.component';
import { MsiFaqPagesModule } from '@msi/faq/faq.module';


@NgModule({
    
    declarations: [
        ContextHelpComponent,
        ContextHelpIconComponent,
        ContextHelpPopoverComponent,
        ContextHelpVideoPlayerComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        MsiFaqPagesModule,
        FlexLayoutModule,
        SafePipeModule,
        NgxsModule.forFeature([ ContextHelpState ]),

    ],
    exports: [
        ContextHelpComponent,
        ContextHelpIconComponent,
    ]
})
export class BfitContextHelpModule { }


const topic = '[Shopping]';

export class ShowCatalogPage {
    static readonly type: string = `${topic} show catalog page`;
}

export class ShowProductDetailPage {
    static readonly type = `${topic} display product detail`;
}

export class ShowProductListPage {
    static readonly type = `${topic} display product list`;
}

export class ShowCartPage {
    static readonly type = `${topic} show cart`;
}

export class RequestLoginForShopping {
    static readonly type = `${topic} request login`;
}

export class ContinueShopping {
    static readonly type: string = `${topic} continue shopping`;
}

export class ShowQuickCheckoutPage {
    static readonly type = `${topic} quick checkout cart`;
}

export class RequestCheckout {
    static readonly type: string = `${topic} request checkout`;
}

export class ShowFirstTimeCheckoutPage {
    static readonly type = `${topic} first time checkout cart`;
}

export class ShowMakePaymentPage {
    static readonly type = `${topic} make payment`;
}

export class ShowCheckoutReviewOrderPage {
    static readonly type: string = `${topic} show review order page`;
}
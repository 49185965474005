import { State, Action, StateContext, NgxsOnInit, NgxsAfterBootstrap, Store } from '@ngxs/store';
import { CloseTermsAndConditionsPage, ClosePrivacyNoticePage } from '../actions/bfit-app.actions';
import { ActionSheetController } from '@ionic/angular';
import { MsiAuthNavigateTo, MsiAuthPage } from '@msi/ionic/core/auth/actions/nav.actions';
import { NavigateRoot, NavigateForward } from '@msi/core/ngxs/ionic-router/public_api';
import { MsiAuthLegalAgree, MsiAuthLegalDisagree } from '@msi/ionic/core/auth/actions/auth.actions';

import {
    ShowProductListPage,
    ShowCartPage,
    ShowFirstTimeCheckoutPage,
    ShowMakePaymentPage,
    ShowQuickCheckoutPage,
    ShowProductDetailPage,
    ContinueShopping,
    ShowCheckoutReviewOrderPage
} from '@msi/e-commerce/ionic/shopping/store/shopping-nav.actions';

import { BfitAppState } from './app.state';
import { ShoppingState } from '@msi/e-commerce/core/shopping/states/shopping.state';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// tslint:disable-next-line: no-empty-interface
interface StateModel {

}

const DEFAULTS: StateModel = {

};


@State<StateModel>({
    name: 'appNavState',
    defaults: DEFAULTS
})
@Injectable({ providedIn: 'root' })
export class AppNavState implements NgxsOnInit, NgxsAfterBootstrap {

    constructor(
        private actionSheetCtrl: ActionSheetController,
        private store: Store,
    ) { }

    ngxsOnInit(): void {
        // console.error ('AppNavState on init');
    }

    ngxsAfterBootstrap(): void {
        // console.error ('AppNavState after bootstap');
    }


    @Action(CloseTermsAndConditionsPage)
    async closeTerms(ctx: StateContext<StateModel>): Promise<void> {
        return this.accept(ctx, 'Terms and Conditions');
    }

    @Action(ClosePrivacyNoticePage)
    async closePrivacy(ctx: StateContext<StateModel>): Promise<void> {
        return this.accept(ctx, 'Privacy notice');
    }

    private async accept(ctx: StateContext<StateModel>, header: string): Promise<void> {
        const actionSheet = await this.actionSheetCtrl.create({
            header,
            buttons: [
                {
                    text: 'Agree',
                    icon: 'checkmark',
                    handler: () => {
                        console.log('Agree clicked');
                        ctx.dispatch(new MsiAuthLegalAgree());
                    }
                },
                {
                    text: 'Disagree',
                    icon: 'close',
                    role: 'cancel',
                    handler: () => {
                        console.log('Disagree clicked');
                        ctx.dispatch(new MsiAuthLegalDisagree());
                    }
                }
            ]
        });
        await actionSheet.present();
    }

    @Action(MsiAuthNavigateTo, { cancelUncompleted: true })
    navigateTo(ctx: StateContext<StateModel>, action: MsiAuthNavigateTo): Observable<void> {

        const user = this.store.selectSnapshot(BfitAppState.currentUser);

        switch (action.pageName) {
            case MsiAuthPage.loginPage:
                return ctx.dispatch(new NavigateRoot('/auth/login'));

            case MsiAuthPage.logoutPage:
                return ctx.dispatch(new NavigateRoot('/auth/logout'));

            case MsiAuthPage.registerPage:
                return ctx.dispatch(new NavigateRoot('/auth/register'));

            case MsiAuthPage.requestPasswordPage:
                return ctx.dispatch(new NavigateRoot('/auth/reset-password'));

            case MsiAuthPage.resetPasswordPage:
                return ctx.dispatch(new NavigateRoot('/auth/reset-password'));

            case MsiAuthPage.changePasswordPage:
                return ctx.dispatch(new NavigateRoot('/auth/change-password'));

            case MsiAuthPage.introPage:
                // return ctx.dispatch(new NavigateRoot('/info/intro'));
                return ctx.dispatch(new NavigateRoot('/auth/login'));

            case MsiAuthPage.homePage:
                if (user && user.type) {
                    return ctx.dispatch(new NavigateRoot(`/home/${user && user.type}`));
                } else {
                    return ctx.dispatch(new NavigateRoot('/home/guest'));
                }
        }

        return ctx.dispatch(new NavigateRoot(action.pageName));
    }

    @Action(ShowCartPage)
    showCart(ctx: StateContext<StateModel>, _action: ShowCartPage) {
        ctx.dispatch(new NavigateForward('/shopping/cart'));
    }

    @Action(ShowFirstTimeCheckoutPage)
    showCheckoutPage(ctx: StateContext<StateModel>): void {
        ctx.dispatch(new NavigateForward('/shopping/checkout'));
    }

    @Action(ShowQuickCheckoutPage)
    showQuickCheckoutPage(ctx: StateContext<StateModel>): void {
        ctx.dispatch(new NavigateForward('/shopping/quick-checkout'));
    }

    @Action(ShowMakePaymentPage)
    showMakePaymentPage(ctx: StateContext<StateModel>): void {
        ctx.dispatch(new NavigateForward('/shopping/pay'));
    }

    @Action(ShowProductListPage)
    showProductList(ctx: StateContext<StateModel>, _action: ShowProductListPage) {
        const category = this.store.selectSnapshot(ShoppingState.currentCategory);
        if (category) {
            return ctx.dispatch(new NavigateForward(['/shopping/products', { categoryId: category.id, name: category.name }]));
        }
    }

    @Action(ShowProductDetailPage)
    showProductDetail(ctx: StateContext<StateModel>, _action: ShowProductDetailPage) {
        const category = this.store.selectSnapshot(ShoppingState.currentCategory);
        const product = this.store.selectSnapshot(ShoppingState.currentProduct);

        // FIXME: Show different page depending of product type
        if (product && category) {
            return ctx.dispatch(new NavigateForward(['/shopping/details', { itemId: category.id, subDoc: product.id }]));
        }
    }

    @Action(ContinueShopping)
    continueShopping(ctx: StateContext<StateModel>): void {
        ctx.dispatch(new NavigateRoot(['/shopping']));
    }

    @Action(ShowCheckoutReviewOrderPage)
    reviewOrder(ctx: StateContext<StateModel>): void {
        ctx.dispatch(new NavigateForward(['/shopping/review-order']));
    }
}

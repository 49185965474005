import { Component, Input } from '@angular/core';

import { FormBuilder, Validators, AbstractControl, FormGroup } from '@angular/forms';

import {
  MsiAuthCheckLoginWithEmail,
  MsiAuthCheckLoginWithGoogle,
  MsiAuthCheckLoginWithTwitter,
  MsiAuthLoginWithFacebook
} from '@msi/ionic/core/auth/actions/login.actions';

import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { MsiAuthNavigateTo, MsiAuthPage } from '@msi/ionic/core/auth/actions/nav.actions';
import { MsiAuthConfig } from '@msi/ionic/core/auth/models/config';


@Component({
  selector: 'msi-auth-login-form',
  templateUrl: './login.html',
  styleUrls: [ './login.scss' ],
})
export class LoginFormComponent {

  @Input() config: MsiAuthConfig;

  public loginForm: FormGroup;


  constructor(
    fb: FormBuilder,
  ) {
    // let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    this.loginForm = fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.minLength(6), Validators.required])]
    });

    this.loginForm.reset();
  }

  get email(): AbstractControl { return this.loginForm.get('email'); }
  get password(): AbstractControl { return this.loginForm.get('password'); }

  @Dispatch()
  login = (email: string, password: string) => new MsiAuthCheckLoginWithEmail (email, password)

  @Dispatch()
  loginWithGoogle = () => new MsiAuthCheckLoginWithGoogle ()

  @Dispatch()
  loginWithFacebook = () => new MsiAuthLoginWithFacebook ()

  @Dispatch()
  loginWithTwitter = () => new MsiAuthCheckLoginWithTwitter ()

  @Dispatch()
  recover = () => new MsiAuthNavigateTo(MsiAuthPage.resetPasswordPage)

  @Dispatch()
  createAccount = () => new MsiAuthNavigateTo(MsiAuthPage.registerPage)


  checkSubmit(): void {
    if (this.loginForm.valid) {
      this.login(this.email.value, this.password.value);
    }
  }
}

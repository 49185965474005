<div id="time-picker">
    <div>
        <div id="time-picker-header" class="time-picker-bg">
            <p>
                <span id="time-picker-hour"></span>
                <span id="time-picker-mins"></span>
                <span id="time-picker-ampm"></span>
            </p>
        </div>
        <div>
            <a id="time-picker-alt-ok-button" class="btn btn-sm btn-flat"><i class="material-icons">check_circle</i></a>
        </div>
        <div id="time-picker-clock">
            <span id="time-picker-hour-hand"></span>
            <span id="time-picker-hour-center"></span>
            <p id="time-picker-hour-1" class="time-picker-hour" data-value="1"></p>
            <p id="time-picker-hour-2" class="time-picker-hour" data-value="2"></p>
            <p id="time-picker-hour-3" class="time-picker-hour" data-value="3"></p>
            <p id="time-picker-hour-4" class="time-picker-hour" data-value="4"></p>
            <p id="time-picker-hour-5" class="time-picker-hour" data-value="5"></p>
            <p id="time-picker-hour-6" class="time-picker-hour" data-value="6"></p>
            <p id="time-picker-hour-7" class="time-picker-hour" data-value="7"></p>
            <p id="time-picker-hour-8" class="time-picker-hour" data-value="8"></p>
            <p id="time-picker-hour-9" class="time-picker-hour" data-value="9"></p>
            <p id="time-picker-hour-10" class="time-picker-hour" data-value="10"></p>
            <p id="time-picker-hour-11" class="time-picker-hour" data-value="11"></p>
            <p id="time-picker-hour-12" class="time-picker-hour" data-value="12"></p>
        </div>
        
        <p id="time-picker-am-button" class="time-picker-ampm-button" [ngClass]="amButtonClass">AM</p>
        <p id="time-picker-pm-button" class="time-picker-ampm-button" [ngClass]="pmButtonClass">PM</p>
        
        <div id="time-picker-buttons" *ngIf="!TP_NO_BUTTONS" class="text-right">
            <a id="time-picker-reset-button" class="btn btn-sm btn-flat"><i class="material-icons">today</i></a>
            <a id="time-picker-cancel-button" class="btn btn-sm btn-flat">Cancel</a>
            <a id="time-picker-ok-button" class="btn btn-sm btn-flat" [ngClass]="okButtonClass">OK</a>
        </div>
    </div>
</div>